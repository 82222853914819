const campaignConfi ={
    'TITLE': 'Campaign Configuration',
    'SEARCH': 'Search for campaigns',
    'CAMPAIGN_NAME': 'Campaign Name',
    'RULE': 'Dist. Rule',
    'PARAM': 'Dist. Param',
    'AGENT_GROUP': 'Agent Group',
    'SKILL': 'Skills Level',
    'CALLING': 'Calling List',
    'NO_DATA': 'No Data to Display',
    'INCLUDING': 'Including:',
    'MEMBER': 'Members',
    'TEAM_LEAD': 'Team Lead',
    'DETAIL': 'Detail',
    'ALL_CAMPAIGN': 'All Campaigns',
    'NUMBER': 'Number of user',
    'EDIT': 'Edit',
    'RESET': 'Reset',
    'SEARCH_BY': 'Search By Campaign',
    'CAMPAIGN': 'Campaigns',
    'SEARCH_BY_AG': 'Search By Agent Group',
    'SEARCH_BY_CL': 'Search By Calling list',
    'SEARCH_BY_SL': 'Search By Skill Level',
    'SEARCH_BY_OFFER': 'Search By Offer',
    'OFFER': 'Offers',
    'SEARCH_BY_PARTNER': 'Search By Partner',
    'PARTNER': 'Partner',
    'CLOSE': 'Close',
}

export default campaignConfi;