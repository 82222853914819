const agentMonitor = {
    'TITLE': 'Agents List',
    'GROUP': 'Group',
    'ONLINE': 'Online',
    'OFFLINE': 'Offline',
    'ALL': 'All',
    'REGISTER': 'Registered',
    'UNREGISTER': 'Unregistered',
    'COLUMN': 'Column',
    'COLUMNS': 'Columns',
    'IDLE': '[Idle]',
    'SPY': 'Spy',
    'WHISPER': 'Whisper',
    'BARGE': 'Barge',
    'TALK1': 'You are in conversation between agent ',
    'TALK2': ' and customer (',
    'TALK3': 'The status of ',
    'TALK4': ' has been transferred to ',
    'CANCEL': 'Cancel',
    'JOIN_CALLS': 'Join Calls',
    'RINGING': 'Ringing',
    'CONNECT': 'Connected',
    'IDLE_REGIST': 'Idle',
    'SELECT': 'Select',
};
export default agentMonitor;