import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';

import { fetchJSON } from '../../../helpers/api';

import * as types from './constants';

import apiConfig from '../../../config/routesApi';

import * as Actions from './actions';

var j = 0, p = 0, i = 0;
const delay = (ms) => new Promise(res => setTimeout(res, ms));
const cookie = new Cookies();
const user_token = cookie.get("user_access");

var options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '') }
    //+ (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '')
};

function* dataAgentStatus() {
    options.method = 'GET';
    options.body = null;
    try {
        if (i === 0) {
            let list = [], countOffline = 0, countOnline = 0;
            i++;
            list.forEach(element => {
                if(element.state === '[Unregistered]')
                {
                    countOffline++;
                }
                else
                {
                    countOnline++;
                }
            });
            yield put(Actions.getDataAgentStatus([]));
            yield delay(500);
            i = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        yield put(Actions.getDataAgentStatus(["null"]));
    }
}

function* dataAgentFromGroupID({ groupID: id }) {
    options.method = 'GET';
    options.body = null;
    try {
        if (p === 0) {
            let list = [];
            p++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainCMMs + apiConfig.apiEventCMMs.getAgentFromGroupID + id, options);
            if (response.header.code === 200) {
                list = response.items;
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataAgentFromGroupID(list, null));
            yield delay(500);
            p = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        yield put(Actions.getDataAgentFromGroupID(["null"],null));
    }
}

function* dataJoinCall({ dataJoin: data, dataAgent: form }) {
    options.method = 'POST';
    options.body = JSON.stringify(data);
    try {
        if (j === 0) {
            j++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mornitorCMMs + apiConfig.apiEventCMMs.postJoinCallAction, options);
            if(response.status)
            {
                yield put(Actions.submitSucceed(''));
                yield put(Actions.openListenDialog(form));
            }
            else
            {
                yield put(Actions.submitFailed(response.dialStatus));
            }
            yield delay(500);
            j = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        console.log(error);
    }
}

export function* watchAgentStatus() {
    yield takeEvery(types.GET_DATA_AGENT_STATUS, dataAgentStatus);
}

export function* watchAgentFromGroupID() {
    yield takeEvery(types.GET_DATA_AGENT_FROM_GROUP_ID, dataAgentFromGroupID);
}

export function* watchJoinCall() {
    yield takeEvery(types.JOIN_CALL, dataJoinCall);
}

function* agentMonitorSaga() {
    yield all([ fork(watchAgentStatus), fork(watchAgentFromGroupID), fork(watchJoinCall) ]);
}

export default agentMonitorSaga;