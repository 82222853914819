import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';

import { fetchJSON } from '../../../helpers/api';

import * as types from './constants';

import apiConfig from '../../../config/routesApi';

import * as Actions from './actions';

var i = 0;
const delay = (ms) => new Promise(res => setTimeout(res, ms));
const cookie = new Cookies();
const user_token = cookie.get("user_access");

var options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json','Authorization': 'Bearer ' + (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '')}
    //+ (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '')
};


function* dataAgentGroup() {
    options.method = 'GET';
    options.body = null;
    try {
        if (i === 0) {
            let list = [];
            i++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainCMMs + apiConfig.apiEventCMMs.getAgentGroup, options);
            if (response.header.code === 200) {
                const data = response.items;
                if(data){
                    data.forEach((log, index) => {
                        log.keyIndex = index;
                        list.push(log);
                    })
                }
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataAgentGroup(list));
            yield delay(500);
            i = 0;
        }
    }
    catch (error) {
        yield put(Actions.getDataAgentGroup(["null"]));
    }
}

export function* watchDataAgentGroup() {
    yield takeEvery(types.GET_DATA_AGENT_GROUP, dataAgentGroup);
}



function* agentGroupSaga() {
    yield all([
        fork(watchDataAgentGroup),
    ]);
}

export default agentGroupSaga;
