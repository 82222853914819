import { all, call, fork, put, takeEvery, delay } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import moment from 'moment';

import * as types from './constants';

import apiConfig from '../../config/routesApi';
import { getOptionsApi } from '../../helpers/authUtils';

import {
    getDataCountry,
    getDataProvince,
    getDataDistrict,
    getDataSubdistrict,
    getDataNeighborhood,
    getDataDeliveryPackages,
    getDataProvider,
    getDataLocationStatus,
    getDOCrosscheck,
    getDOTotal,
    submitSucceed,
    submitFailed,
    setRowSelected,
    setRowSelectedIndex,
    hideModalProvider,
    resetLoading,
    statusMapping,
    dataFulfillmentLastmile,
    closeEditMappingDialog,
    locationMapping,
    rescueMapping,
    dataTMSCodeMapping,
    resetLocationDistrict,
    resetLocationProvince,
    resetLocationSubDistrict,
    closeAddMappingDialog,
    closeConfirmDialog
} from './actions';

var i = 0, y = 0, z = 0, v = 0, p = 0, j = 0, q = 0, w = 0, e = 0, t = 0, u = 0, x = 0, b = 0, c = 0;

let options = getOptionsApi();

function* dataCountry() {
    options.method = 'GET';
    options.body = null;
    try {
        if (i === 0) {
            var list = [];
            var locationMappingLevel = null, locationLevel = null;
            i++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getCountryList, options);
            if (response.data) {
                var otd = {
                    id: response.data.id,
                    name: response.data.name,
                    fulfillment: [],
                    warehouse: [],
                    lastmile: [],
                };

                locationMappingLevel = response.data.locationMappingLevel;
                locationLevel = response.data.locationLevel;

                response.data.fulfillmentList.forEach(elementFulfillment => {
                    if (elementFulfillment.shortname != null) {
                        otd.fulfillment.push(elementFulfillment.shortname)
                    }
                });
                response.data.lastmileList.forEach(elementLastmile => {
                    if (elementLastmile.shortname != null) {
                        otd.lastmile.push(elementLastmile.shortname)
                    }
                });
                response.data.warehouseList.forEach(elementWarehouse => {
                    if (elementWarehouse.shortname != null) {
                        otd.warehouse.push(elementWarehouse.shortname)
                    }
                });
                list.push(otd);
            }
            else {
                list = []
            }
            yield put(getDataCountry(list, locationMappingLevel, locationLevel));
            yield delay(500);
            i = 0;
        }
    }
    catch (error) {
        yield put(getDataCountry([], null, null));
    }
}

function* dataProvince() {
    options.method = 'GET';
    options.body = null;
    try {
        if (y === 0) {
            var list = [];
            var listNonSelected = [];
            y++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getProvinceList, options);
            if (response.data.length > 0) {
                response.data.forEach(element => {
                    var otd = {
                        id: element.id,
                        name: element.name,
                        fulfillment: '',
                        warehouse: '',
                        lastmile: '',
                        value: element.id,
                        label: element.name,
                        status: element.status,
                        sapOneDayDeliveries: element.sapDeliveryPackage ? element.sapDeliveryPackage.sapOneDayDeliveries : [],
                        sapSameDayDeliveries: element.sapDeliveryPackage ? element.sapDeliveryPackage.sapSameDayDeliveries : [],
                        statusName: element.statusName,
                        change: element.scheduleUpdate !== null ? element.scheduleUpdate.updateDay : null,
                        scheduleUpdate: { ...element.scheduleUpdate, idLocation: element.id, status: element.status }
                    };

                    if (element.scheduleUpdate !== null) {
                        listNonSelected.push(element.id);
                    }
                    element.fulfillmentList = element.fulfillmentList ? element.fulfillmentList : [];
                    element.lastmileList = element.lastmileList ? element.lastmileList : [];
                    element.warehouseList = element.warehouseList ? element.warehouseList : [];

                    element.fulfillmentList.forEach(elementFulfillment => {
                        if (elementFulfillment.shortname != null) {
                            otd.fulfillment = otd.fulfillment + elementFulfillment.shortname + ',';
                        }
                    });
                    element.lastmileList.forEach(elementLastmile => {
                        if (elementLastmile.shortname != null) {
                            otd.lastmile = otd.lastmile + elementLastmile.shortname + ',';
                        }
                    });
                    element.warehouseList.forEach(elementWarehouse => {
                        if (elementWarehouse.shortname != null) {
                            otd.warehouse = otd.warehouse + elementWarehouse.shortname + ',';
                        }
                    });
                    list.push(otd);
                });
            }
            else {
                list = []
            }
            yield put(getDataProvince(list, listNonSelected));
            yield put(resetLocationProvince());
            yield delay(500);
            y = 0;
        }
    }
    catch (error) {
        yield put(getDataProvince([], []));
    }
}

function* dataDistrict({ id: idProvince, mapping: mapping_type }) {
    options.method = 'GET';
    options.body = null;
    try {
        if (z === 0) {
            var list = [];
            var listNonSelected = [];
            z++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getDistrictList + '/' + idProvince, options);
            if (response.data.length > 0) {
                response.data.forEach(element => {
                    var otd = {
                        id: element.dtId,
                        name: element.name,
                        fulfillment: '',
                        warehouse: '',
                        lastmile: '',
                        value: element.dtId,
                        label: element.name,
                        sapOneDayDeliveries: element.sapDeliveryPackage ? element.sapDeliveryPackage.sapOneDayDeliveries : [],
                        sapSameDayDeliveries: element.sapDeliveryPackage ? element.sapDeliveryPackage.sapSameDayDeliveries : [],
                        status: element.status,
                        statusName: element.statusName,
                        change: element.scheduleUpdate !== null ? element.scheduleUpdate.updateDay : null,
                        scheduleUpdate: { ...element.scheduleUpdate, idLocation: element.dtId, status: element.status }
                    };
                    if (element.scheduleUpdate !== null) {
                        listNonSelected.push(element.dtId);
                    }
                    if (mapping_type === 2) {
                        otd.fulfillment = element.fulfillment.shortname;
                        otd.warehouse = element.warehouse.shortname;
                        otd.lastmile = element.lastmile.shortname;
                    }
                    else {
                        element.fulfillmentList = element.fulfillmentList ? element.fulfillmentList : [];
                        element.lastmileList = element.lastmileList ? element.lastmileList : [];
                        element.warehouseList = element.warehouseList ? element.warehouseList : [];

                        element.fulfillmentList.forEach(elementFulfillment => {
                            if (elementFulfillment.shortname != null) {
                                otd.fulfillment = otd.fulfillment + elementFulfillment.shortname + ',';
                            }
                        });
                        element.lastmileList.forEach(elementLastmile => {
                            if (elementLastmile.shortname != null) {
                                otd.lastmile = otd.lastmile + elementLastmile.shortname + ',';
                            }
                        });
                        element.warehouseList.forEach(elementWarehouse => {
                            if (elementWarehouse.shortname != null) {
                                otd.warehouse = otd.warehouse + elementWarehouse.shortname + ',';
                            }
                        });
                    }
                    list.push(otd);
                });
            }
            else {
                list = [];
            }
            yield put(getDataDistrict(list, null, null, listNonSelected));
            yield put(resetLocationDistrict());
            yield delay(500);
            z = 0;
        }
    }
    catch (error) {
        yield put(getDataDistrict([], null, null, []));
    }
}

function* dataSubdistrict({ id: idDistrict, mapping: mapping_type }) {
    options.method = 'GET';
    options.body = null;
    try {
        if (v === 0) {
            var list = [];
            var listNonSelected = [];
            v++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getSubdistrictList + '/' + idDistrict, options);
            if (response.data.length > 0) {
                response.data.forEach(element => {
                    var otd = {
                        id: element.id,
                        name: element.name,
                        fulfillment: '',
                        warehouse: '',
                        lastmile: '',
                        value: element.id,
                        label: element.name,
                        status: element.status,
                        sapOneDayDeliveries: element.sapDeliveryPackage ? element.sapDeliveryPackage.sapOneDayDeliveries : [],
                        sapSameDayDeliveries: element.sapDeliveryPackage ? element.sapDeliveryPackage.sapSameDayDeliveries : [],
                        statusName: element.statusName,
                        zipCode: element.zipCode,
                        change: element.scheduleUpdate !== null ? element.scheduleUpdate.updateDay : null,
                        scheduleUpdate: { ...element.scheduleUpdate, idLocation: element.id, status: element.status }
                    };
                    if (element.scheduleUpdate !== null) {
                        listNonSelected.push(element.id);
                    }
                    if (mapping_type <= 3) {
                        otd.fulfillment = element.fulfillment.shortname;
                        otd.warehouse = element.warehouse.shortname;
                        otd.lastmile = element.lastmile.shortname;
                    }
                    else {
                        element.fulfillmentList = element.fulfillmentList ? element.fulfillmentList : [];
                        element.lastmileList = element.lastmileList ? element.lastmileList : [];
                        element.warehouseList = element.warehouseList ? element.warehouseList : [];

                        element.fulfillmentList.forEach(elementFulfillment => {
                            if (elementFulfillment.shortname != null) {
                                otd.fulfillment = otd.fulfillment + elementFulfillment.shortname + ',';
                            }
                        });
                        element.lastmileList.forEach(elementLastmile => {
                            if (elementLastmile.shortname != null) {
                                otd.lastmile = otd.lastmile + elementLastmile.shortname + ',';
                            }
                        });
                        element.warehouseList.forEach(elementWarehouse => {
                            if (elementWarehouse.shortname != null) {
                                otd.warehouse = otd.warehouse + elementWarehouse.shortname + ',';
                            }
                        });
                    }
                    list.push(otd);
                });
            }
            else {
                list = [];
            }
            yield put(getDataSubdistrict(list, null, null, listNonSelected));
            yield put(resetLocationSubDistrict());
            yield delay(500);
            v = 0;
        }
    }
    catch (error) {
        yield put(getDataSubdistrict([], null, null, []));
    }
}

function* dataNeighborhood({ id: idSubdistrict }) {
    options.method = 'GET';
    options.body = null;
    try {
        if (p === 0) {
            var list = [];
            var listNonSelected = [];
            p++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getNeighborhood + '/' + idSubdistrict, options);
            if (response.data.length > 0) {
                response.data.forEach(element => {
                    var otd = {
                        id: element.id,
                        name: element.name,
                        value: element.id,
                        label: element.name,
                        fulfillment: '',
                        warehouse: '',
                        lastmile: '',
                        lastmileId: null,
                        status: element.status,
                        sapOneDayDeliveries: element.sapDeliveryPackage ? element.sapDeliveryPackage.sapOneDayDeliveries : [],
                        sapSameDayDeliveries: element.sapDeliveryPackage ? element.sapDeliveryPackage.sapSameDayDeliveries : [],
                        statusName: element.statusName,
                        zipCode: element.zipCode,
                        change: element.scheduleUpdate !== null ? element.scheduleUpdate.updateDay : null,
                        scheduleUpdate: { ...element.scheduleUpdate, idLocation: element.id, status: element.status }
                    };
                    if (element.scheduleUpdate !== null) {
                        listNonSelected.push(element.id);
                    }
                    otd.fulfillment = element.fulfillment.shortname;
                    otd.warehouse = element.warehouse.shortname;
                    otd.lastmile = element.lastmile.shortname;
                    otd.lastmileId = element.lastmile.id;
                    list.push(otd);
                })
            }
            else {
                list = []
            }
            yield put(getDataNeighborhood(list, null, listNonSelected));
            yield delay(500);
            p = 0;
        }
    }
    catch (error) {
        yield put(getDataNeighborhood([], null, []));
    }
}

function* dataDeliveryPackages({ lastmileId }) {
    options.method = 'GET';
    options.body = null;
    try {
        if (c === 0) {
            var list = [];
            c++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getDeliveryPackages + '/' + lastmileId, options);
            if (response.code === 200) {
                const data = response.data;
                if(data){
                    data.forEach((log, index) => {
                        log.keyIndex = index;
                        list.push(log);
                    })
                }
            }
            else {
                list = ["null"];
            }
            yield put(getDataDeliveryPackages(list, null));
            yield delay(500);
            c = 0;
        }
    }
    catch (error) {
        yield put(getDataDeliveryPackages(["null"], null));
    }
}

function* dataProvider() {
    options.method = 'GET';
    options.body = null;
    try {
        if (j === 0) {
            var list = [];
            j++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getProviderList, options);
            if (response.data) {
                response.data.ffms.forEach(element => {
                    element.value = element.ffmPnId;
                    element.label = element.nameFfm;
                    list.push(element);
                });
            }
            else {
                list = ["null"];
            }
            yield put(getDataProvider(list));
            yield delay(500);
            j = 0;
        }
    }
    catch (error) {
        yield put(getDataProvider(["null"]));
    }
}

function* dataLocationStatus() {
    options.method = 'GET';
    options.body = null;
    try {
        if (q === 0) {
            var list = [];
            q++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getLocationStatus, options);
            if (response.data.length > 0) {
                response.data.forEach(element => {
                    element.label = element.name;
                    list.push(element);
                });
            }
            else {
                list = ["null"];
            }
            yield put(getDataLocationStatus(list));
            yield delay(500);
            q = 0;
        }
    }
    catch (error) {
        yield put(getDataLocationStatus(["null"]));
    }
}

function* dataDOCrosscheck({ dataDate }) {
    try {
        if (x === 0) {
            options.method = 'POST';
            options.body = JSON.stringify(dataDate);
            let list = [];
            x++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.doCrosscheck, options);
            if (response.code === 200) {
                const data = response.data.items;
                if (data) {
                    data.forEach((log, index) => {
                        log.keyIndex = index;
                        list.push(log);
                    })
                }
            }
            else {
                list = ["null"];
            }
            yield put(getDOCrosscheck(list, null));
            yield delay(500);
            x = 0;
            yield delay(1);
            yield put(resetLoading());
        }
    }
    catch (error) {
        yield put(getDOCrosscheck(["null"]));
        yield delay(1);
        yield put(resetLoading());
    }
}

function* dataDOTotal({ bodyTotal }) {
    try {
        if (b === 0) {
            options.method = 'POST';
            options.body = JSON.stringify(bodyTotal);
            let list = [];
            b++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.doTotal, options);
            if (response.code === 200) {
                list = response.data;
            }
            else {
                list = ["null"];
            }
            yield put(getDOTotal(list, null));
            yield delay(500);
            b = 0;
            yield delay(1);
            yield put(resetLoading());
        }
    }
    catch (error) {
        yield put(getDOTotal(["null"]));
        yield delay(1);
        yield put(resetLoading());
    }
}

function* submitData({ payload: data, location_type: type }) {
    options.body = JSON.stringify(data);
    options.method = 'PUT';
    if (w === 0) {
        w++;
        const response = type === 'province' ? yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getProvinceList, options)
            : type === 'district' ? yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getDistrictList, options)
                : type === 'subdistrict' ? yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getSubdistrictList, options)
                    : yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getNeighborhood, options);
        if (response.code === 200) {
            if (data.status && data.locationIds) {
                const responseLocationStatus = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getCountryList, options);
                if (responseLocationStatus.code === 200) {
                    yield put(hideModalProvider());
                    yield put(submitSucceed(responseLocationStatus.message));
                }
                else {
                    yield put(submitFailed(responseLocationStatus.message));
                }
            }
            else {
                yield put(hideModalProvider());
                yield put(submitSucceed(response.message));
            }
            yield put(setRowSelected([]));
            yield put(setRowSelectedIndex([]));
        }
        else {
            yield put(submitFailed(response.message));
        }
        yield delay(500);
        w = 0;
        yield delay(1);
        yield put(resetLoading());
    }
}

function* deleteData({ payload: id }) {
    options.method = 'DELETE';
    if (e === 0) {
        e++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.deleteScheduleChange + '/' + id, options);
        if (response.code === 200) {
            yield put(hideModalProvider());
            yield put(submitSucceed(response.message));
        }
        else {
            yield put(submitFailed(response.message));
        }
        yield delay(200);
        e = 0;
        yield delay(1);
        yield put(resetLoading());
    }
}

function* editData({ payload: data }) {
    options.body = JSON.stringify(data);
    options.method = 'PUT';
    if (t === 0) {
        t++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.deleteScheduleChange, options);
        if (response.code === 200) {
            yield put(hideModalProvider());
            yield put(submitSucceed(response.message));
            yield put(setRowSelected([]));
            yield put(setRowSelectedIndex([]));
        }
        else {
            yield put(submitFailed(response.message));
        }
        yield delay(500);
        t = 0;
        yield delay(1);
        yield put(resetLoading());
    }
}

function* editLocationStatusData({ payload: data }) {
    options.body = JSON.stringify(data);
    options.method = 'PUT';
    if (u === 0) {
        u++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getCountryList, options);
        if (response.code === 200) {
            yield put(hideModalProvider());
            yield put(submitSucceed(response.message));
        }
        else {
            yield put(submitFailed(response.message));
        }
        yield delay(500);
        u = 0;
        yield delay(1);
        yield put(resetLoading());
    }
}

function* dataFulfillmentLastmileSaga() {
    options.method = 'GET';
    options.body = null;
    try {
        if (b === 0) {
            var list = [];
            b++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getFulfillmentLastmile, options);
            if (response.data.length > 0) {
                response.data.forEach(element => {
                    element.value = element.partnerId;
                    element.label = element.name;
                    list.push(element);
                });
            }
            yield put(dataFulfillmentLastmile(list));
            yield delay(500);
            b = 0;
        }
    }
    catch (error) {
        yield put(dataFulfillmentLastmile([]));
        yield put(resetLoading());
    }
}

function* dataTMSCodeMappingSaga() {
    options.method = 'GET';
    options.body = null;
    try {
        if (u === 0) {
            var list = [];
            u++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.getTmsCodeMapping, options);
            if (response.data.length > 0) {
                response.data.forEach(element => {
                    element.value = element.status_code;
                    element.label = element.status_name;
                    list.push(element);
                });
            }
            yield put(dataTMSCodeMapping(list));
            yield delay(500);
            u = 0;
        }
    }
    catch (error) {
        yield put(dataTMSCodeMapping([]));
        yield put(resetLoading());
    }
}

function* editStatusMapping({ formData: data, dataList: dataStatusMapping, search: searchObj }) {
    options.body = JSON.stringify(data);
    options.method = 'PUT';
    if (z === 0) {
        z++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.crudEditStatusMapping + '/' + data.id, options);
        if (response.code === 200) {
            yield put(closeEditMappingDialog());
            const index = dataStatusMapping.list.findIndex(x => parseInt(x.id) === parseInt(data.id));
            data.modifiedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            dataStatusMapping.list.splice(index, 1, data);
            yield put(statusMapping(dataStatusMapping, 0, {}, searchObj.pageIndex, searchObj.pageSize));
            yield put(submitSucceed(response.message));
        }
        else {
            yield put(submitFailed(response.message));
        }
        yield delay(500);
        z = 0;
    }
}

function* editLocationMapping({ formData: data, dataList: dataLocationMapping, search: searchObj }) {
    options.body = JSON.stringify(data);
    options.method = 'PUT';
    if (z === 0) {
        z++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.crudEditLocationMapping, options);
        if (response.code === 200) {
            yield put(closeEditMappingDialog());
            const index = dataLocationMapping.list.findIndex(x => parseInt(x.id) === parseInt(data.id));
            data.modifiedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            dataLocationMapping.list.splice(index, 1, data);
            yield put(locationMapping(dataLocationMapping, searchObj, {}, searchObj.pageIndex, searchObj.pageSize));
            yield put(submitSucceed(response.message));
        }
        else {
            yield put(submitFailed(response.message));
        }
        yield delay(500);
        z = 0;
    }
}

function* editRescueMapping({ formData: data, dataList: dataRescueMapping, search: searchObj }) {
    options.body = JSON.stringify(data);
    options.method = 'PUT';
    if (z === 0) {
        z++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.crudEditRecueMapping, options);
        if (response.code === 200) {
            yield put(closeEditMappingDialog());
            const index = dataRescueMapping.list.findIndex(x => parseInt(x.id) === parseInt(data.id));
            data.modifiedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            dataRescueMapping.list.splice(index, 1, data);
            yield put(rescueMapping(dataRescueMapping, {}, {}, searchObj.pageIndex, searchObj.pageSize));
            yield put(submitSucceed(response.message));
        }
        else {
            yield put(submitFailed(response.message));
        }
        yield delay(500);
        z = 0;
    }
}

function* createLocationMapping({ formData: data, dataList: dataLocationMapping, search: searchObj }) {
    options.body = JSON.stringify(data);
    options.method = 'POST';
    if (z === 0) {
        z++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.crudEditLocationMapping, options);
        if (response.code === 200) {
            yield put(closeAddMappingDialog());
            data.modifiedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            dataLocationMapping.list.unshift(data);
            yield put(locationMapping(dataLocationMapping, searchObj, {}, searchObj.pageIndex, searchObj.pageSize));
            yield put(submitSucceed(response.message));
        }
        else {
            yield put(submitFailed(response.message));
        }
        yield delay(500);
        z = 0;
    }
}

function* createStatusMapping({ formData: data, dataList: dataStatusMapping, search: searchObj }) {
    options.body = JSON.stringify(data);
    options.method = 'POST';
    if (z === 0) {
        z++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.crudEditStatusMapping, options);
        if (response.code === 200) {
            yield put(closeAddMappingDialog());
            data.modifiedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            dataStatusMapping.list.unshift(data);
            yield put(statusMapping(dataStatusMapping, 0, {}, searchObj.pageIndex, searchObj.pageSize));
            yield put(submitSucceed(response.message));
        }
        else {
            yield put(submitFailed(response.message));
        }
        yield delay(500);
        z = 0;
    }
}

function* createRescueMapping({ formData: data, dataList: dataRescueMapping, search: searchObj }) {
    options.body = JSON.stringify(data);
    options.method = 'POST';
    if (z === 0) {
        z++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.crudEditRecueMapping, options);
        if (response.code === 200) {
            yield put(closeAddMappingDialog());
            data.modifiedAt = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            dataRescueMapping.list.unshift(data);
            yield put(rescueMapping(dataRescueMapping, {}, {}, searchObj.pageIndex, searchObj.pageSize));
            yield put(submitSucceed(response.message));
        }
        else {
            console.log('response failed = ', response)
            yield put(submitFailed(response.message));
        }
        yield delay(500);
        z = 0;
    }
}

function* deleteLocationMapping({ formData: data, dataList: dataLocationMapping, search: searchObj }) {
    options.body = JSON.stringify(data);
    options.method = 'DELETE';
    if (z === 0) {
        z++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.crudEditLocationMapping, options);
        if (response.code === 200) {
            yield put(closeConfirmDialog());
            dataLocationMapping.list.splice(data.indexRow, 1);
            yield put(locationMapping(dataLocationMapping, searchObj, {}, searchObj.pageIndex, searchObj.pageSize));
            yield put(submitSucceed(response.message));
        }
        else {
            yield put(submitFailed(response.message));
        }
        yield delay(500);
        z = 0;
    }
}

function* deleteStatusMapping({ formData: data, dataList: dataStatusMapping, search: searchObj }) {
    options.body = JSON.stringify(data);
    options.method = 'DELETE';
    if (z === 0) {
        z++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.crudEditStatusMapping, options);
        if (response.code === 200) {
            yield put(closeConfirmDialog());
            dataStatusMapping.list.splice(data.indexRow, 1);
            yield put(statusMapping(dataStatusMapping, 0, {}, searchObj.pageIndex, searchObj.pageSize));
            yield put(submitSucceed(response.message));
        }
        else {
            yield put(submitFailed(response.message));
        }
        yield delay(500);
        z = 0;
    }
}

function* deleteRescueMapping({ formData: data, dataList: dataRescueMapping, search: searchObj }) {
    options.body = JSON.stringify(data);
    options.method = 'DELETE';
    if (z === 0) {
        z++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainLogistic + apiConfig.apiEventLogistic.crudEditRecueMapping, options);
        if (response.code === 200) {
            yield put(closeConfirmDialog());
            dataRescueMapping.list.splice(data.indexRow, 1);
            yield put(rescueMapping(dataRescueMapping, {}, {}, searchObj.pageIndex, searchObj.pageSize));
            yield put(submitSucceed(response.message));
        }
        else {
            yield put(submitFailed(response.message));
        }
        yield delay(500);
        z = 0;
    }
}

export function* watchCountry() {
    yield takeEvery(types.GET_DATA_COUNTRY, dataCountry);
}

export function* watchProvince() {
    yield takeEvery(types.GET_DATA_PROVINCE, dataProvince);
}

export function* watchDistrict() {
    yield takeEvery(types.GET_DATA_DISTRICT, dataDistrict);
}

export function* watchSubdistrict() {
    yield takeEvery(types.GET_DATA_SUBDISTRICT, dataSubdistrict);
}

export function* watchNeighborhood() {
    yield takeEvery(types.GET_DATA_NEIGHBORHOOD, dataNeighborhood);
}

export function* watchDeliveryPackages() {
    yield takeEvery(types.DELIVERY_PACKAGES_BY_LASTMILE_ID, dataDeliveryPackages);
}

export function* watchProvider() {
    yield takeEvery(types.GET_DATA_PROVIDER, dataProvider);
}

export function* watchLocationStatus() {
    yield takeEvery(types.GET_DATA_LOCATION_STATUS, dataLocationStatus);
}

export function* watchDOCrosscheck() {
    yield takeEvery(types.GET_DO_CROSSCHECK, dataDOCrosscheck);
}

export function* watchDOTotal() {
    yield takeEvery(types.DATA_TOTAL, dataDOTotal);
}

export function* watchSubmitData() {
    yield takeEvery(types.PUT_DATA_LOCATION, submitData);
}

export function* watchDeleteData() {
    yield takeEvery(types.DELETE_DATA_SCHEDULE, deleteData);
}

export function* watchEditData() {
    yield takeEvery(types.EDIT_DATA_LOCATION, editData);
}

export function* watchEditLocationStatus() {
    yield takeEvery(types.EDIT_LOCATION_STATUS, editLocationStatusData);
}

export function* watchDataFulfillmentLastmileSaga() {
    yield takeEvery(types.GET_DATA_FULFILLMENT_LASTMILE, dataFulfillmentLastmileSaga);
}

export function* watchDataTmsCodeMappingSaga() {
    yield takeEvery(types.GET_DATA_TMS_CODE_MAPPING, dataTMSCodeMappingSaga);
}

export function* watchEditStatusMapping() {
    yield takeEvery(types.EDIT_DATA_STATUS_MAPPING, editStatusMapping);
}

export function* watchEditLocationMapping() {
    yield takeEvery(types.EDIT_DATA_LOCATION_MAPPING, editLocationMapping);
}

export function* watchEditResueMapping() {
    yield takeEvery(types.EDIT_DATA_RESCUE_MAPPING, editRescueMapping);
}

export function* watchCreateLocationMapping() {
    yield takeEvery(types.CREATE_DATA_LOCATION_MAPPING, createLocationMapping);
}

export function* watchCreateStatusMapping() {
    yield takeEvery(types.CREATE_DATA_STATUS_MAPPING, createStatusMapping);
}

export function* watchCreateRescueMapping() {
    yield takeEvery(types.CREATE_DATA_RESCUE_MAPPING, createRescueMapping);
}

export function* watchDeleteLocationMapping() {
    yield takeEvery(types.DELETE_DATA_LOCATION_MAPPING, deleteLocationMapping);
}

export function* watchDeleteStatusMapping() {
    yield takeEvery(types.DELETE_DATA_STATUS_MAPPING, deleteStatusMapping);
}

export function* watchDeleteRescueMapping() {
    yield takeEvery(types.DELETE_DATA_RESCUE_MAPPING, deleteRescueMapping);
}

function* logisticSaga() {
    yield all([
        fork(watchProvince),
        fork(watchDistrict),
        fork(watchCountry),
        fork(watchSubdistrict),
        fork(watchNeighborhood),
        fork(watchDeliveryPackages),
        fork(watchProvider),
        fork(watchLocationStatus),
        fork(watchDOCrosscheck),
        fork(watchDOTotal),
        fork(watchSubmitData),
        fork(watchDeleteData),
        fork(watchEditData),
        fork(watchEditLocationStatus),
        fork(watchDataFulfillmentLastmileSaga),
        fork(watchEditStatusMapping),
        fork(watchEditLocationMapping),
        fork(watchEditResueMapping),
        fork(watchDataTmsCodeMappingSaga),
        fork(watchCreateLocationMapping),
        fork(watchCreateStatusMapping),
        fork(watchCreateRescueMapping),
        fork(watchDeleteLocationMapping),
        fork(watchDeleteStatusMapping),
        fork(watchDeleteRescueMapping),
    ]);
}

export default logisticSaga;

