const agentSummaries = {
    'TITLE': 'Agent Summaries',
    'OUTBOUND': 'Outbound',
    'INBOUND': 'Inbound',
    'SEARCH': 'Search For Agents',
    'AUDIO': 'Audio',
    'LEADID': 'Lead ID',
    'UNIQUEID': 'Unique ID',
    'LEAD_NAME': 'Lead Name',
    'LEAD_PHONE': 'Lead Phone',
    'CALL_STATUS': 'Call Status',
    'RING_TIME': 'Ringing Time',
    'TALK_TIME': 'Agent Talk Time',
    'DURATION': 'Durations',
    'SERVING_TIME': 'Serving Time',
    'AG_SIP': 'Agent Sip',
    'AG_NAME': 'Agent Name',
    'CALL_STARTTIME': 'Call Start Time',
    'CALL_ENDTIME': 'Call End Time',
    'CLOSE': 'Close',
    'APPLY_FILTER': 'Apply Filter',
    'CLEAR_FILTER': 'Clear Filter',
    'LEAD_STATUS': 'Lead status',
    'AGENTID': 'Agent ID',
    'E_LEADID': 'Enter lead ID',
    'E_LEAD_STATUS': 'Enter lead status',
    'E_LEAD_NAME': 'Enter lead name',
    'E_LEAD_PHONE': 'Enter lead phone',
    'E_AG_SIP': 'Enter agent sip',
    'E_AGENTID': 'Enter agent ID',
    'E_AG_NAME': 'Enter agent name',
    'E_UNIQUEID': 'Enter unique ID',
    'E_CALL_STATUS': 'Enter call status',
    'E_CALL_START': 'Enter from call start time',
    'E_CALL_END': 'Enter to call end time',
    'E_PHONE': 'Enter phone',
    'ANSWERED': 'Answered',
    'BUSY': 'Busy',
    'NO_ANSWER': 'No answer',
    'PHONE': 'Phone',
    'START_END_TIME': 'Call Start Time & Call End Time',
    'E_START_END': 'Enter call start time and call end time'
};
export default agentSummaries;