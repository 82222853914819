const agentCampaign = {
    'TITLE': 'Tư vấn viên chiến lượt bán hàng',
    'ALL_CP': 'Tất cả Chiến dịch',
    'NO_DATA': 'Không có dữ liệu để hiển thị',
    'INCLUDING': 'Bao gồm:',
    'AGENT': 'Nhóm Đại lý',
    'MEMBER': 'Thành viên',
    'TEAM_LEAD': 'Trưởng nhóm',
    'DETAIL': 'Chi tiết',
    'NUMBER_OFF': 'Số lượng người dùng',
    'SEARCH': 'Nhóm Tìm kiếm...',
}

export default agentCampaign;