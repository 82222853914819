const callingList ={
    'TITLE': 'Các mã bán hàng',
    'SEARCH': 'Tìm kiếm danh sách gọi điện',
    'NEW_CALLING': 'Danh sách cuộc gọi mới',
    'NAME': 'Tên',
    'PRODUCT_ID': 'ID Sản phẩm',
    'TYPE': 'Loại',
    'DESC': 'Mô tả',
    'CREATE_DATE': 'Ngày tạo',
    'NO_DATA': 'Không có dữ liệu để hiển thị',
    'ADD_CALLING': 'Thêm Danh sách Gọi điện',
    'EDIT_CALLING': 'Chỉnh sửa Danh sách Gọi điện',
    'CODE': 'Mã',
    'PRIORITY': 'Ưu tiên',
    'ADD': 'Thêm',
    'SAVE': 'Lưu',
    'ERROR': 'Lỗi, Vui lòng điền thông tin!',
}

export default callingList;