import * as types from './constants';
const initialState = {
    loading: false,
    dataUserManagement: [],
    dataUserTypes: [],
    dataUserGroups: [],
    dataUserTeams: [],
    agentDialog     : {
        type : 'New',
        props: {
            open: false
        },
        data : null
    },
    showMessage: null,
    error: null,
}

const UserManagement = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATA_USER_MANAGEMENT:
        {
            return {...state, dataUserManagement: action.payload, loading: action.loading }
        }
        case types.GET_DATA_USER_TYPES:
        {
            return {...state, dataUserTypes: action.payload }
        }
        case types.GET_DATA_USER_GROUPS:
        {
            return {...state, dataUserGroups: action.payload }
        }
        case types.GET_DATA_USER_TEAMS:
        {
            return {...state, dataUserTeams: action.payload }
        }
        case types.OPEN_NEW_AGENT_DIALOG:
        {
            return {
                ...state,
                agentDialog: {
                    type : 'New',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case types.CLOSE_NEW_AGENT_DIALOG:
        {
            return {
                ...state,
                agentDialog: {
                    type : 'New',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case types.OPEN_EDIT_AGENT_DIALOG:
        {
            return {
                ...state,
                agentDialog: {
                    type : 'Edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case types.CLOSE_EDIT_AGENT_DIALOG:
        {
            return {
                ...state,
                agentDialog: {
                    type : 'Edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case types.CREATE_NEW_USER:
            return { ...state, loading: true }
        case types.EDIT_USER:
            return { ...state, loading: true }
        case types.FILTER_USER:
            return { ...state, loading: true }
        case types.EXPORT_DATA:
            return { ...state, loading: true }
        case types.LOAD_USER_BY_FILTER:
            return { ...state, loading: false, dataUserManagement: action.payload }
        case types.SUBMIT_SUCCEED:
            return { ...state, showMessage: action.payload, error: null, loading: false }
        case types.SUBMIT_FAILED:
            return { ...state, showMessage: null, error: action.payload, loading: false }
        case types.RESET_MESSAGE:
            return { ...state, showMessage: null, error: null }
        case types.RESET_LOADING:
            return {
                ...state,
                loading: false
        }
        default:
            return { ...state };
    }
}

export default UserManagement;