import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';

import { fetchJSON } from '../../../helpers/api';

import * as types from './constants';

import apiConfig from '../../../config/routesApi';

import * as Actions from './actions';

var j = 0, z = 0, p = 0, i = 0, x = 0, y = 0;
const delay = (ms) => new Promise(res => setTimeout(res, ms));
const cookie = new Cookies();
const user_token = cookie.get("user_access");

var options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '') }
    //+ (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '')
};

function* dataAgentByGroup({ id: groupId }) {
    options.method = 'GET';
    options.body = null;
    try {
        if (p === 0) {
            let list = [];
            p++;
            if (groupId !== '') {
                const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.getAgentByGroup + groupId + '/1/200', options);
                if (response.header.code === 200) {
                    if (response.items.length <= 0) {
                        list = ["null"];
                    }
                    response.items.forEach(element => {
                        element.userId = element.user.userId;
                        element.username = element.user.username;
                        element.fullName = element.user.fullName;
                        list.push(element);
                    });
                }
                else {
                    list = ["null"];
                }
                yield put(Actions.getDataAgentByGroup(list, null));
            }
            yield delay(200);
            p = 0;
        }
    }
    catch (error) {
        yield put(Actions.getDataAgentByGroup(["null"], null));
    }
}

function* dataAllGroupRuleByAgent({ id: agentId }) {
    options.method = 'GET';
    options.body = null;
    try {
        if (p === 0) {
            let list = [];
            p++;
            if (agentId !== '') {
                const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.getAllGroupRuleByAgent + agentId, options);
                if (response.header.code === 200) {
                    if (response.items.length <= 0) {
                        list = ["null"];
                    }
                    response.items.groupDetails.forEach(element => {
                        element.userId = response.items.userId;
                        list.push(element);
                    });
                }
                else {
                    list = ["null"];
                }
                yield put(Actions.getAllGroupRuleByAgent(list, null));
            }
            yield delay(200);
            p = 0;
        }
    }
    catch (error) {
        yield put(Actions.getAllGroupRuleByAgent(["null"], null));
    }
}

function* dataGroupsType() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.getGroups;
    try {
        if (x === 0) {
            x++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }

            yield put(Actions.getDataGroupType(list));
            yield delay(500);
            x = 0;
        }


    } catch {
        yield put(Actions.getDataGroupType(['null']));
    }
}

function* moveUserGroup({ formData: data }) {
    options.body = JSON.stringify(data);
    options.method = 'POST';
    if (j === 0) {
        j++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.postMoveGroup, options);

        if (response.header.code === 200 || response.header.code === 201) {
            yield put(Actions.submitSucceed(response.message));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        j = 0;
    }

}

function* removeUserGroup({ formData: data }) {
    options.body = JSON.stringify(data);
    options.method = 'POST';
    if (z === 0) {
        z++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.postRemoveGroup, options);

        if (response.header.code === 200 || response.header.code === 201) {
            yield put(Actions.closeDeleteAgentDialog());
            yield put(Actions.submitSucceed(response.message));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        z = 0;
    }

}

function* renewUserSkillLevel({ formData: data }) {
    options.body = JSON.stringify(data);
    options.method = 'POST';
    if (i === 0) {
        i++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.postRenewSkillLevel, options);

        if (response.header.code === 200 || response.header.code === 201) {
            yield put(Actions.submitSucceed(response.message));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        i = 0;
    }

}

function* createGroup({ formData: data }) {
    options.body = JSON.stringify(data);
    options.method = 'POST';
    if (y === 0) {
        y++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.createGroup, options);

        if (response.code === 200 || response.code === 201) {
            yield put(Actions.closeNewGroupDialog());
            yield put(Actions.submitSucceed(response.message));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        y = 0;
    }

}

export function* watchAgentByGroup() {
    yield takeEvery(types.GET_DATA_AGENT_BY_GROUP, dataAgentByGroup);
}

export function* watchAllGroupRuleByAgent() {
    yield takeEvery(types.GET_ALL_GR_RULE_BY_AGENT, dataAllGroupRuleByAgent);
}

export function* watchDataGroup() {
    yield takeEvery(types.GET_DATA_GROUPTYPE, dataGroupsType);
}

export function* watchMoveUserGroup() {
    yield takeEvery(types.MOVE_USER_GROUP, moveUserGroup);
}

export function* watchRemoveUserGroup() {
    yield takeEvery(types.REMOVE_USER_GROUP, removeUserGroup);
}

export function* watchUserSkillLevel() {
    yield takeEvery(types.UPDATE_USER_SKILL_LEVEL, renewUserSkillLevel);
}

export function* watchCreateGroup() {
    yield takeEvery(types.CREATE_NEW_GROUP, createGroup);
}

function* userGroupSaga() {
    yield all([
        fork(watchAgentByGroup),
        fork(watchAllGroupRuleByAgent),
        fork(watchDataGroup),
        fork(watchMoveUserGroup),
        fork(watchRemoveUserGroup),
        fork(watchUserSkillLevel),
        fork(watchCreateGroup)
    ]);
}

export default userGroupSaga;