import * as types from './constants'

var formData = new FormData();
// formData.append('limit', 10);
// formData.append('offset', 0);
const initialState = {
    loading: false,
    dataFiltered: [],
    dataCountry: [],
    dataProvince: [],
    dataDistrict: [],
    dataSubdistrict: [],
    dataNeighborhood: [],
    dataDeliveryPackages: [],
    dataDOCrosscheck: [],
    dataDOTotal: [],
    mapping_type: '',
    showLocation: '',
    dataProvider: [],
    dataLocationStatus: [],
    showMessage: null,
    error: null,
    nonSelectableProvince: [],
    nonSelectableDistrict: [],
    nonSelectableSubdistrict: [],
    nonSelectableNeighborhood: [],
    rowSelected: [],
    rowSelectedIndex: [],
    modalProvider: false,
    dataFilterDate:[],
    dataFilter: formData,
    showAdvanceFilter: false,
    dataStatusMapping: { list: [], countPage: 0, totalElements: 0 },
    dataLocationMapping: { list: [], countPage: 0, totalElements: 0 },
    dataRescueMapping: { list: [], countPage: 0, totalElements: 0 },
    dataSearchObjMapping: {},
    dataFulfillmentLastmile: [],
    dataTMSCodeMapping: [],
    mappingDialog     : {
        type: '',
        props: {
            open: false
        },
        data : null
    },
    confirmDialog: {
        props: {
            open: false
        },
        data : null
    }
}

const Logistic = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATA_COUNTRY:
            return { ...state, dataCountry: action.payload, loading: action.loading, mapping_type: action.mapping, showLocation: action.location };
        case types.GET_DATA_PROVINCE:
            return { ...state, dataProvince: action.payload, nonSelectableProvince: action.listNonSelected, dataFiltered: action.payload, loading: action.loading };
        case types.GET_DATA_DISTRICT:
            return { ...state, dataDistrict: action.payload, nonSelectableDistrict: action.listNonSelected, dataFiltered: action.payload, loading: action.loading };
        case types.GET_DATA_SUBDISTRICT:
            return { ...state, dataSubdistrict: action.payload, nonSelectableSubdistrict: action.listNonSelected, dataFiltered: action.payload, loading: action.loading };
        case types.GET_DATA_NEIGHBORHOOD:
            return { ...state, dataNeighborhood: action.payload, nonSelectableNeighborhood: action.listNonSelected, dataFiltered: action.payload, loading: action.loading };
        case types.DELIVERY_PACKAGES_BY_LASTMILE_ID:
            return { ...state, dataDeliveryPackages: action.payload };
        case types.GET_DATA_PROVIDER:
            return { ...state, dataProvider: action.payload, loading: action.loading };
        case types.GET_DATA_LOCATION_STATUS:
            return { ...state, dataLocationStatus: action.payload, loading: action.loading };
        case types.GET_DO_CROSSCHECK:
            return { ...state, dataDOCrosscheck: action.payload, loading: action.loading };
        case types.DATA_TOTAL:
            return { ...state, dataDOTotal: action.payload };
        case types.SET_FILTER_SEARCH:
            return { ...state, dataFilter: action.payload };
        case types.SHOW_ADVANCE_FILTER_SEARCH:
            return { ...state, showAdvanceFilter: action.payload };
        case types.SHOW_MODAL_PROVIDER:
            return { ...state, modalProvider: true }
        case types.HIDE_MODAL_PROVIDER:
            return { ...state, modalProvider: false }
        case types.PUT_DATA_LOCATION:
            return { ...state, loading: true }
        case types.EDIT_LOCATION_STATUS:
            return { ...state, loading: true }
        case types.EDIT_DATA_LOCATION:
            return { ...state, loading: true }
        case types.SUBMIT_SUCCEED:
            return { ...state, showMessage: action.payload, error: null, loading: false }
        case types.SUBMIT_FAILED:
            return { ...state, showMessage: null, error: action.payload, loading: false }
        case types.RESET_MESSAGE:
            return { ...state, showMessage: null, error: null }
        case types.SELECTED_ROW:
            return { ...state, rowSelected: action.payload }
        case types.SELECTED_ROW_INDEX:
            return { ...state, rowSelectedIndex: action.payload }    
        case types.FILTER_MULTIPLE_PROVINCE:
            return { ...state, dataProvince: action.payload }
        case types.FILTER_MULTIPLE_DISTRICT:
            return { ...state, dataDistrict: action.payload }
        case types.FILTER_MULTIPLE_SUB_DISTRICT:
            return { ...state, dataSubdistrict: action.payload }
        case types.FILTER_MULTIPLE_NEIGHBORHOOD:
            return { ...state, dataNeighborhood: action.payload }
        case types.RESET_LOADING:
            return { ...state, loading: false }
        case types.OPEN_LOADING:
            return { ...state, loading: true }
        case types.GET_DATA_STATUS_MAPPING:
            return { ...state, dataStatusMapping: action.payload, loading: action.loading }
        case types.EDIT_DATA_STATUS_MAPPING:
            return { ...state, loading: true }
        case types.GET_DATA_LOCATION_MAPPING:
            return { ...state, dataLocationMapping: action.payload, loading: action.loading }
        case types.EDIT_DATA_LOCATION_MAPPING:
            return { ...state, loading: true }
        case types.GET_DATA_RESCUE_MAPPING:
            return { ...state, dataRescueMapping: action.payload, loading: action.loading }
        case types.EDIT_DATA_RESCUE_MAPPING:
            return { ...state, loading: true }
        case types.GET_DATA_FULFILLMENT_LASTMILE:
            return { ...state, loading: action.loading, dataFulfillmentLastmile: action.payload }
        case types.GET_DATA_TMS_CODE_MAPPING:
            return { ...state, loading: action.loading, dataTMSCodeMapping: action.payload }
        case types.OPEN_EDIT_MAPPING_DIALOG:
            return { ...state, mappingDialog: {
                    props: {
                        open: true
                    },
                    data : action.data,
                    type: action.types
                }
            }
        case types.CLOSE_EDIT_MAPPING_DIALOG:
            return { ...state, mappingDialog: {
                    props: {
                        open: false
                    },
                    data : null,
                    type: '',
                }
            }
        case types.OPEN_ADD_MAPPING_DIALOG:
            return { ...state, mappingDialog: {
                    props: {
                        open: true
                    },
                    data : action.data,
                    type: action.types
                }
            }
        case types.CLOSE_ADD_MAPPING_DIALOG:
            return { ...state, mappingDialog: {
                    props: {
                        open: false
                    },
                    data : null,
                    type: '',
                }
            }
        case types.OPEN_CONFIRM_DIALOG:
            return { ...state, confirmDialog: {
                    props: {
                        open: true
                    },
                    data : action.data,
                }
            }
        case types.CLOSE_CONFIRM_DIALOG:
            return { ...state, confirmDialog: {
                    props: {
                        open: false
                    },
                    data : null,
                }
            }
        case types.EXPORT_DATA_MAPPING: 
            return { ...state, loading: true }
        case types.IMPORT_DATA_MAPPING: 
            return { ...state, loading: true }
        case types.SET_DATA_SEARCH_OBJ_MAPPING:
            return { ...state, dataSearchObjMapping: action.payload }
        case types.RESET_LOCATION_PROVINCE:
            return { ...state, dataDistrict: [], dataSubdistrict: [], dataNeighborhood: [] }
        case types.RESET_LOCATION_DISTRICT:
            return { ...state, dataSubdistrict: [], dataNeighborhood: [] }
        case types.RESET_LOCATION_SUBDISTRICT:
            return { ...state, dataNeighborhood: [] }
        case types.CREATE_DATA_LOCATION_MAPPING:
            return { ...state, loading: true }
        case types.DELETE_DATA_LOCATION_MAPPING:
            return { ...state, loading: true }
        case types.DELETE_DATA_STATUS_MAPPING:
            return { ...state, loading: true }
        case types.DELETE_DATA_RESCUE_MAPPING:
            return { ...state, loading: true }
        default:
            return { ...state };
    }
}
export default Logistic