const campaignM ={
    'TITLE': 'Danh sách chiến lượt bán hàng',
    'SEARCH': 'Tìm kiếm Chiến dịch',
    'NEW_CAMPAIGN': 'Chiến dịch Mới',
    'CAMPAIGN_NAME': 'Tên Chiến dịch',
    'OWNER_NAME': 'Tên chủ sở hữu',
    'START_TIME': 'Thời gian bắt đầu',
    'STOP_TIME': 'Thời gian kết thúc',
    'STATUS': 'Trạng thái',
    'CAMPAIGN_TYPE': 'Loại Chiến dịch',
    'CAMPAIGN_CATE': 'Danh mục Chiến dịch',
    'CAMPAIGN_TAG': 'Thẻ chiến dịch',
    'NO_DATA': 'Không có dữ liệu để hiển thị',
    'CLOSE': 'Đóng',
    'CLEAR': 'Xóa bộ lọc',
    'STATUS_NAME': 'Tên trạng thái',
    'CATEGORY': 'Danh mục',
    'SUB_CATEGORY': 'Danh mục con',
    'TAG': 'Thẻ',
    'START_DATE': 'Ngày bắt đầu',
    'STOP_DATE': 'Ngày kết thúc',
    'ALERT_1': 'Bạn có chắc chắn bắt đầu chiến dịch này không?',
    'ALERT_2': 'Bạn có chắc chắn dừng chiến dịch này không?',
    'ALERT_3': 'Bạn có chắc chắn xóa chiến dịch này không?',
    'ADD': 'Thêm',
    'SAVE': 'Lưu',
    'CONFIRM': 'Xác nhận',
    'CANCEL': 'Hủy bỏ',
}

export default campaignM;