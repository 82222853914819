const slaLogistic ={
    'TITLE': 'SLA logistic',
    'CREATE': 'Create',
    'IMPORT': 'Import',
    'EXPORT': 'Export',
    'FULFILMENT': 'Fulfillment',
    'WAREHOUSE': 'Warehouse',
    'LASTMILE': 'Lastmile',
    'SERVICE': 'Service',
    'PROVINCE_ID': 'Province ID',
    'PROVINCE_NAME': 'Province Name',
    'DISTRICT_ID': 'District ID',
    'DISTRICT_NAME': 'District Name',
    'SUB_DISTRICT_ID': 'Sub-District ID',
    'SUB_DISTRICT_NAME': 'Sub-District Name',
    'BEFORE': 'Before Cut Off Time',
    'AFTER': 'After Cut Off Time',
    'CROSS_REGION': 'Cross Region',
    'UPDATE_BY': 'Update By',
    'UPDATE_AT': 'Update At',
    'ACTION': 'Action',
    'PROVINCE': 'Province',
    'DISTRICT': 'District',
    'SUB_DISTRICT': 'Sub-District',
}

export default slaLogistic