import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';

import { fetchJSON } from '../../../helpers/api';

import * as types from './constants';

import apiConfig from '../../../config/routesApi';

import * as Actions from './actions';
import axios from 'axios';

var j = 0, i = 0, k = 0, m = 0, n = 0, a = 0;
const delay = (ms) => new Promise(res => setTimeout(res, ms));
const cookie = new Cookies();
const user_token = cookie.get("user_access");

var options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '') }
    //+ (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '')
};

function* dataAgentStatus() {
    options.method = 'GET';
    try {
        if (i === 0) {
            let list = [];
            i++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainCMMs + apiConfig.apiEventCMMs.getDashboard, options);
            if (response.header.code === 200) {
                const data = response.items;
                if(data){
                    data.forEach((log, index) => {
                        log.keyIndex = index;
                        list.push(log);
                    })
                }
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataAgentStatus(list));
            yield delay(1);
            i = 0;
        }
    }
    catch (error) {
        yield put(Actions.getDataAgentStatus(["null"]));
    }
}

function* dataChart() {
    options.method = 'GET';
    try {
        if (j === 0) {
            let list = [];
            j++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainCMMs + apiConfig.apiEventCMMs.getChart, options);
            if (response.header.code === 200) {
                list = response.items;
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataChart(list));
            yield delay(500);
            j = 0;
        }
    }
    catch (error) {
        yield put(Actions.getDataChart(["null"]));
    }
}

function* dataInboundTicket() {
    options = {...options, method: 'GET'};
   
    try {
        if (k === 0) {
            let list = [];
            k++;
            const response = yield call(axios.get, apiConfig.apiGateway.mainCMMs + apiConfig.apiDashboardInbound.getInboundTicket, options);
            if (response.data.header.code === 200) {
                list.push(response.data.items);
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataInboundTicket(list));
            yield delay(500);
            k = 0;
        }
    }
    catch (error) {
        yield put(Actions.getDataInboundTicket(["null"]));
    }
}
function* dataChartServices({ chartServices }) {
    // options.method = 'POST';
    options = {...options, method: 'POST'};
    options.body = JSON.stringify(chartServices);
    try {
        if (m === 0) {
            let list = [];
            m++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainCMMs + apiConfig.apiDashboardInbound.getChartServices, options);
            if (response.header.code === 200) {
                list = response.items;
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getChartServices(list, []));
            yield delay(500);
            m = 0;
        }
    }
    catch (error) {
        yield put(Actions.getChartServices(["null"]));
    }
}
function* dataChartAbanCall({ chartAbanCall }) {
    // options.method = 'POST';
    options = {...options, method: 'POST'};
    options.body = JSON.stringify(chartAbanCall);
    try {
        if (n === 0) {
            let list = [];
            n++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainCMMs + apiConfig.apiDashboardInbound.getChartAbanCall, options);
            if (response.header.code === 200) {
                list = response.items;
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getChartAbanCall(list, []));
            yield delay(500);
            n = 0;
        }
    }
    catch (error) {
        yield put(Actions.getChartAbanCall(["null"]));
    }
}
function* dataInboundTotalCS() {
    options = {...options, method: 'GET'};
    try {
        if (a === 0) {
            let list = [];
            a++;
            const response = yield call(axios.get, apiConfig.apiGateway.mainCMMs + apiConfig.apiDashboardInbound.getInboundTotalCS,options);
            if (response.data.header.code === 200) {
                list.push(response.data.items);
                
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataInboundTotalCS(list));
            yield delay(500);
            a = 0;
        }
    }
    catch (error) {
        yield put(Actions.getDataInboundTotalCS(["null"]));
    }
}

export function* watchDataAgentStatus() {
    yield takeEvery(types.GET_DATA_DASHBOARD_AGENT_STATUS, dataAgentStatus);
}

export function* watchDataChart() {
    yield takeEvery(types.GET_DATA_CHART, dataChart);
}
export function* watchDataInboundTicket() {
    yield takeEvery(types.GET_DATA_INBOUND_TICKET, dataInboundTicket);
}
export function* watchChartServices() {
    yield takeEvery(types.GET_CHART_SERVICES, dataChartServices);
}
export function* watchChartAbanCall() {
    yield takeEvery(types.GET_CHART_ABAN_CALL, dataChartAbanCall);
}
export function* watchDataInboundTotalCS() {
    yield takeEvery(types.GET_DATA_INBOUND_TOTAL_CS, dataInboundTotalCS);
}


function* dashboardCCMsSaga() {
    yield all([
        fork(watchDataAgentStatus),
        fork(watchDataChart),
        fork(watchDataInboundTicket),
        fork(watchChartServices),
        fork(watchChartAbanCall),
        fork(watchDataInboundTotalCS),
    ]);
}


export default dashboardCCMsSaga;