import * as types from './constants';

export const getDataAgentByTeam = (data, teamId) => {
    return ({
        type: types.GET_DATA_AGENT_BY_TEAM,
        payload: data,
        loading: data?.length <= 0 ? true : false,
        id: teamId
    })
}

export function openDeleteAgentDialog(data)
{
    return {
        type: types.OPEN_DELETE_AGENT_DIALOG,
        data
    }
}

export function closeDeleteAgentDialog()
{
    return {
        type: types.CLOSE_DELETE_AGENT_DIALOG
    }
}

export const openNewAgentDialog = () =>
{
    return {
        type: types.OPEN_NEW_AGENT_DIALOG
    }
}

export function closeNewAgentDialog()
{
    return {
        type: types.CLOSE_NEW_AGENT_DIALOG
    }
}

export function moveUserTeam(data) {
    return {
        type: types.MOVE_USER_TEAM,
        formData: data
    }
}

export function removeUserTeam(data) {
    return {
        type: types.REMOVE_USER_TEAM,
        formData: data
    }
}

export const submitSucceed = (message) => ({
    type: types.SUBMIT_SUCCEED,
    payload: message
});

export const submitFailed = (message) => ({
    type: types.SUBMIT_FAILED,
    payload: message
});

export const resetMessage = () => ({
    type: types.RESET_MESSAGE
});