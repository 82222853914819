const agentSummaries = {
    'TITLE': 'Tóm tắt cuộc gọi',
    'OUTBOUND': 'Cuộc gọi đi',
    'INBOUND': 'Cuộc gọi đến',
    'SEARCH': 'Tìm kiếm',
    'AUDIO': 'Bảng ghi âm',
    'LEADID': 'ID khách hàng',
    'UNIQUEID': 'ID duy nhất',
    'LEAD_NAME': 'Tên khách hàng',
    'LEAD_PHONE': 'Số điện thoại',
    'CALL_STATUS': 'Trạng thái cuộc gọi',
    'RING_TIME': 'Thời gian đổ chuông',
    'TALK_TIME': 'Thời gian nói chuyện',
    'DURATION': 'Thời lượng',
    'SERVING_TIME': 'Thời gian phục vụ',
    'AG_SIP': 'Số sip tư vấn viên',
    'AG_NAME': 'Tên tư vấn viên',
    'CALL_STARTTIME': 'Thời gian bắt đầu cuộc gọi',
    'CALL_ENDTIME': 'Thời gian kết thúc cuộc gọi',
    'CLOSE': 'Đóng',
    'APPLY_FILTER': 'Áp dụng',
    'CLEAR_FILTER': 'Làm mới',
    'LEAD_STATUS': 'Trạng thái Lead',
    'AGENTID': 'ID đại lý',
    'E_LEADID': 'Nhập ID khách hàng',
    'E_LEAD_STATUS': 'Nhập trạng thái khách hàng',
    'E_LEAD_NAME': 'Nhập tên khách hàng',
    'E_AG_SIP': 'Nhập số sip tư vấn viên',
    'E_AGENTID': 'Nhập agent ID',
    'E_AG_NAME': 'Nhập tên tư vấn viên',
    'E_UNIQUEID': 'Nhập unique ID',
    'E_CALL_STATUS': 'Nhập trạng thái cuộc gọi',
    'E_CALL_START': 'Nhập thời gian bắt đầu cuộc gọi',
    'E_CALL_END': 'Nhập thời gian kết thúc cuộc gọi',
    'E_PHONE': 'Nhập số điện thoại',
    'ANSWERED': 'Đã trả lời',
    'BUSY': 'Bận',
    'NO_ANSWER': 'Không trả lời',
    'PHONE': 'Số điện thoại',
    'START_END_TIME': 'Thời gian bắt đầu cuộc gọi & thời gian kết thúc cuộc gọi',
    'E_START_END': 'Nhập thời gian bắt đầu cuộc gọi và thời gian kết thúc cuộc gọi'
};
export default agentSummaries;