import * as types from './constants';

export const getDataCountry = (data, mapping_type, location_level) => {
    return ({
        type: types.GET_DATA_COUNTRY,
        payload: data,
        mapping: mapping_type,
        location: location_level,
        loading: data?.length <= 0 ? true : false
    })
}

export const getDataProvince = (data, nonSelectableProvince) => {
    return ({
        type: types.GET_DATA_PROVINCE,
        payload: data,
        listNonSelected: nonSelectableProvince,
        loading: data?.length <= 0 ? true : false
    })
}

export const getDataDistrict = (data, idProvince, mapping_type, nonSelectableDistrict) => {
    return ({
        type: types.GET_DATA_DISTRICT,
        payload: data,
        id: idProvince,
        mapping: mapping_type,
        listNonSelected: nonSelectableDistrict,
        loading: data?.length <= 0 ? true : false
    })
}

export const getDataSubdistrict = (data, idDistrict, mapping_type, nonSelectableSubdistrict) => {
    return ({
        type: types.GET_DATA_SUBDISTRICT,
        payload: data,
        id: idDistrict,
        mapping: mapping_type,
        listNonSelected: nonSelectableSubdistrict,
        loading: data?.length <= 0 ? true : false
    })
}

export const getDataNeighborhood = (data, idSubdistrict, nonSelectableNeighborhood) => {
    return ({
        type: types.GET_DATA_NEIGHBORHOOD,
        payload: data,
        id: idSubdistrict,
        listNonSelected: nonSelectableNeighborhood,
        loading: data?.length <= 0 ? true : false
    })
}

export const getDataDeliveryPackages = (data, lastmileId) => {
    return ({
        type: types.DELIVERY_PACKAGES_BY_LASTMILE_ID,
        payload: data,
        lastmileId: lastmileId
    })
}

export const getDataProvider = (data) => {
    return ({
        type: types.GET_DATA_PROVIDER,
        payload: data,
        loading: data?.length <= 0 ? true : false
    })
}

export const getDataLocationStatus = (data) => {
    return ({
        type: types.GET_DATA_LOCATION_STATUS,
        payload: data,
        loading: data?.length <= 0 ? true : false
    })
}

export const putSubmitLocation = (data, type) => {
    return ({
        type: types.PUT_DATA_LOCATION,
        payload: data,
        location_type: type
    })
}

export const deleteSchedule = (id) => {
    return ({
        type: types.DELETE_DATA_SCHEDULE,
        payload: id,
    })
}

export const submitSucceed = (message) => ({
    type: types.SUBMIT_SUCCEED,
    payload: message
});

export const submitFailed = (message) => ({
    type: types.SUBMIT_FAILED,
    payload: message
});

export const resetMessage = () => ({
    type: types.RESET_MESSAGE
})

export const setRowSelected = (data) => ({
    type: types.SELECTED_ROW,
    payload: data
})

export const setRowSelectedIndex = (data) => ({
    type: types.SELECTED_ROW_INDEX,
    payload: data
})

export const editSubmitLocation = (data) => ({
    type: types.EDIT_DATA_LOCATION,
    payload: data
})

export const editSubmitLocationStatus = (data) => ({
    type: types.EDIT_LOCATION_STATUS,
    payload: data
})

export const showModalProvider = () => ({
    type: types.SHOW_MODAL_PROVIDER
})

export const hideModalProvider = () => ({
    type: types.HIDE_MODAL_PROVIDER
})

export const filterMultipleProvinces = (data) => ({
    type: types.FILTER_MULTIPLE_PROVINCE,
    payload: data
})

export const filterMultipleDistricts = (data) => ({
    type: types.FILTER_MULTIPLE_DISTRICT,
    payload: data
})

export const filterMultipleSubDistricts = (data) => ({
    type: types.FILTER_MULTIPLE_SUB_DISTRICT,
    payload: data
})

export const filterMultipleNeighborhoods = (data) => ({
    type: types.FILTER_MULTIPLE_NEIGHBORHOOD,
    payload: data
})

export const getDOCrosscheck = (data, dataDate) => {
    return ({
        type: types.GET_DO_CROSSCHECK,
        payload: data,
        dataDate: dataDate,
        loading: data?.length <= 0 ? true : false
    })
}

export const getDOTotal = (data, bodyTotal) => {
    return ({
        type: types.DATA_TOTAL,
        payload: data,
        bodyTotal: bodyTotal
    })
}

export const setFilter = (data) => {
    return ({
        type: types.SET_FILTER_SEARCH,
        payload: data
    })
}

export const showAdvanceFilterSearch = (data) => {
    return ({
        type: types.SHOW_ADVANCE_FILTER_SEARCH,
        payload: data
    })
}

export const resetLoading = () => ({
    type: types.RESET_LOADING
})

export const openLoading = () => ({
    type: types.OPEN_LOADING
})

export const statusMapping = (data, formSearch, filter, index, size) => {
    return ({
        type: types.GET_DATA_STATUS_MAPPING,
        payload: data,
        searchObj: formSearch,
        filterObj: filter,
        pageIndex: index,
        pageSize: size,
        loading: !data.list ? true : false
    })
}

export const locationMapping = (data, formSearch, filter, index, size) => {
    return ({
        type: types.GET_DATA_LOCATION_MAPPING,
        payload: data,
        searchObj: formSearch,
        filterObj: filter,
        pageIndex: index,
        pageSize: size,
        loading: !data.list ? true : false
    })
}

export const rescueMapping = (data, formSearch, filter, index, size) => {
    return ({
        type: types.GET_DATA_RESCUE_MAPPING,
        payload: data,
        searchObj: formSearch,
        filterObj: filter,
        pageIndex: index,
        pageSize: size,
        loading: !data.list ? true : false
    })
}

export const dataFulfillmentLastmile = (data) => {
    return ({
        type: types.GET_DATA_FULFILLMENT_LASTMILE,
        payload: data,
        loading: !data ? true : false
    })
}

export const dataTMSCodeMapping = (data) => {
    return ({
        type: types.GET_DATA_TMS_CODE_MAPPING,
        payload: data,
        loading: !data ? true : false
    })
}

export function openEditMappingDialog(data) {
    return {
        type: types.OPEN_EDIT_MAPPING_DIALOG,
        data,
        types: 'Edit',
    }
}

export function closeEditMappingDialog() {
    return {
        type: types.CLOSE_EDIT_MAPPING_DIALOG
    }
}

export function openAddMappingDialog(data) {
    return {
        type: types.OPEN_ADD_MAPPING_DIALOG,
        data,
        types: 'Add',
    }
}

export function closeAddMappingDialog() {
    return {
        type: types.CLOSE_ADD_MAPPING_DIALOG
    }
}

export function openConfirmDialog(data) {
    return {
        type: types.OPEN_CONFIRM_DIALOG,
        data,
    }
}

export function closeConfirmDialog() {
    return {
        type: types.CLOSE_CONFIRM_DIALOG
    }
}

export function editStatusMapping(data, dataStatusMapping, searchObj) {
    return {
        type: types.EDIT_DATA_STATUS_MAPPING,
        formData: data,
        dataList: dataStatusMapping,
        search: searchObj
    }
}

export function editLocationMapping(data, dataLocationMapping, searchObj) {
    return {
        type: types.EDIT_DATA_LOCATION_MAPPING,
        formData: data,
        dataList: dataLocationMapping,
        search: searchObj
    }
}

export function editRescueMapping(data, dataRescueMapping, searchObj) {
    return {
        type: types.EDIT_DATA_RESCUE_MAPPING,
        formData: data,
        dataList: dataRescueMapping,
        search: searchObj
    }
}

export function exportDataMapping() {
    return {
        type: types.EXPORT_DATA_MAPPING,
    }
}

export function importDataMapping() {
    return {
        type: types.IMPORT_DATA_MAPPING,
    }
}

export function setDataSearchObjMapping(searchObj) {
    return {
        type: types.SET_DATA_SEARCH_OBJ_MAPPING,
        payload: searchObj
    }
}

export function resetLocationProvince() {
    return {
        type: types.RESET_LOCATION_PROVINCE,
        payload: [],
    }
}

export function resetLocationDistrict() {
    return {
        type: types.RESET_LOCATION_DISTRICT,
        payload: [],
    }
}

export function resetLocationSubDistrict() {
    return {
        type: types.RESET_LOCATION_SUBDISTRICT,
        payload: [],
    }
}

export function createLocationMapping(data, dataLocationMapping, searchObj) {
    return {
        type: types.CREATE_DATA_LOCATION_MAPPING,
        formData: data,
        dataList: dataLocationMapping,
        search: searchObj
    }
}

export function createStatusMapping(data, dataStatusMapping, searchObj) {
    return {
        type: types.CREATE_DATA_STATUS_MAPPING,
        formData: data,
        dataList: dataStatusMapping,
        search: searchObj
    }
}

export function createRescueMapping(data, dataRescueMapping, searchObj) {
    return {
        type: types.CREATE_DATA_RESCUE_MAPPING,
        formData: data,
        dataList: dataRescueMapping,
        search: searchObj
    }
}

export function deleteLocationMapping(data, dataLocationMapping, searchObj) {
    return {
        type: types.DELETE_DATA_LOCATION_MAPPING,
        formData: data,
        dataList: dataLocationMapping,
        search: searchObj
    }
}

export function deleteStatusMapping(data, dataStatusMapping, searchObj) {
    return {
        type: types.DELETE_DATA_STATUS_MAPPING,
        formData: data,
        dataList: dataStatusMapping,
        search: searchObj
    }
}

export function deleteRescueMapping(data, dataRescueMapping, searchObj) {
    return {
        type: types.DELETE_DATA_RESCUE_MAPPING,
        formData: data,
        dataList: dataRescueMapping,
        search: searchObj
    }
}