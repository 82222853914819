const inboundSummaries = {
    'TITLE': 'Inbound Summaries',
    'TODAY': 'Today',
    'WEEK': 'This week',
    'MONTH': 'This month',
    'PERIOD': 'Period',
    'SEARCH': 'Search',
    'EXPORT': 'Export',
    'TEAM': 'Team',
    'AGENT': 'Agent',
    'TOTAL_CALL': 'Total Calls',
    'ANSWER_CALL': 'Answered Calls',
    'TOTAL_ABANCALL': 'Total Aban Calls',
    'SHORT_ABANCALL': 'Short Aban Calls',
    'ABAN_CALL': 'Aban Calls',
    'TOTAL_SER_TIME': 'Total Serving Time',
    'TOTAL_TALK_TIME': 'Total Talk Time',
    'TOTAL_RING_TIME': 'Total Ring Time',
    'AVG_TALK_TIME': 'Avg Talk Time',
    'AVG_SER_TIME': 'Avg Serving Time',
    'USERNAME': 'Username',
    'EXTENSION': 'Extension',
};
export default inboundSummaries;