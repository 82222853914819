const slaLogistic ={
    'TITLE': 'SLA logistic',
    'CREATE': 'Tạo',
    'IMPORT': 'Nhập',
    'EXPORT': 'Xuất',
    'FULFILMENT': 'Hoàn thành',
    'WAREHOUSE': 'Kho',
    'LASTMILE': 'Lastmile',
    'SERVICE': 'Dịch vụ',
    'PROVINCE_ID': 'ID Tỉnh',
    'PROVINCE_NAME': 'Tên tỉnh',
    'DISTRICT_ID': 'ID Quận',
    'DISTRICT_NAME': 'Tên Quận',
    'SUB_DISTRICT_ID': 'ID tiểu khu',
    'SUB_DISTRICT_NAME': 'Tên tiểu khu',
    'BEFORE': 'Trước thời gian giới hạn',
    'AFTER': 'Sau thời gian giới hạn',
    'CROSS_REGION': 'Xuyên vùng',
    'UPDATE_BY': 'Cập nhật bởi',
    'UPDATE_AT': 'Cập nhật lúc',
    'ACTION': 'Hành động',
    'PROVINCE': 'Tỉnh',
    'DISTRICT': 'Quận',
    'SUB_DISTRICT': 'Tiểu khu',
}

export default slaLogistic