import * as types from './constants';

export const getDataAgentStatus = (data) => {
    return ({
        type: types.GET_DATA_DASHBOARD_AGENT_STATUS,
        payload: data,
        loading: data?.length <= 0 ? true : false
    })
}

export const getDataChart = (data) => {
    return ({
        type: types.GET_DATA_CHART,
        payload: data,
    })
}
export const getDataInboundTicket = (data) => {
    return ({
        type: types.GET_DATA_INBOUND_TICKET,
        payload: data,
    })
}
export const getDataInboundTotalCS = (data) => {
    return ({
        type: types.GET_DATA_INBOUND_TOTAL_CS,
        payload: data,
    })
}
export const getChartServices = (data, chartServices) => {
    return ({
        type: types.GET_CHART_SERVICES,
        payload: data,
        chartServices: chartServices,
        loading: data?.length <= 0 ? true : false,
    })
}
export const getChartAbanCall = (data, chartAbanCall) => {
    return ({
        type: types.GET_CHART_ABAN_CALL,
        payload: data,
        chartAbanCall: chartAbanCall,
        loading: data?.length <= 0 ? true : false,
    })
}

export const filterMultipleGroup = (data) => ({
    type: types.FILTER_MULTIPLE_GROUP,
    payload: data
})

export const filterMultipleUser = (data) => ({
    type: types.FILTER_MULTIPLE_USER,
    payload: data
})

export const filterMultipleTeam = (data) => ({
    type: types.FILTER_MULTIPLE_TEAM,
    payload: data
})

export const submitSucceed = (message) => ({
    type: types.SUBMIT_SUCCEED,
    payload: message
});

export const submitFailed = (message) => ({
    type: types.SUBMIT_FAILED,
    payload: message
});

export const resetMessage = () => ({
    type: types.RESET_MESSAGE
});
