const inboudDashboard = {
    'INBOUND_CALL': 'Trung Tâm Cuộc Gọi',
    'CENTER': 'Đến',
    'TOTAL_CALL': 'Tổng số cuộc gọi',
    'WAITING': 'Đang chờ đợi',
    'ANSWER': 'Đã trả lời',
    'ABANDONED': 'Cuộc gọi nhỡ',
    'TODAY' : 'Hôm nay',
    'YESTERDAY' : 'Hôm qua',
    'WEEK' : 'Tuần này',
    'MONTH' : 'Tháng này',
    'SERVICE_LEVEL' : '% Mức dịch vụ (SL)',
    'ABANDON_RATE' : 'Tỷ lệ cuộc gọi nhỡ',
    'TOTAL_CS_STATUS' : 'Tổng trạng thái CS',
    'STATISTIC' : 'Thống kê',
    'USERNAME' : 'Tài khoản',
    'STATUS' : 'Trạng thái',
    'ANSWER_CALL' : 'Cuộc gọi đã trả lời',
    'TIME' : 'Thời gian',
};
export default inboudDashboard;