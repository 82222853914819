const inboundSummaries = {
    'TITLE': 'Tóm tắt cuộc gọi đến',
    'TODAY': 'Hôm nay',
    'WEEK': 'Tuần này',
    'MONTH': 'Tháng này',
    'PERIOD': 'Giai đoạn',
    'SEARCH': 'Tìm kiếm',
    'EXPORT': 'Xuất',
    'TEAM': 'Đội',
    'AGENT': 'Tư vấn viên',
    'TOTAL_CALL': 'Tổng số cuộc gọi',
    'ANSWER_CALL': 'Cuộc gọi đã trả lời',
    'TOTAL_ABANCALL': 'Tổng số cuộc gọi nhỡ',
    'SHORT_ABANCALL': 'Cuộc gọi nhỡ ngắn',
    'ABAN_CALL': 'Cuộc gọi nhỡ',
    'TOTAL_SER_TIME': 'Tổng thời gian phục vụ',
    'TOTAL_TALK_TIME': 'Tổng thời gian thoại',
    'TOTAL_RING_TIME': 'Tổng thời gian đổ chuông',
    'AVG_TALK_TIME': 'Thời gian thoại trung bình',
    'AVG_SER_TIME': 'Thời gian phục vụ trung bình',
    'USERNAME': 'Tài khoản',
    'EXTENSION': 'Extension',
};
export default inboundSummaries;