import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next  } from "react-i18next";

  import en from './languages/en/index';
  import vi from './languages/vi/index';

const resources = {
    en: {
      translation: en
    },
    vi: {
      translation: vi
    }
  }

  i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "en",
    fallbackLng: localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "en", // use en if detected lng is not available
    
    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;