export const GET_DATA_COUNTRY = 'GET_DATA_COUNTRY';
export const GET_DATA_PROVINCE = 'GET_DATA_PROVINCE';
export const GET_DATA_DISTRICT = 'GET_DATA_DISTRICT';
export const GET_DATA_SUBDISTRICT = 'GET_DATA_SUBDISTRICT';
export const GET_DATA_NEIGHBORHOOD = 'GET_DATA_NEIGHBORHOOD';
export const GET_DATA_PROVIDER = 'GET_DATA_PROVIDER';
export const GET_DATA_LOCATION_STATUS = 'GET_DATA_LOCATION_STATUS';
export const PUT_DATA_LOCATION = 'PUT_DATA_LOCATION';
export const DELETE_DATA_SCHEDULE = 'DELETE_DATA_SCHEDULE';
export const SUBMIT_SUCCEED = 'SUBMIT_SUCCEED';
export const SUBMIT_FAILED = 'SUBMIT_FAILED';
export const RESET_MESSAGE = 'RESET_MESSAGE';
export const SELECTED_ROW = 'SELECTED_ROW';
export const SELECTED_ROW_INDEX = 'SELECTED_ROW_INDEX';
export const EDIT_DATA_LOCATION = 'EDIT_DATA_LOCATION';
export const EDIT_LOCATION_STATUS = 'EDIT_LOCATION_STATUS';
export const SHOW_MODAL_PROVIDER = 'SHOW_MODAL_PROVIDER';
export const HIDE_MODAL_PROVIDER = 'HIDE_MODAL_PROVIDER';
export const FILTER_MULTIPLE_PROVINCE = 'FILTER_MULTIPLE_PROVINCE';
export const FILTER_MULTIPLE_DISTRICT = 'FILTER_MULTIPLE_DISTRICT';
export const FILTER_MULTIPLE_SUB_DISTRICT = 'FILTER_MULTIPLE_SUB_DISTRICT';
export const FILTER_MULTIPLE_NEIGHBORHOOD = 'FILTER_MULTIPLE_NEIGHBORHOOD';
export const GET_DO_CROSSCHECK = 'GET_DO_CROSSCHECK';
export const DATA_FILTER_DATE = 'DATA_FILTER_DATE';
export const DATA_TOTAL = 'DATA_TOTAL';
export const SET_FILTER_SEARCH = 'SET_FILTER_SEARCH';
export const SHOW_ADVANCE_FILTER_SEARCH = 'SHOW_ADVANCE_FILTER_SEARCH';
export const RESET_LOADING = 'RESET_LOADING';
export const GET_DATA_STATUS_MAPPING = 'GET_DATA_STATUS_MAPPING';
export const GET_DATA_LOCATION_MAPPING = 'GET_DATA_LOCATION_MAPPING';
export const GET_DATA_RESCUE_MAPPING = 'GET_DATA_RESCUE_MAPPING';
export const GET_DATA_TMS_CODE_MAPPING = 'GET_DATA_TMS_CODE_MAPPING';
export const EDIT_DATA_STATUS_MAPPING = 'EDIT_DATA_STATUS_MAPPING';
export const EDIT_DATA_LOCATION_MAPPING = 'EDIT_DATA_LOCATION_MAPPING';
export const EDIT_DATA_RESCUE_MAPPING = 'EDIT_DATA_RESCUE_MAPPING';
export const GET_DATA_FULFILLMENT_LASTMILE = 'GET_DATA_FULFILLMENT_LASTMILE';
export const OPEN_EDIT_MAPPING_DIALOG = 'OPEN_EDIT_MAPPING_DIALOG';
export const CLOSE_EDIT_MAPPING_DIALOG = 'CLOSE_EDIT_MAPPING_DIALOG';
export const OPEN_ADD_MAPPING_DIALOG = 'OPEN_ADD_MAPPING_DIALOG';
export const CLOSE_ADD_MAPPING_DIALOG = 'CLOSE_ADD_MAPPING_DIALOG';
export const OPEN_CONFIRM_DIALOG = 'OPEN_CONFIRM_DIALOG';
export const CLOSE_CONFIRM_DIALOG = 'CLOSE_CONFIRM_DIALOG';
export const EXPORT_DATA_MAPPING = 'EXPORT_DATA_MAPPING';
export const IMPORT_DATA_MAPPING = 'IMPORT_DATA_MAPPING';
export const SET_DATA_SEARCH_OBJ_MAPPING = 'SET_DATA_SEARCH_OBJ_MAPPING';
export const OPEN_LOADING = 'OPEN_LOADING';
export const RESET_LOCATION_PROVINCE = 'RESET_LOCATION_PROVINCE';
export const RESET_LOCATION_DISTRICT = 'RESET_LOCATION_DISTRICT';
export const RESET_LOCATION_SUBDISTRICT = 'RESET_LOCATION_SUBDISTRICT';
export const CREATE_DATA_LOCATION_MAPPING = 'CREATE_DATA_LOCATION_MAPPING';
export const CREATE_DATA_STATUS_MAPPING = 'CREATE_DATA_STATUS_MAPPING';
export const CREATE_DATA_RESCUE_MAPPING = 'CREATE_DATA_RESCUE_MAPPING';
export const DELETE_DATA_LOCATION_MAPPING = 'DELETE_DATA_LOCATION_MAPPING';
export const DELETE_DATA_STATUS_MAPPING = 'DELETE_DATA_STATUS_MAPPING';
export const DELETE_DATA_RESCUE_MAPPING = 'DELETE_DATA_RESCUE_MAPPING';
export const DELIVERY_PACKAGES_BY_LASTMILE_ID = 'DELIVERY_PACKAGES_BY_LASTMILE_ID';