import * as types from './constants';

const initialState = {
    loading: false,
    dataUserTeam: [],
    showMessage: null,
    error: null,
    teamDialog     : {
        type: 'Delete',
        props: {
            open: false
        },
        data : null
    },
}

const TeamManagement = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATA_AGENT_BY_TEAM:
            return { ...state, dataUserTeam: action.payload, loading: action.loading}
        case types.MOVE_USER_TEAM:
            return { ...state, loading: true }
        case types.REMOVE_USER_TEAM:
            return { ...state, loading: true }
        case types.SUBMIT_SUCCEED:
            return { ...state, showMessage: action.payload, error: null, loading: false }
        case types.SUBMIT_FAILED:
            return { ...state, showMessage: null, error: action.payload, loading: false }
        case types.RESET_MESSAGE:
            return { ...state, showMessage: null, error: null }
        case types.OPEN_NEW_AGENT_DIALOG:
            {
                return {
                    ...state,
                    teamDialog: {
                        type : 'New',
                        props: {
                            open: true
                        },
                        data : null
                    }
                };
            }
        case types.CLOSE_NEW_AGENT_DIALOG:
            {
                return {
                    ...state,
                    teamDialog: {
                        type : 'New',
                        props: {
                            open: false
                        },
                        data : null
                    }
                };
            }
        case types.OPEN_DELETE_AGENT_DIALOG:
            {
                return {
                    ...state,
                    teamDialog: {
                        type : 'Delete',
                        props: {
                            open: true
                        },
                        data : action.data
                    }
                };
            }
        case types.CLOSE_DELETE_AGENT_DIALOG:
            {
                return {
                    ...state,
                    teamDialog: {
                        type : 'Delete',
                        props: {
                            open: false
                        },
                        data : null
                    }
                };
            }
        default:
            return { ...state };
    }
}

export default TeamManagement;