import * as types from './constants';

const initialState = {
    loading: false,
    dataAgentStatus: [],
    dataChart: [],
    dataFiltered: [],
    showMessage: null,
    error: null,
    dataInboundTicket: [],
    dataInboundTotalCS: [],
    chartServices:[],
    chartAbanCall:[],
}

const DashboardCCMs = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATA_DASHBOARD_AGENT_STATUS:
        {
            return {...state, dataAgentStatus: action.payload, dataFiltered: action.payload, loading: action.loading }
        }
        case types.GET_DATA_CHART:
        {
            return {...state, dataChart: action.payload }
        }
        case types.GET_DATA_INBOUND_TICKET:
        {
            return {...state, dataInboundTicket: action.payload }
        }
        case types.GET_DATA_INBOUND_TOTAL_CS:
        {
            return {...state, dataInboundTotalCS: action.payload }
        }
        case types.GET_CHART_SERVICES:
        {
            return {...state, chartServices: action.payload }
        }
        case types.GET_CHART_ABAN_CALL:
        {
            return {...state, chartAbanCall: action.payload }
        }
        case types.FILTER_MULTIPLE_GROUP:
            return { ...state, dataAgentStatus: action.payload}
        case types.FILTER_MULTIPLE_USER:
            return { ...state, dataAgentStatus: action.payload}
        case types.FILTER_MULTIPLE_TEAM:
            return { ...state, dataAgentStatus: action.payload}
        case types.SUBMIT_SUCCEED:
            return { ...state, showMessage: action.payload, error: null, loading: false }
        case types.SUBMIT_FAILED:
            return { ...state, showMessage: null, error: action.payload, loading: false }
        case types.RESET_MESSAGE:
            return { ...state, showMessage: null, error: null }
        default:
            return { ...state };
    }
}

export default DashboardCCMs;