const actionLog = {
    'TITLE': 'Action Log',
    'CREATE_DATE': 'Created Date',
    'USERID': 'User ID',
    'USERNAME': 'User Name',
    'MODULENAME': 'Module Type Name',
    'ACIONNAME': 'Action Type Name',
    'TABLE': 'Table',
    'SEARCH': 'Search For Action Log',
    'CLOSE': 'Close',
    'APPLY': 'Apply Filter',
    'CLEAR': 'Clear Filter',
    'PLACEHOLDER1': 'Enter User ID',
    'PLACEHOLDER2': 'Enter User Name',
    'PLACEHOLDER3': 'Enter Module Type Name',
    'PLACEHOLDER4': 'Enter Action Type Name',
    'PLACEHOLDER5': 'Enter Table Name',
};
export default actionLog;