const logistic = {
    'COUNTRY': 'Quốc gia',
    'FULFILLMENT': 'Fulfillment',
    'WAREHOUSE': 'Kho',
    'LASTMILE': 'Lastmile',
    'PREV': 'Trước',
    'PROVINCE': 'Địa bàn tỉnh',
    'PROVINCE_ID': 'ID tỉnh',
    'LOCATION_STATUS': 'Tình trạng vị trí',
    'SCHEDULE_CHANGE': 'Thay đổi lịch trình',
    'ONE_DAY': 'Giao hàng 1 ngày',
    'SAME_DAY': 'Giao hàng trong ngày',
    'CONFIRM': 'Áp dụng',
    'WOULD_APPLY_CHANGE': 'Bạn có muốn áp dụng thay đổi?',
    'IMPORT_PROVIDERS': 'Nhập nhà cung cấp',
    'SEE_TEMPLATE': 'Xem mẫu',
    'IMPORT_LOCATION_STATUS': 'Nhập trạng thái Vị trí',
    'VIEW_REASON_ERRORS': 'Xem lý do lỗi',
    'REMOVE': 'Loại bỏ',
    'IMPORT': 'Nhập',
    'CLOSE': 'Đóng',
    'EDIT': 'Sửa',
    'ADD': 'Thêm',
    'EXPORT': 'Xuất',
    'BULK_EDIT': 'Chỉnh sửa hàng loạt',
    'TYPE_CHANGE': 'Loại thay đổi',
    'CHANGE_ALL': 'Thay đổi tất cả',
    'CHANGE_LOCATION_STATUS': 'Thay đổi trạng thái vị trí',
    'SELECT_LOCATION_STATUS': 'Chọn Trạng thái Vị trí',
    'SELECT_FULFILLMENT': 'Chọn Fulfillment',
    'SELECT_WAREHOUSE': 'Chọn nhà kho',
    'SELECT_LASTMILE': 'Chọn Lastmile',
    'APPLY_CHANGE': 'Áp dụng thay đổi',
    'CANCEL': 'Hủy bỏ',
    'UPDATE': 'Cập nhật',
    'DISTRICT': 'Huyện',
    'DISTRICTID': 'ID Huyện',
    'SUB_DISTRICT': 'Xã',
    'SUB_DISTRICTID': 'ID Xã',
    'ZIPCODE': 'Mã Bưu Chính',
    'NEIGHBORHOOD': 'Khu vực lân cận',
    'NEIGHBORHOOD_ID': 'ID Khu vực lân cận',
};
export default logistic;