const navEN = {
    'CCMS': {
        'TITLE' : 'Quản lý cuộc gọi',
        'OUTBOUND_DASH' : 'Tổng quan cuộc gọi đi',
        'INBOUND_DASH' : 'Tổng quan cuộc gọi đến',
        'GROUP_AGENTS' : 'Nhóm tư vấn viên',
        'AGENTS_MONITOR' : 'Giám sát tư vấn viên',
        'AGENT_SUMMARIES' : 'Tóm tắt cuộc gọi',
        'INBOUND_SUMMARIES' : 'Tóm tắt cuộc gọi đến',
    },
    'LOGISTIC': {
        'TITLE' : 'Quản lý kho vận',
        'LOGISTIC_MGMT' : 'Tổng quan kho vận',
        'DO' : 'Kiểm tra chéo các đơn hàng',
        'STATUS': 'Trạng thái',
        'LOCATION': 'Vị trí',
        'RESCUE': 'Cứu đơn',
        'SLA': 'SLA',
    },
    'USER': {
        'TITLE' : 'Quản lý người dùng',
        'USERS' : 'Người dùng',
        'GROUP' : 'Nhóm',
        'TEAM' : 'Đội',
    },
    'CAMPAIGN': {
        'TITLE' : 'Quản lý chiến lượt bán hàng',
        'CP_LIST' : 'Danh sách chiến lượt bán hàng',
        'CALLING_LIST' : 'Các mã bán hàng',
        'CP_CONFIGURATION' : 'Cấu hình chiến lượt bán hàng',
        'AG_CP' : 'Tư vấn viên chiến lượt bán hàng',
        'GEO_BALANCING' : 'Cân bằng tải địa lý',
    },
    'ACTION_LOG' : {
        'TITLE' : 'Bảng ghi hoạt động'
    },
    'LOGIN' : 'Đăng nhập',
    'LOGOUT' : 'Đăng xuất',
    'REGISTER' : 'Đăng ký',
    'CONFIRM' : 'Xác nhận',
    'FORGET_PASS' : 'Quên mật khẩu',
    'NAVI' : 'Điều hướng'
}

export default navEN;