import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';
import * as fileSaver from 'file-saver';

import { fetchJSON } from '../../../helpers/api';

import * as types from './constants';

import apiConfig from '../../../config/routesApi';

import * as Actions from './actions';

var i = 0, j = 0, z = 0, y = 0, c = 0, a = 0;
const delay = (ms) => new Promise(res => setTimeout(res, ms));
const cookie = new Cookies();
const user_token = cookie.get("user_access");

var options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '') }
    //+ (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '')
};

function* dataUserManagement() {
    options.method = 'GET';
    options.body = null;
    try {
        if (i === 0) {
            let list = [];
            i++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.getUserManagement, options);
            if (response.header.code === 200) {
                response.items.forEach(element => {
                    element.id = element.userId;
                    element.label = element.fullName + ' (' + element.username + ')';
                    list.push(element);
                });
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataUserManagement(list));
            yield delay(500);
            i = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        yield put(Actions.getDataUserManagement(["null"]));
        yield delay(5000);
        yield put(Actions.resetLoading());
    }
}

function* dataUserTypes() {
    options.method = 'GET';
    options.body = null;
    try {
        if (j === 0) {
            let list = [];
            j++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.getUserType, options);
            if (response.header.code === 200) {
                response.items.forEach(element => {
                    element.label = element.name;
                    list.push(element);
                });
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataUserTypes(list));
            yield delay(500);
            j = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        yield put(Actions.getDataUserTypes(["null"]));
        yield delay(5000);
        yield put(Actions.resetLoading());
    }
}

function* dataUserGroups() {
    options.method = 'GET';
    options.body = null;
    try {
        if (y === 0) {
            let list = [];
            y++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.getUserGroup, options);
            if (response.header.code === 200) {
                response.items.forEach(element => {
                    element.label = element.name;
                    element.value = element.groupId;
                    list.push(element);
                });
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataUserGroups(list));
            yield delay(500);
            y = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        yield put(Actions.getDataUserGroups(["null"]));
        yield delay(5000);
        yield put(Actions.resetLoading());
    }
}

function* dataUserTeams() {
    options.method = 'GET';
    options.body = null;
    try {
        if (z === 0) {
            let list = [];
            z++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.getUserTeam, options);
            if (response.header.code === 200) {
                response.items.forEach(element => {
                    element.label = element.name;
                    element.value = element.id;
                    list.push(element);
                });
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataUserTeams(list));
            yield delay(500);
            z = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        yield put(Actions.getDataUserTeams(["null"]));
        yield delay(5000);
        yield put(Actions.resetLoading());
    }
}

function* createNewUser({ formData: data }) {
    options.body = JSON.stringify(data);
    options.method = 'POST';
    if (c === 0) {
        c++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.postNewUser, options);

        if (response.header.code === 200 || response.header.code === 201) {
            yield put(Actions.closeNewAgentDialog());
            yield put(Actions.submitSucceed(response.message));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        c = 0;
    }

}

function* editUser({ formData: data }) {
    options.body = JSON.stringify(data);
    options.method = 'PUT';
    if (c === 0) {
        c++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.putEditUser + data.userId, options);

        if (response.header.code === 200 || response.header.code === 201) {
            yield put(Actions.closeEditAgentDialog());
            yield put(Actions.submitSucceed(response.message));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        c = 0;
    }

}

function* filterUser({ formData: data }) {
    options.method = 'POST';
    options.body = JSON.stringify(data);
    try {
        if (a === 0) {
            let list = [];
            a++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.filterUser, options);
            if (response.header.code === 200) {
                list = response.items;
            }
            else {
                list = ["null"];
            }
            yield put(Actions.loadUserByFilter(list));
            yield delay(500);
            a = 0;
        }
    }
    catch (error) {
        yield put(Actions.loadUserByFilter(["null"]));
    }
}

function* exportData({ dataFilter: data }) {
    options.method = 'POST';
    options.body = JSON.stringify(data);
    options.headers.responseType = 'arraybuffer';
    try {
        if (c === 0) {
            c++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.postExportUser, options);
            yield put(Actions.submitSucceed(''));
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExt = '.xlsx';
            const file = new Blob([response.data], { type: fileType });
            fileSaver.saveAs(file, 'users' + fileExt);
            yield delay(500);
            c = 0;
        }
    }
    catch (error) {
        console.log(error);
    }

}

export function* watchUserManagement() {
    yield takeEvery(types.GET_DATA_USER_MANAGEMENT, dataUserManagement);
}

export function* watchUserTypes() {
    yield takeEvery(types.GET_DATA_USER_TYPES, dataUserTypes);
}

export function* watchUserGroups() {
    yield takeEvery(types.GET_DATA_USER_GROUPS, dataUserGroups);
}

export function* watchUserTeams() {
    yield takeEvery(types.GET_DATA_USER_TEAMS, dataUserTeams);
}

export function* watchCreateUser() {
    yield takeEvery(types.CREATE_NEW_USER, createNewUser);
}

export function* watchEditUser() {
    yield takeEvery(types.EDIT_USER, editUser);
}

export function* watchFilterUser() {
    yield takeEvery(types.FILTER_USER, filterUser);
}

export function* watchExportData() {
    yield takeEvery(types.EXPORT_DATA, exportData);
}

function* userManagementSaga() {
    yield all([
        fork(watchUserManagement),
        fork(watchUserTypes),
        fork(watchUserGroups),
        fork(watchUserTeams),
        fork(watchCreateUser),
        fork(watchEditUser),
        fork(watchFilterUser),
        fork(watchExportData),
    ]);
}

export default userManagementSaga;
