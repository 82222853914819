// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    const decoded = jwtDecode(user.access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        console.warn('access token expired');
        return false;
    } else {
        return true;
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    var user = cookies.get('user_info');
    var token = cookies.get('user_access');
    var access_token = token ? (typeof token == 'object' ? token : JSON.parse(token)) : null;
    if(user)
    {
        user.access_token = access_token ? access_token.access_token : null;
    }
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const getOptionsApi = () => {
    const cookie = new Cookies();
    const user_token = cookie.get("user_access");
    let options = {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '') }
    };

    return options;
}

export { isUserAuthenticated, getLoggedInUser, getOptionsApi };
