import * as types from './constants';

export const getDataUserManagement = (data) => {
    return ({
        type: types.GET_DATA_USER_MANAGEMENT,
        payload: data,
        loading: data?.length <= 0 ? true : false
    })
}

export const getDataUserTypes = (data) => {
    return ({
        type: types.GET_DATA_USER_TYPES,
        payload: data,
    })
}

export const getDataUserGroups = (data) => {
    return ({
        type: types.GET_DATA_USER_GROUPS,
        payload: data,
    })
}

export const getDataUserTeams = (data) => {
    return ({
        type: types.GET_DATA_USER_TEAMS,
        payload: data,
    })
}

export const openNewAgentDialog = () =>
{
    return {
        type: types.OPEN_NEW_AGENT_DIALOG
    }
}

export function closeNewAgentDialog()
{
    return {
        type: types.CLOSE_NEW_AGENT_DIALOG
    }
}

export function createNewUser(data) {
    return {
        type: types.CREATE_NEW_USER,
        formData: data
    }
}

export function editUser(data) {
    return {
        type: types.EDIT_USER,
        formData: data
    }
}

export function filterUser(data) {
    return {
        type: types.FILTER_USER,
        formData: data
    }
}

export function loadUserByFilter(data) {
    return {
        type: types.LOAD_USER_BY_FILTER,
        payload: data,        
    }
}

export const submitSucceed = (message) => ({
    type: types.SUBMIT_SUCCEED,
    payload: message
});

export const submitFailed = (message) => ({
    type: types.SUBMIT_FAILED,
    payload: message
});

export const resetMessage = () => ({
    type: types.RESET_MESSAGE
})

export const resetLoading = () => ({
    type: types.RESET_LOADING
})

export function openEditAgentDialog(data)
{
    return {
        type: types.OPEN_EDIT_AGENT_DIALOG,
        data
    }
}

export function closeEditAgentDialog()
{
    return {
        type: types.CLOSE_EDIT_AGENT_DIALOG
    }
}

export function exportData(data)
{
    return {
        type: types.EXPORT_DATA,
        dataFilter: data,
    }
}