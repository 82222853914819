import * as types from './constants';
import moment from 'moment/moment';

const initialState = {
    loading: false,
    dataCampaigns: [],
    dataCampaignConfiguration: [],
    dataCampaignOverview: [],
    dataFiltered: [],
    showMessage: null,
    error: null,
    campaignDialog: {
        props: {
            open: false
        },
        data: null
    },
    defaultFormState : {
        'campaignName' : '',
        'startDate': moment().format("YYYY-MM-DD HH:mm:ss"),
        'endDate': moment().format("YYYY-MM-DD HH:mm:ss"),
        'campaignType': '',
        'campaignTag': '',
        'campaignCate': '',
    },
    dataStep2: [],
    dataStep3: [],
    dataStep4: [],
    dataStep5: [],
    dataStep: {},
    dataCampaignTags: [],
    dataCampaignCategories: [],
    dataCampaignTypes: [],
    dataCallingListCampaign: [],
    dataCallStrategies: [],
    dataDistributionRule: [],
    dataAgentGroups: [],
    dataCampaignsMngt: [],
    dataCallingListAll: [],
    dataCampaignsStatus: [],
    dataCampaignsCategory: [],
    dataCampaignsSubCategory: [],
    dataCampaignsTag: [],
    campaignMngtDialog     : {
        type: 'delete',
        props: {
            open: false
        },
        data : null
    },
}

const CampaignsManagement = (state = initialState, action) => {

    switch (action.type) {
        case types.FIND_CAMPAIGNS: {
            return { ...state, dataCampaigns: action.payload, loading: action.loading }
        }
        case types.GET_CAMPAIGN_TAGS: {
            return { ...state, dataCampaignTags: action.payload, loading: action.loading }
        }
        case types.GET_CAMPAIGN_CATEGORIES: {
            return { ...state, dataCampaignCategories: action.payload, loading: action.loading }
        }
        case types.GET_CAMPAIGN_TYPES: {
            return { ...state, dataCampaignTypes: action.payload, loading: action.loading }
        }
        case types.GET_CALLING_LIST_CAMPAIGN: {
            return { ...state, dataCallingListCampaign: action.payload, loading: action.loading }
        }
        case types.GET_CALL_STRATEGIES: {
            return { ...state, dataCallStrategies: action.payload, loading: action.loading }
        }
        case types.GET_DISTRIBUTION_RULE: {
            return { ...state, dataDistributionRule: action.payload, loading: action.loading }
        }
        case types.GET_AGENT_GROUPS: {
            return { ...state, dataAgentGroups: action.payload, loading: action.loading }
        }
        case types.FILTER_CAMPAIGN_MGMT:
            return { ...state, loading: true }
        case types.LOAD_CAMPAIGN_BY_FILTER:
            return { ...state, loading: false, dataCampaignsMngt: action.payload }
        case types.FIND_CAMPAIGN_CONFIGURATION: {
            return { ...state, dataCampaignConfiguration: action.payload, dataFiltered: action.payload, loading: action.loading }
        }

        case types.FIND_CAMPAIGN_ORVERVIEW: {
            return { ...state, dataCampaignOverview: action.payload, loading: action.loading }
        }

        case types.OPEN_CAMPAIGN_CONFIG_DIALOG:
            {
                return {
                    ...state,
                    campaignDialog: {
                        props: {
                            open: true
                        },
                        data: action.payload
                    }
                };
            }
        case types.CLOSE_CAMPAIGN_CONFIG_DIALOG:
            {
                return {
                    ...state,
                    campaignDialog: {
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }

        case types.GET_CAMPAIGN:
        {
            return {...state, dataCampaignsMngt: action.payload, dataFiltered: action.payload, loading: action.loading }
        }
        case types.GET_CALLING_LIST_ALL:
        {
            return {...state, dataCallingListAll: action.payload, loading: action.loading }
        }
        case types.GET_CAMPAIGN_STATUS:
        {
            return {...state, dataCampaignsStatus: action.payload, loading: action.loading }
        }
        case types.GET_CAMPAIGN_CATEGORY:
        {
            return {...state, dataCampaignsCategory: action.payload, loading: action.loading }
        }
        case types.GET_CAMPAIGN_SUBCATEGORY:
        {
            return {...state, dataCampaignsSubCategory: action.payload, loading: action.loading }
        }
        case types.GET_CAMPAIGN_TAG:
        {
            return {...state, dataCampaignsTag: action.payload, loading: action.loading }
        }
        case types.CREATE_CALLING_LIST:
            return { ...state, loading: true }    

        case types.OPEN_CAMPAIGN_NEW_DIALOG:
        {
            return {
                ...state,
                campaignMngtDialog: {
                    type : 'New',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case types.CLOSE_CAMPAIGN_NEW_DIALOG:
        {
            return {
                ...state,
                campaignMngtDialog: {
                    type : 'New',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case types.OPEN_CAMPAIGN_EDIT_DIALOG:
        {
            return {
                ...state,
                campaignMngtDialog: {
                    type : 'Edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case types.CLOSE_CAMPAIGN_EDIT_DIALOG:
            {
                return {
                    ...state,
                    campaignMngtDialog: {
                        type : 'Edit',
                        props: {
                            open: false
                        },
                        data : null
                    }
                };
        }
        case types.OPEN_START_CAMPAIGN_DIALOG:
        {
            return {
                ...state,
                campaignMngtDialog: {
                    type : 'start',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case types.OPEN_STOP_CAMPAIGN_DIALOG:
        {
            return {
                ...state,
                campaignMngtDialog: {
                    type : 'stop',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case types.OPEN_DELETE_CAMPAIGN_DIALOG:
        {
            return {
                ...state,
                campaignMngtDialog: {
                    type : 'delete',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case types.CLOSE_START_CAMPAIGN_DIALOG:
        {
            return {
                ...state,
                campaignMngtDialog: {
                    type : 'start',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case types.CLOSE_STOP_CAMPAIGN_DIALOG:
        {
            return {
                ...state,
                campaignMngtDialog: {
                    type : 'stop',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case types.CLOSE_DELETE_CAMPAIGN_DIALOG:
        {
            return {
                ...state,
                campaignMngtDialog: {
                    type : 'delete',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case types.UPDATE_CAMPAIGN_CONFIG:
            return { ...state, loading: true }
        case types.UPDATE_CALLING_LIST:
            return { ...state, loading: true }
        case types.UPDATE_CAMPAIGN:
            return { ...state, loading: true }
        case types.UPDATE_CAMPAIGN_STATUS:
            return { ...state, loading: true }
        case types.DELETE_CALLING_LIST:
            return { ...state, loading: true }

        case types.SUBMIT_SUCCEED:
            return { ...state, showMessage: action.payload, error: null, loading: true }
        case types.SUBMIT_FAILED:
            return { ...state, showMessage: null, error: action.payload, loading: false }
        case types.RESET_MESSAGE:
            return { ...state, showMessage: null, error: null }
        case types.FILTER_MULTIPLE_CAMPAIGNS:
            return { ...state, dataCampaignConfiguration: action.payload }
        case types.RESET_CAMPAIGN_CONFIGURATION: {
            return { ...state, dataCampaignConfiguration: action.payload, dataFiltered: action.payload }
        }
        case types.CHANGE_FORM_DATA:
            return {
                ...state,
                defaultFormState: action.data
            }
        case types.SAVE_DATA_STEP2:
            return {
                ...state,
                dataStep2: action.data
            }
        case types.SAVE_DATA_STEP3:
            return {
                ...state,
                dataStep3: action.data
            }
        case types.SAVE_DATA_STEP4:
            return {
                ...state,
                dataStep4: action.data
            }
        case types.SAVE_DATA_STEP5:
            return {
                ...state,
                dataStep5: action.data
            }
        case types.SAVE_DATA_STEP:
            return {
                ...state,
                dataStep: action.data
            }
        case types.RESET_LOADING:
            return {
                ...state,
                loading: false
            }
        default:
            return { ...state };
    }
}

export default CampaignsManagement;