const agentGroup = {
    'TITLE': 'Nhóm tư vấn viên',
    'GROUP': 'Các Nhóm',
    'SEARCH': 'Tìm kiếm....',
    'REFRESH': 'Làm mới',
    'CLEAR_SEARCH': 'Xóa tìm kiếm',
    'TRACK': 'Theo dõi thời gian thực',
    'NAME': 'TÊN',
    'SHORT_NAME': 'TÊN NGẮN',
};
export default agentGroup;