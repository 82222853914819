import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';

import { fetchJSON } from '../../helpers/api';

import * as types from './constants';

import apiConfig from '../../config/routesApi';

import * as Actions from './actions';

var i = 0, a = 0;
const delay = (ms) => new Promise(res => setTimeout(res, ms));
const cookie = new Cookies();
const user_token = cookie.get("user_access");

var options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '') }
    //+ (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '')
};

const pageSize = {
    pageSize: 400,
}

function* dataActionLog() {
    options.method = 'POST';
    options.body = JSON.stringify(pageSize);
    try {
        if (i === 0) {
            let list = [];
            i++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.authen + apiConfig.apiEventUsers.postActionLog, options);
            if (response.code === 200) {
                const data = response.data.items;
                if(data){
                    data.forEach((log, index) => {
                        log.keyIndex = index;
                        list.push(log);
                    })
                }
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataActionLog(list));
            yield delay(500);
            i = 0;
        }
    }
    catch (error) {
        yield put(Actions.getDataActionLog(["null"]));
    }
}


function* filterActionLog({ formData: data }) {
    options.method = 'POST';
    options.body = JSON.stringify(data);
    try {
        if (a === 0) {
            let list = [];
            a++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.authen + apiConfig.apiEventUsers.postActionLog, options);
            if (response.code === 200) {
                list = response.data.items;
            }
            else {
                list = ["null"];
            }
            yield put(Actions.loadActionByFilter(list));
            yield delay(500);
            a = 0;
        }
    }
    catch (error) {
        yield put(Actions.loadActionByFilter(["null"]));
    }
}

export function* watchDataActionLog() {
    yield takeEvery(types.GET_DATA_ACTION_LOG, dataActionLog);
}

export function* watchFilterActionLog() {
    yield takeEvery(types.FILTER_ACTION_LOG, filterActionLog);
}

function* ActionLogSaga() {
    yield all([
        fork(watchDataActionLog),
        fork(watchFilterActionLog),
    ]);
}


export default ActionLogSaga;
