export const FIND_CAMPAIGN_CONFIGURATION = 'FIND_CAMPAIGN_CONFIGURATION';
export const FIND_CAMPAIGNS = 'FIND_CAMPAIGNS';
export const FIND_CAMPAIGN_ORVERVIEW = 'FIND_CAMPAIGN_ORVERVIEW';
export const OPEN_CAMPAIGN_CONFIG_DIALOG = 'OPEN_CAMPAIGN_CONFIG_DIALOG';
export const CLOSE_CAMPAIGN_CONFIG_DIALOG = 'CLOSE_CAMPAIGN_CONFIG_DIALOG';
export const UPDATE_CAMPAIGN_CONFIG = 'UPDATE_CAMPAIGN_CONFIG';
export const SUBMIT_SUCCEED = 'SUBMIT_SUCCEED';
export const SUBMIT_FAILED = 'SUBMIT_FAILED';
export const RESET_MESSAGE = 'RESET_MESSAGE';
export const FILTER_MULTIPLE_CAMPAIGNS = 'FILTER_MULTIPLE_CAMPAIGNS';
export const RESET_CAMPAIGN_CONFIGURATION = 'RESET_CAMPAIGN_CONFIGURATION';
export const CHANGE_FORM_DATA = 'CHANGE_FORM_DATA';
export const SAVE_DATA_STEP2 = 'SAVE_DATA_STEP2';
export const SAVE_DATA_STEP3 = 'SAVE_DATA_STEP3';
export const SAVE_DATA_STEP4 = 'SAVE_DATA_STEP4';
export const SAVE_DATA_STEP5 = 'SAVE_DATA_STEP5';
export const SAVE_DATA_STEP = 'SAVE_DATA_STEP';
export const GET_CAMPAIGN_TAGS = 'GET_CAMPAIGN_TAGS';
export const GET_CAMPAIGN_CATEGORIES = 'GET_CAMPAIGN_CATEGORIES';
export const GET_CAMPAIGN_TYPES = 'GET_CAMPAIGN_TYPES';
export const GET_CALLING_LIST_CAMPAIGN = 'GET_CALLING_LIST_CAMPAIGN';
export const GET_CALL_STRATEGIES = 'GET_CALL_STRATEGIES';
export const GET_DISTRIBUTION_RULE = 'GET_DISTRIBUTION_RULE';
export const GET_AGENT_GROUPS = 'GET_AGENT_GROUPS';
export const POST_CAMPAIGNS = 'POST_CAMPAIGNS';

export const OPEN_CAMPAIGN_NEW_DIALOG = 'OPEN_CAMPAIGN_NEW_DIALOG';
export const CLOSE_CAMPAIGN_NEW_DIALOG = 'CLOSE_CAMPAIGN_NEW_DIALOG';
export const OPEN_CAMPAIGN_EDIT_DIALOG = 'OPEN_CAMPAIGN_EDIT_DIALOG';
export const CLOSE_CAMPAIGN_EDIT_DIALOG = 'CLOSE_CAMPAIGN_EDIT_DIALOG';
export const OPEN_START_CAMPAIGN_DIALOG = 'OPEN_START_CAMPAIGN_DIALOG';
export const CLOSE_START_CAMPAIGN_DIALOG = 'CLOSE_START_CAMPAIGN_DIALOG';
export const OPEN_STOP_CAMPAIGN_DIALOG = 'OPEN_STOP_CAMPAIGN_DIALOG';
export const CLOSE_STOP_CAMPAIGN_DIALOG = 'CLOSE_STOP_CAMPAIGN_DIALOG';
export const OPEN_DELETE_CAMPAIGN_DIALOG = 'OPEN_DELETE_CAMPAIGN_DIALOG';
export const CLOSE_DELETE_CAMPAIGN_DIALOG = 'CLOSE_DELETE_CAMPAIGN_DIALOG';

export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const GET_CALLING_LIST_ALL = 'GET_CALLING_LIST_ALL';
export const GET_CAMPAIGN_STATUS = 'GET_CAMPAIGN_STATUS';
export const GET_CAMPAIGN_CATEGORY = 'GET_CAMPAIGN_CATEGORY';
export const GET_CAMPAIGN_SUBCATEGORY = 'GET_CAMPAIGN_SUBCATEGORY';
export const GET_CAMPAIGN_TAG = 'GET_CAMPAIGN_TAG';
export const CREATE_CALLING_LIST = 'CREATE_CALLING_LIST';
export const UPDATE_CALLING_LIST = 'UPDATE_CALLING_LIST';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN_STATUS = 'UPDATE_CAMPAIGN_STATUS';
export const DELETE_CALLING_LIST = 'DELETE_CALLING_LIST';

export const FILTER_CAMPAIGN_MGMT = 'FILTER_CAMPAIGN_MGMT';
export const LOAD_CAMPAIGN_BY_FILTER = 'LOAD_CAMPAIGN_BY_FILTER';
export const RESET_LOADING = 'RESET_LOADING';
