export const GET_DATA_USER_MANAGEMENT = 'GET_DATA_USER_MANAGEMENT';
export const GET_DATA_USER_TYPES = 'GET_DATA_USER_TYPES';
export const GET_DATA_USER_GROUPS = 'GET_DATA_USER_GROUPS';
export const GET_DATA_USER_TEAMS = 'GET_DATA_USER_TEAMS';
export const OPEN_NEW_AGENT_DIALOG = 'OPEN_NEW_AGENT_DIALOG';
export const CLOSE_NEW_AGENT_DIALOG = 'CLOSE_NEW_AGENT_DIALOG';
export const OPEN_EDIT_AGENT_DIALOG = 'OPEN_EDIT_AGENT_DIALOG';
export const CLOSE_EDIT_AGENT_DIALOG = 'CLOSE_EDIT_AGENT_DIALOG';
export const FILTER_USER = 'FILTER_USER';
export const LOAD_USER_BY_FILTER = 'LOAD_USER_BY_FILTER';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const EDIT_USER = 'EDIT_USER';
export const SUBMIT_SUCCEED = 'SUBMIT_SUCCEED';
export const SUBMIT_FAILED = 'SUBMIT_FAILED';
export const RESET_MESSAGE = 'RESET_MESSAGE';
export const EXPORT_DATA = 'EXPORT_DATA';
export const RESET_LOADING = 'RESET_LOADING';