const outboudDashboard = {
    'OUTBOUND_DASHBOARD': 'Outbound Dashboard',
    'TOTAL_AG_STATUS': 'Total Agent Status',
    'TODAY': 'Today',
    'HIDE': 'Hide',
    'SHOW': 'Show Chart',
    'AG_STATUS': 'Agent Status',
    'MEMBER': 'Member',
    'USERNAME': 'Username',
    'GROUP': 'Group',
    'TEAM': 'Team',
    'STATUS': 'Status',
    'REASON': 'Reason',
    'TIME': 'Time in state',
};
export default outboudDashboard;