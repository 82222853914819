const callingList ={
    'TITLE': 'Calling List',
    'SEARCH': 'Search calling list',
    'NEW_CALLING': 'New Calling List',
    'NAME': 'Name',
    'PRODUCT_ID': 'Product ID',
    'TYPE': 'Type',
    'DESC': 'Description',
    'CREATE_DATE': 'Created Date',
    'NO_DATA': 'No Data to Display',
    'ADD_CALLING': 'Add Calling List',
    'EDIT_CALLING': 'Edit Calling List',
    'CODE': 'Code',
    'PRIORITY': 'Priority',
    'ADD': 'Add',
    'SAVE': 'Save',
    'ERROR': 'Error, Please fill in the information !',
}

export default callingList;