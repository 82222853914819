const navEN = {
    'CCMS': {
        'TITLE' : 'Contact Center',
        'OUTBOUND_DASH' : 'Outbound Dashboard',
        'INBOUND_DASH' : 'Inbound Dashboard',
        'GROUP_AGENTS' : 'Group Agents',
        'AGENTS_MONITOR' : 'Agents Monitor',
        'AGENT_SUMMARIES' : 'Agent Summaries',
        'INBOUND_SUMMARIES' : 'Inbound Summaries',
    },
    'LOGISTIC': {
        'TITLE' : 'Logistic',
        'LOGISTIC_MGMT' : 'Delivery Configuration',
        'DO' : 'DO Crosscheck',
        'STATUS': 'Status Mapping',
        'LOCATION': 'Location Mapping',
        'RESCUE': 'Rescue Mapping',
        'SLA': 'SLA Configuration'
    },
    'USER': {
        'TITLE' : 'User',
        'USERS' : 'Users',
        'GROUP' : 'Group',
        'TEAM' : 'Team',
    },
    'CAMPAIGN': {
        'TITLE' : 'Campaign',
        'CP_LIST' : 'Campaign List',
        'CALLING_LIST' : 'Calling List',
        'CP_CONFIGURATION' : 'Campaign Configuration',
        'AG_CP' : 'Agents in Campaign',
        'GEO_BALANCING' : 'Geo Load Balancing',
    },
    'ACTION_LOG' : {
        'TITLE' : 'Action Log'
    },
    'LOGIN' : 'Login',
    'LOGOUT' : 'Logout',
    'REGISTER' : 'Register',
    'CONFIRM' : 'Confirm',
    'FORGET_PASS' : 'Forget Password',
    'NAVI' : 'Navigation'
}

export default navEN;