const agentCampaign = {
    'TITLE': 'Agents in Campaign',
    'ALL_CP': 'All Campaigns',
    'NO_DATA': 'No Data to Display',
    'INCLUDING': 'Including:',
    'AGENT': 'Agent Group',
    'MEMBER': 'Members',
    'TEAM_LEAD': 'Team Lead',
    'DETAIL': 'Detail',
    'NUMBER_OFF': 'Number of user',
    'SEARCH': 'Search Groups...',
}

export default agentCampaign;