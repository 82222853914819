const config = {
    apiGateway: {
        mainLogistic: process.env.REACT_APP_API_LOGISTIC,
        mainUsers: process.env.REACT_APP_API_USERS,
        authen: process.env.REACT_APP_API_AUTHEN,
        mainCampaigns: process.env.REACT_APP_API_CAMPAIGNS,
        mainDistribute: process.env.REACT_APP_API_DISTRIBUTE,
        mornitorCMMs: process.env.REACT_APP_API_CMMS_MONITOR,
        mainCMMs: process.env.REACT_APP_API_CMMS_MAIN,
    },
};

export default {
    ...config,
    prefixUrlLogistic: {
        exportLocationErrorsReason: '/api/v1/logistic/excel/excel-file-error?fileName=',
        shortenUrlLogistic: '/api/v1/logistic'
    },
    apiEventLogistic: {
        getCountryList: '/countries',
        getProvinceList: '/provinces',
        getDistrictList: '/districts',
        getSubdistrictList: '/sub-districts',
        getNeighborhood: '/neighborhoods',
        getDeliveryPackages: '/delivery-packages',
        getProviderList: '/providers/mapping',
        getLocationStatus: '/cfSynonyms/32',
        postAuthentication: '/oauth/token',
        getCurrentUser: '/users/current',
        deleteScheduleChange: '/schedules',
        exportDefaultDeliveries: '/excel/export/default-deliveries',
        importProviders: '/excel/import/provider',
        importLocationStatus: '/excel/import/location-status',
        exportTemplateLocationProvider: '/excel/template/import-provider-file',
        exportTemplateLocationStatus: '/excel/template/import-location-status-file',
        exportDOCrosscheck: '/do-crosschecks/export',
        doCrosscheck: '/do-crosschecks/page',
        doTotal: '/do-crosschecks/history',
        getStatusMapping: '/status-mappings/page?partnerId=',
        getLocationMapping: '/location-mappings/page?',
        getRescueMapping: '/rescue-mappings/page?',
        getTmsCodeMapping: '/status-mappings/list-status',
        getFulfillmentLastmile: '/partners/fulfillment-and-lastmile',
        crudEditStatusMapping: '/status-mappings',
        crudEditLocationMapping: '/location-mappings',
        crudEditRecueMapping: '/rescue-mappings',
        importStatusMapping: '/excel/import/status-mapping',
        exportStatusMapping: '/excel/export/status-mapping?partnerId=',
        importLocationMapping: '/excel/import/location-mapping',
        exportLocationMapping: '/excel/export/location-mapping?partnerId=',
        importRescueMapping: '/excel/import/rescue-mapping',
        exportRescueMapping: '/excel/export/rescue-mapping',
        templateStatusMapping: '/excel/export/template?name=status&extension=xlsx',
        templateLocationMapping: '/excel/export/template?name=location&extension=xlsx',
        templateRescueMapping: '/excel/export/template?name=rescue&extension=xlsx',
        rescue_mapping_all_endpoint: '/rescue-mappings/all'
    },
    apiEventUsers: {
        getUserManagement: '/users/1/500',
        getUserGroup: '/groups/1/500',
        getUserTeam: '/teams/1/100',
        getUserType: '/users/types',
        getAgentByGroup: '/groups/assignee/',
        getAllGroupRuleByAgent: '/groups/',
        getAgentByTeam: '/teams/members/',
        postNewUser: '/users/new',
        putEditUser: '/users/update/',
        filterUser: '/users/filter/1/100',
        postMoveGroup: '/users/group/move',
        postRemoveGroup: '/users/group/remove',
        postMoveTeam: '/users/team/move',
        postRemoveTeam: '/users/team/remove',
        postExportUser: '/users/export-excel',
        postExportGroupsMember: '/groups/members',
        postExportTeamsMember: '/teams/members',
        postAddMembersToGroup: '/groups/add-members',
        postRemoveMembersToGroup: '/groups/out-groups',
        postAddMembersToTeam: '/teams/add-members',
        postUploadUsers: '/users/upload-users',
        postActionLog: '/action-logs/page',
        postRenewSkillLevel: '/users/group/renew-skill-level'
    },
    apiEventCampaigns: {
        findCampaignsConfiguration: '/configuration',
        findAll: '/all/1/100',
        findCampaignOverviewByCampaignId: '/overview',
        putUpdateCampaignConfig: '/update',
        getCampaignTags: '/synonyms?typeId=42',
        getCampaignTypes: '/synonyms?typeId=40',
        getCampaignCategories: '/synonyms?typeId=41',
        getCallingListCampaign: '/calling-list/all-free',
        getCallStrategies: '/call-strategies',
        getDistributionRule: '/distribution-rules',
        getAgentGroups: '/groups',
        dataCampaign: '/management/1/100',
        callingListAll: '/calling-list/all/1/30',
        createGroup: '/groups',
        createCallingList: '/calling-list',
        updateCampaignStatus: '/status',
        deleteCallingList: '/calling-list?ids=',
        campaignsStatus: '/synonyms?typeId=3',
        campaignsCategory: '/synonyms?typeId=40',
        campaignsSubCategory: '/synonyms?typeId=41',
        campaignsTag: '/synonyms?typeId=42',
        getGroups: '/synonyms?typeId=2',
        getAgencyDistribute: '/agency/distribution',
        updateDistributeRates: '/agency/distribution/update'
    },
    apiEventCMMs: {
        getAgentMonitorStatus: '/pbx-rest/agent-status',
        getAgentSummary: '/cdr-summaries?active=false&',
        getAgentInbound: '/inbound?enablePaging=true&',
        postJoinCallAction: '/pbx-rest/s-call',
        getAgentGroup: '/groups?pageIndex=1&pageSize=100',
        getDashboard: '/dashboard/agent-status?activityId=53&page=1&size=400',
        getChart: '/dashboard/agent-status-aggregated?activityId=53',
        getAgentFromGroupID: '/groups/agents?pageIndex=1&pageSize=100&groupsId='
    },
    apiEventInbound: {
        getDataAgent: '/inbound/agents',
        getDataTeam: '/inbound/teams',
        getExportDataAgent: '/inbound/export/agents',
        getExportDataTeam: '/inbound/export/teams',
    },
    apiDashboardInbound: {
        getInboundTicket: '/dashboard/inbound/ticket',
        getChartServices: '/dashboard/inbound/chart/services-level',
        getChartAbanCall: '/dashboard/inbound/chart/abandon-rate',
        getInboundTotalCS: '/dashboard/inbound/status',
    },
    apiSLA: {
        // Cut off time
        getConfigCutOffTime: '/api/v1/agreement/vendor/list',
        createCutOffTime: '/api/v1/agreement/vendor',
        editCutOffTime: '/api/v1/agreement/vendor',
        deleteCutOffTime: '/api/v1/agreement/vendor?id=',
        getAllFulfillment: '/api/v1/logistic/partners/fulfillment',
        getAllPartners: 'api/v1/agreement/partners',

        // SLA logistic
        getSLALocation: '/api/v1/agreement/location/list',
        createSLALocation: '/api/v1/agreement/location',
        editSLALocation: '/api/v1/agreement/location',
        deleteConfigSLA: '/api/v1/agreement/location',
        uploadSLALocation: '/api/v1/agreement/location/upload',
        downloadSLALocation: '/api/v1/agreement/location/generation-link',
        getServiceByLastmile:'/api/v1/agreement/services?last_mile_id=',
        getAllProvinces: '/api/v1/address/province',
        getDataDistrictList: '/api/v1/address/district?province_id=',
        getDataSubdistrictList: '/api/v1/address/ward?district_id=',
    }
}