import * as types from './constants';

const initialState = {
    loading: false,
    data: [],
    showMessage: null,
    error: null,
    dataAgentGroup: [],
}

const AgentGroupCMMs = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATA_AGENT_GROUP:
            return { ...state, dataAgentGroup: action.payload, error: null, loading: false }
        case types.SUBMIT_SUCCEED:
            return { ...state, showMessage: action.payload, error: null, loading: false }
        case types.SUBMIT_FAILED:
            return { ...state, showMessage: null, error: action.payload, loading: false }
        case types.RESET_MESSAGE:
            return { ...state, showMessage: null, error: null }
        default:
            return { ...state };
    }
}

export default AgentGroupCMMs;
