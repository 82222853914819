const team ={
    'TITLE': 'Team members',
    'TEAM': 'Team',
    'SEARCH': 'Search',
    'EXPORT': 'Export',
    'ADD_MEMBER': 'Add Members',
    'Full_NAME_ID': 'Fullname & ID',
    'USER': 'Username',
    'NO_DATA': 'No Data to Display',
    'MOVE': 'Move to team',
    'REMOVE': 'Remove',
    'FULL_NAME': 'Full Name',
    'ALERT': 'Are you sure to remove the user from the team?',
    'CANCEL': 'Cancel',
}

export default team;