const group = {
    'TITLE': 'Group members',
    'FULL_NAME_ID': 'Fullname & ID',
    'GROUP': 'Group',
    'AGENT': 'Agents/ CS',
    'TEAM_LEAD': 'Team lead',
    'SEARCH': 'Search',
    'USER': 'Username',
    'SKILL': 'Skill level',
    'EXPORT': 'Export',
    'ADD_MEMBER': 'Add Members',
    'ADD_GROUP': 'Add Group',
    'USER_ID': 'Username & ID',
    'NEW_USER': 'New user',
    'FULL_NAME': 'Full name',
    'REMOVE': 'Remove',
    'MOVE': 'Move to group',
    'NAME': 'Name',
    'SHORT_NAME': 'Shortname',
    'DESC': 'Description',
    'TYPE': 'Type',
    'NONE': 'None',
    'SKILL_ID': 'SkillId',
    'ADD': 'Add',
    'ALERT': 'Are you sure to remove the user from the group?',
    'CANCEL': 'Cancel',
}

export default group;