import * as types from './constants';

const initialState = {
    loading: false,
    dataUserGroup: [],
    dataGroupsType: [],
    dataAllGroupRuleByAgent: [],
    showMessage: null,
    error: null,
    groupDialog: {
        type: 'Delete',
        props: {
            open: false
        },
        data: null
    },
    newGroupDialog: {
        type: 'Delete',
        props: {
            open: false
        },
        data: null
    },
}

const GroupManagement = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATA_AGENT_BY_GROUP:
            return { ...state, dataUserGroup: action.payload, loading: action.loading }
        case types.GET_DATA_GROUPTYPE:
            return { ...state, dataGroupsType: action.payload, loading: action.loading }
        case types.GET_ALL_GR_RULE_BY_AGENT:
            return { ...state, dataAllGroupRuleByAgent: action.payload, loading: action.loading }
        case types.MOVE_USER_GROUP:
            return { ...state, loading: true }
        case types.REMOVE_USER_GROUP:
            return { ...state, loading: true }
        case types.SUBMIT_SUCCEED:
            return { ...state, showMessage: action.payload, error: null, loading: false }
        case types.SUBMIT_FAILED:
            return { ...state, showMessage: null, error: action.payload, loading: false }
        case types.RESET_MESSAGE:
            return { ...state, showMessage: null, error: null }
        case types.OPEN_NEW_AGENT_DIALOG:
            {
                return {
                    ...state,
                    groupDialog: {
                        type: 'New',
                        props: {
                            open: true
                        },
                        data: null
                    }
                };
            }
        case types.CLOSE_NEW_AGENT_DIALOG:
            {
                return {
                    ...state,
                    groupDialog: {
                        type: 'New',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case types.OPEN_NEW_GROUP_DIALOG:
            {
                return {
                    ...state,
                    newGroupDialog: {
                        type: 'NewGroup',
                        props: {
                            open: true
                        },
                        data: null
                    }
                };
            }
        case types.CLOSE_NEW_GROUP_DIALOG:
            {
                return {
                    ...state,
                    newGroupDialog: {
                        type: 'NewGroup',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case types.OPEN_DELETE_AGENT_DIALOG:
            {
                return {
                    ...state,
                    groupDialog: {
                        type: 'Delete',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case types.CLOSE_DELETE_AGENT_DIALOG:
            {
                return {
                    ...state,
                    groupDialog: {
                        type: 'Delete',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case types.UPDATE_USER_SKILL_LEVEL:
            return { ...state, loading: true }
        case types.CREATE_NEW_GROUP:
            return { ...state, loading: true }    
        default:
            return { ...state };
    }
}

export default GroupManagement;