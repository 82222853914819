/* Layout types and other constants */

export const LAYOUT_VERTICAL = 'vertical';
export const LAYOUT_HORIZONTAL = 'topnav';

export const LAYOUT_WIDTH_FLUID = 'fluid';
export const LAYOUT_WIDTH_BOXED = 'boxed';

export const LEFT_SIDEBAR_THEME_DEFAULT = 'default';
export const LEFT_SIDEBAR_THEME_DARK = 'dark';

export const LEFT_SIDEBAR_TYPE_FIXED = 'fixed';
export const LEFT_SIDEBAR_TYPE_CONDENSED = 'condensed';
export const LEFT_SIDEBAR_TYPE_SCROLLABLE = 'scrollable';

export const parentStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: 'border-box',
    display: 'block',
    width: '100%',
};
export const cellStyle = {
    boxSizing: 'border-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

export const numbersHeaderStyle = {
    width: '90px',
    textAlign: 'left'
};

export const textHeaderStyle = {
    width: '160px',
    textAlign: 'left'
};

export const numberColStyle = {
    textAlign: 'left'
};

export const textColStyle = {
    textAlign: 'left'
}