import * as types from './constants';

export const getDataActionLog = (data) => {
    return ({
        type: types.GET_DATA_ACTION_LOG,
        payload: data,
        loading: data?.length <= 0 ? true : false
    })
}

export function filterActionLog(data) {
    return {
        type: types.FILTER_ACTION_LOG,
        formData: data
    }
}

export function loadActionByFilter(data) {
    return {
        type: types.LOAD_ACTION_BY_FILTER,
        payload: data,        
    }
}