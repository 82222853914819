import * as types from './constants';

export const getDataAgentGroup = (data) => ({
    type: types.GET_DATA_AGENT_GROUP,
    payload: data,
    loading: data?.length <= 0 ? true : false
});
export const submitSucceed = (message) => ({
    type: types.SUBMIT_SUCCEED,
    payload: message
});

export const submitFailed = (message) => ({
    type: types.SUBMIT_FAILED,
    payload: message
});

export const resetMessage = () => ({
    type: types.RESET_MESSAGE
});
