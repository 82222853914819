// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import logisticSaga from './logistic/saga';
import userManagementSaga from './users/management/saga';
import userGroupSaga from './users/group/saga';
import userTeamSaga from './users/team/saga';
import ActionLogSaga from './actionlog/saga';
import campaignsManagementSaga from './campaigns/saga';
import agentGroupSaga from './ccms/agentGroup/saga';
import agentMonitorSaga from './ccms/agentMonitor/saga';
import agentSummarySaga from './ccms/agentSummary/saga';
import dashboardCCMsSaga from './ccms/dashboard/saga';

export default function* rootSaga(getState: any): any {
    yield all([
        authSaga(), 
        layoutSaga(), 
        appMenuSaga(), 
        logisticSaga(), 
        userManagementSaga(), 
        userGroupSaga(), 
        userTeamSaga(), 
        ActionLogSaga(), 
        campaignsManagementSaga(),
        agentGroupSaga(),
        agentMonitorSaga(),
        agentSummarySaga(),
        dashboardCCMsSaga()
    ]);
}

