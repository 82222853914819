const doCrosscheck = {
    'TITLE': 'Kiểm tra chéo các đơn hàng',
    'TRACKING_CODE': 'Mã theo dõi',
    'FFM_NAME': 'Tên FFM',
    'CARRIER': 'Vận chuyển',
    'DO_STATUS': 'Trạng thái DO',
    'TRANSPORT_STATUS': 'Tình trạng vận chuyển',
    'TMS_MAPPING': 'Trạng thái TMS',
    'DO_CREATE_DATE': 'Ngày tạo DO',
    'DO_LAST_DATE': 'Ngày cuối DO',
    'LAST_CROSS': 'Kiểm tra chéo cuối cùng',
    'TODAY': 'Hôm nay',
    'WEEK': 'Tuần này',
    'MONTH': 'Tháng này',
    'PERIOD': 'Giai đoạn',
    'SEARCH': 'Tìm kiếm',
    'FAIL': 'Thất bại',
    'TOTAL_DO': 'Tổng DO',
    'EXPORT': 'Xuất',
    'SHOW_FILTER': 'Hiện bộ lọc',
    'CLOSE_FILTER': 'Đóng bộ lọc',
    'CLEAR_FILTER': 'Xóa bộ lọc',
};
export default doCrosscheck;