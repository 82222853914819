import * as types from './constants';

export const getDataAgentByGroup = (data, groupId) => {
    return ({
        type: types.GET_DATA_AGENT_BY_GROUP,
        payload: data,
        loading: data?.length <= 0 ? true : false,
        id: groupId
    })
}

export const getDataGroupType = (data) => {
    return ({
        type: types.GET_DATA_GROUPTYPE,
        payload: data,
    })
}

export const getAllGroupRuleByAgent = (data, agentId) => {
    return ({
        type: types.GET_ALL_GR_RULE_BY_AGENT,
        payload: data,
        id: agentId
    })
}

export function moveUserGroup(data) {
    return {
        type: types.MOVE_USER_GROUP,
        formData: data
    }
}

export function removeUserGroup(data) {
    return {
        type: types.REMOVE_USER_GROUP,
        formData: data
    }
}

export const submitSucceed = (message) => ({
    type: types.SUBMIT_SUCCEED,
    payload: message
});

export const submitFailed = (message) => ({
    type: types.SUBMIT_FAILED,
    payload: message
});

export const resetMessage = () => ({
    type: types.RESET_MESSAGE
});

export function openDeleteAgentDialog(data) {
    return {
        type: types.OPEN_DELETE_AGENT_DIALOG,
        data
    }
}

export function closeDeleteAgentDialog() {
    return {
        type: types.CLOSE_DELETE_AGENT_DIALOG
    }
}

export const openNewAgentDialog = () => {
    return {
        type: types.OPEN_NEW_AGENT_DIALOG
    }
}

export function closeNewAgentDialog() {
    return {
        type: types.CLOSE_NEW_AGENT_DIALOG
    }
}

export const openNewGroupDialog = () => {
    return {
        type: types.OPEN_NEW_GROUP_DIALOG
    }
}

export function closeNewGroupDialog() {
    return {
        type: types.CLOSE_NEW_GROUP_DIALOG
    }
}

export function renewUserSkillLevel(data) {
    return {
        type: types.UPDATE_USER_SKILL_LEVEL,
        formData: data
    }
}

export function createNewGroup(data) {
    return {
        type: types.CREATE_NEW_GROUP,
        formData: data
    }
}