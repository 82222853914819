import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';
import * as types from './constants';
import * as Actions from './actions';
import apiConfig from '../../config/routesApi';
import { fetchJSON } from '../../helpers/api';

var i = 0, j = 0, p = 0, m = 0, n = 0, a = 0, e = 0, r = 0, t = 0, y = 0, u = 0, f = 0, q = 0, k = 0, l = 0, g = 0, s = 0, c = 0, b = 0, cll = 0, ni = 0, na = 0, cpstt = 0, fil = 0;
const delay = (ms) => new Promise(res => setTimeout(res, ms));
const cookie = new Cookies();
const user_token = cookie.get("user_access");

let options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '') }
};
let options2 = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '') }
};

const body = {};

function* dataCampaignConfiguration() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.findCampaignsConfiguration;
    try {
        if (i === 0) {
            i++;
            const response = yield call(fetchJSON, url, options);
            if (response.header.code === 200) {
                if (response.items.length > 0) {
                    response.items.forEach((element, index) => {
                        let paramFields = {
                            keyIndex: index,
                            campaignsName: element.campaigns.name,
                            distributionRulesName: element.distributionRules.shortName,
                            groupsName: '',
                            skillsLevelName: '',
                            callingListName: '',
                            offersName: '',
                            partnersName: '',
                            metaParams: '',
                            callingList: element.callingList,
                            metaParamsOriginal: element.metaParams.listMeta,
                            campaignId: element.campaigns.campaignId,
                            sizeOfMembers: element.campaigns.sizeOfMembers,
                            groups: [],
                            skillsLevel: [],
                            partnership: element.partnership,
                            distributionRules: element.distributionRules
                        };

                        if (element.groups) {
                            element.groups = element.groups ? element.groups : [];
                            element.groups.forEach(elementGroup => {
                                if (elementGroup.name !== null) {
                                    paramFields.groups.push(elementGroup);

                                    if (paramFields.groupsName) {
                                        paramFields.groupsName = paramFields.groupsName + ', ' + elementGroup.templateGroupAssignees;
                                    } else {
                                        paramFields.groupsName = elementGroup.templateGroupAssignees;
                                    }
                                }
                            })
                        }

                        if (element.skillsLevel) {
                            element.skillsLevel = element.skillsLevel ? element.skillsLevel : [];
                            element.skillsLevel.forEach(elementSkill => {
                                if (elementSkill.numberOfSkillLevel !== null) {
                                    paramFields.skillsLevel.push(elementSkill);

                                    if (paramFields.skillsLevelName) {
                                        paramFields.skillsLevelName = paramFields.skillsLevelName + ', ' + elementSkill.templateSkillLevels;
                                    } else {
                                        paramFields.skillsLevelName = elementSkill.templateSkillLevels;
                                    }
                                }
                            })
                        }

                        if (element.callingList) {
                            element.callingList = element.callingList ? element.callingList : [];
                            element.callingList.forEach(elementSkill => {
                                if (elementSkill.callingListId !== null) {
                                    paramFields.callingListName = paramFields.callingListName + elementSkill.templateCalling + ',';
                                }

                                elementSkill.templateOffers = elementSkill.templateOffers ? elementSkill.templateOffers : [];
                                elementSkill.templateOffers.forEach(elementOffer => {
                                    if (elementOffer !== null) {
                                        paramFields.offersName = paramFields.offersName + elementOffer + ',';
                                    }
                                })

                                if (elementSkill.offers) {
                                    elementSkill.offers = elementSkill.offers ? elementSkill.offers : [];
                                    elementSkill.offers.forEach(elementPartner => {
                                        if (elementPartner.partnerName !== null) {
                                            paramFields.partnersName = paramFields.partnersName + elementPartner.templatePartnerName + ',';
                                        }
                                    })
                                }

                            })
                        }

                        if (element.metaParams) {
                            if (element.metaParams.listMeta) {
                                element.metaParams.listMeta = element.metaParams.listMeta ? element.metaParams.listMeta : [];

                                element.metaParams.listMeta.forEach(elementMeta => {
                                    if (elementMeta.paramId !== null) {
                                        if (paramFields.metaParams) {
                                            paramFields.metaParams = paramFields.metaParams + ', ' + elementMeta.templateOfParams;
                                        } else {
                                            paramFields.metaParams = elementMeta.templateOfParams;
                                        }

                                    }

                                })
                            }
                        }


                        list.push(paramFields);
                    });
                }

            }

            yield put(Actions.findCampaignsConfiguration(list));
            yield delay(500);
            i = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    } catch {
        yield put(Actions.findCampaignsConfiguration(['null']));
    }
}

function* dataCampaigns() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.findAll;
    try {
        if (j === 0) {
            j++;
            const response = yield call(fetchJSON, url, options);
            if (response.header.code === 200) {
                if (response.items.length > 0) {
                    response.items.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }

            yield put(Actions.findCampaigns(list));
            yield delay(500);
            j = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }


    } catch {
        yield put(Actions.findCampaigns(['null']));
    }
}

function* dataCampaignTags() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.getCampaignTags;
    try {
        if (q === 0) {
            q++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }

            yield put(Actions.getCampaignTags(list));
            yield delay(500);
            q = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }


    } catch {
        yield put(Actions.getCampaignTags(['null']));
    }
}

function* dataCampaignCategories() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.getCampaignCategories;
    try {
        if (k === 0) {
            k++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }

            yield put(Actions.getCampaignCategories(list));
            yield delay(500);
            k = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }


    } catch {
        yield put(Actions.getCampaignCategories(['null']));
    }
}

function* dataCampaignTypes() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.getCampaignTypes;
    try {
        if (y === 0) {
            y++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }

            yield put(Actions.getCampaignTypes(list));
            yield delay(500);
            y = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }


    } catch {
        yield put(Actions.getCampaignTypes(['null']));
    }
}

function* dataCallingListCampaign() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.getCallingListCampaign;
    try {
        if (n === 0) {
            n++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }

            yield put(Actions.getCallingListCampaign(list));
            yield delay(500);
            n = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }


    } catch {
        yield put(Actions.getCallingListCampaign(['null']));
    }
}

function* dataCallStrategies() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.getCallStrategies;
    try {
        if (g === 0) {
            g++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }

            yield put(Actions.getCallStrategies(list));
            yield delay(500);
            g = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }


    } catch {
        yield put(Actions.getCallStrategies(['null']));
    }
}

function* dataDistributionRule() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.getDistributionRule;
    try {
        if (l === 0) {
            l++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }

            yield put(Actions.getDistributionRule(list));
            yield delay(500);
            l = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }


    } catch {
        yield put(Actions.getDistributionRule(['null']));
    }
}

function* dataAgentGroups() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.getAgentGroups;
    try {
        if (s === 0) {
            s++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }

            yield put(Actions.getAgentGroups(list));
            yield delay(500);
            s = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }


    } catch {
        yield put(Actions.getAgentGroups(['null']));
    }
}

function* dataCampaignOverviewByCampaignId({ id: campaignId }) {
    let list = [];
    options.body = null;
    options.options = 'GET';
    const url = `${apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.findCampaignOverviewByCampaignId}/${campaignId}`;
    try {
        if (p === 0) {

            p++;
            if (campaignId !== '' && campaignId !== undefined) {

                const response = yield call(fetchJSON, url, options);

                if (response.header.code === 200) {
                    if (response.items.length <= 0) {
                        list = ['null'];
                    } else {
                        response.items.forEach((element, index) => {
                            let paramFields = {
                                keyIndex: index,
                                groupName: element.name,
                                templateElementsSpec: element.templateElementsSpec,
                                levelSkills: [],
                                members: []
                            };

                            if (element.levelSkills) {
                                element.levelSkills = element.levelSkills ? element.levelSkills : [];

                                element.levelSkills.forEach((skill, indexSkill) => {
                                    if (skill) {
                                        paramFields.levelSkills.push({
                                            levelSkillId: indexSkill,
                                            levelSkillContent: skill
                                        });
                                    }
                                });
                            }

                            if (element.members) {
                                element.members = element.members ? element.members : [];

                                element.members.forEach((member, indexMember) => {
                                    if (member) {
                                        paramFields.members.push({
                                            memberId: indexMember,
                                            membersContent: member,
                                        });
                                    }
                                })
                            }

                            list.push(paramFields);
                        });
                    }

                }

                yield put(Actions.findCampaignOverviewByCampaignId(list, null));
            }
            yield delay(200);
            p = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        yield put(Actions.findCampaignOverviewByCampaignId(['null'], null));
    }
}

function* updateCampaignConfig({ formData: data, id: campaignId }) {
    options.body = JSON.stringify(data);
    options.method = 'PUT';
    const url = `${apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.putUpdateCampaignConfig}/${campaignId}`;
    if (m === 0) {
        m++;
        const response = yield call(fetchJSON, url, options);

        if (response.header.code === 200 || response.header.code === 201) {
            yield put(Actions.closeCampaignConfigDialog());
            yield put(Actions.submitSucceed(response.message));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        m = 0;
    }

}

function* createCampaigns({ formData: data }) {
    options.body = JSON.stringify(data);
    options.method = 'POST';
    const url = `${apiConfig.apiGateway.mainCampaigns}`;
    if (c === 0) {
        c++;
        const response = yield call(fetchJSON, url, options);

        if (response.code === 200 || response.code === 201) {
            yield put(Actions.submitSucceed(response.message));
            setTimeout(() => {
                window.location.href = "/campaigns/management";
            }, 1500);
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        c = 0;
    }
}

function* dataCallingListAll() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.callingListAll;
    try {
        if (na === 0) {
            na++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }
            yield put(Actions.getCallingListAll(list));
            yield delay(500);
            na = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    } catch {
        yield put(Actions.getCallingListAll(['null']));
    }
}

function* dataCampaignStatus() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.campaignsStatus;
    try {
        if (r === 0) {
            r++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }
            yield put(Actions.getCampaignStatus(list));
            yield delay(500);
            r = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    } catch {
        yield put(Actions.getCampaignStatus(['null']));
    }
}

function* dataCampaignsCategory() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.campaignsCategory;
    try {
        if (t === 0) {
            t++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }
            yield put(Actions.getCampaignCategory(list));
            yield delay(500);
            t = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    } catch {
        yield put(Actions.getCampaignCategory(['null']));
    }
}

function* dataCampaignsSubCategory() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.campaignsSubCategory;
    try {
        if (b === 0) {
            b++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }
            yield put(Actions.getCampaignSubCategory(list));
            yield delay(500);
            b = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    } catch {
        yield put(Actions.getCampaignSubCategory(['null']));
    }
}

function* dataCampaignsTag() {
    let list = [];
    options.body = null;
    options.method = 'GET';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.campaignsTag;
    try {
        if (u === 0) {
            u++;
            const response = yield call(fetchJSON, url, options);
            if (response.code === 200) {
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }
            yield put(Actions.getCampaignTag(list));
            yield delay(500);
            u = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    } catch {
        yield put(Actions.getCampaignTag(['null']));
    }
}

function* dataCampaignsMngt() {
    let list = [];
    options.body = JSON.stringify(body);
    options.method = 'POST';
    const url = apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.dataCampaign;
    try {
        if (f === 0) {
            f++;
            const response = yield call(fetchJSON, url, options);
            if (response.header.code === 200) {
                if (response.items.length > 0) {
                    response.items.forEach((element, index) => {
                        element.keyIndex = index;
                        list.push(element);
                    });
                }
            }
            yield put(Actions.getCampaign(list));
            yield delay(500);
            f = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    } catch {
        yield put(Actions.getCampaign(['null']));
    }
}

function* createCLL({ formData: data }) {
    options.body = JSON.stringify(data);
    options.method = 'POST';
    if (a === 0) {
        a++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.createCallingList, options);

        if (response.code === 200 || response.code === 201) {
            yield put(Actions.closeNewCampaignsDialog());
            yield put(Actions.submitSucceed(response.message));
            yield put(Actions.getCallingListAll([]));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        a = 0;
    }

}

function* updateCallingList({ formData: data, id: callingListId }) {
    options.body = JSON.stringify(data);
    options.method = 'PUT';
    const url = `${apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.createCallingList}/${callingListId}`;
    if (cll === 0) {
        cll++;
        const response = yield call(fetchJSON, url, options);

        if (response.code === 200 || response.code === 201) {
            yield put(Actions.closeEditCampaignsDialog());
            yield put(Actions.submitSucceed(response.message));
            yield put(Actions.getCallingListAll([]));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        cll = 0;
    }
}

function* updateCampaign({ formData: data, id: campaignId }) {
    options.body = JSON.stringify(data);
    options.method = 'PUT';
    const url = `${apiConfig.apiGateway.mainCampaigns}/${campaignId}`;
    if (e === 0) {
        e++;
        const response = yield call(fetchJSON, url, options);

        if (response.code === 200 || response.code === 201) {
            yield put(Actions.closeEditCampaignsDialog());
            yield put(Actions.submitSucceed(response.message));
            yield put(Actions.getCampaign([]));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        e = 0;
    }
}

function* updateCampaignStatus({ formData: data, id: campaignId }) {
    options.body = JSON.stringify(data);
    // options.method = 'PATCH';
    const url = `${apiConfig.apiGateway.mainCampaigns}/${campaignId + apiConfig.apiEventCampaigns.updateCampaignStatus}?newStatus=${data}`;
    if (cpstt === 0) {
        cpstt++;
        const response = yield call(fetchJSON, url, options2);

        if (response.code === 200 || response.code === 201) {
            yield put(Actions.closeEditCampaignsDialog());
            yield put(Actions.closeEnableCampaignDialog());
            yield put(Actions.submitSucceed(response.message));
            yield put(Actions.getCampaign([]));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        cpstt = 0;
    }
}

function* deleteCallingList({ formData: data, id: callingListId }) {
    options.body = JSON.stringify(data);
    options.method = 'DELETE';
    const url = `${apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.deleteCallingList + callingListId}`;
    if (ni === 0) {
        ni++;
        const response = yield call(fetchJSON, url, options);

        if (response.code === 200 || response.code === 201) {
            yield put(Actions.closeEditCampaignsDialog());
            yield put(Actions.submitSucceed(response.message));
            yield put(Actions.getCallingListAll([]));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        ni = 0;
    }
}

function* filterCampaignMgnt({ formData: data }) {
    options.method = 'POST';
    options.body = JSON.stringify(data);
    try {
        if (fil === 0) {
            let list = [];
            fil++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainCampaigns + apiConfig.apiEventCampaigns.dataCampaign, options);
            if (response.header.code === 200) {
                data = response.items;
                if(data){
                    data.forEach((log, index) => {
                        log.keyIndex = index;
                        list.push(log);
                    })
                }
            }
            else {
                list = ["null"];
            }
            yield put(Actions.loadCampaignByFilter(list));
            yield delay(500);
            fil = 0;
        }
    }
    catch (error) {
        yield put(Actions.loadCampaignByFilter(["null"]));
    }
}

export function* watchCampaigns() {
    yield takeEvery(types.FIND_CAMPAIGNS, dataCampaigns);
}

export function* watchCampaignConfiguration() {
    yield takeEvery(types.FIND_CAMPAIGN_CONFIGURATION, dataCampaignConfiguration);
}

export function* watchCampaignsOverviewByCampaignId() {
    yield takeEvery(types.FIND_CAMPAIGN_ORVERVIEW, dataCampaignOverviewByCampaignId);
}

export function* watchCampaignConfig() {
    yield takeEvery(types.UPDATE_CAMPAIGN_CONFIG, updateCampaignConfig);
}
export function* watchCallingListCampaign() {
    yield takeEvery(types.GET_CALLING_LIST_CAMPAIGN, dataCallingListCampaign);
}
export function* watchCampaignTags() {
    yield takeEvery(types.GET_CAMPAIGN_TAGS, dataCampaignTags);
}
export function* watchCampaignCategories() {
    yield takeEvery(types.GET_CAMPAIGN_CATEGORIES, dataCampaignCategories);
}
export function* watchCampaignTypes() {
    yield takeEvery(types.GET_CAMPAIGN_TYPES, dataCampaignTypes);
}
export function* watchCallStrategies() {
    yield takeEvery(types.GET_CALL_STRATEGIES, dataCallStrategies);
}
export function* watchDistributionRule() {
    yield takeEvery(types.GET_DISTRIBUTION_RULE, dataDistributionRule);
}
export function* watchAgentGropus() {
    yield takeEvery(types.GET_AGENT_GROUPS, dataAgentGroups);
}
export function* watchCreateCampaigns() {
    yield takeEvery(types.POST_CAMPAIGNS, createCampaigns);
}

export function* watchDataCallingListAll() {
    yield takeEvery(types.GET_CALLING_LIST_ALL, dataCallingListAll);
}

export function* watchDataCampaignStatus() {
    yield takeEvery(types.GET_CAMPAIGN_STATUS, dataCampaignStatus);
}

export function* watchDataCampaignCategory() {
    yield takeEvery(types.GET_CAMPAIGN_CATEGORY, dataCampaignsCategory);
}

export function* watchDataCampaignSubCategory() {
    yield takeEvery(types.GET_CAMPAIGN_SUBCATEGORY, dataCampaignsSubCategory);
}

export function* watchDataCampaignTag() {
    yield takeEvery(types.GET_CAMPAIGN_TAG, dataCampaignsTag);
}

export function* watchCreateCLL() {
    yield takeEvery(types.CREATE_CALLING_LIST, createCLL);
}

export function* watchUpdateCallingList() {
    yield takeEvery(types.UPDATE_CALLING_LIST, updateCallingList);
}

export function* watchUpdateCampaignStatus() {
    yield takeEvery(types.UPDATE_CAMPAIGN_STATUS, updateCampaignStatus);
}

export function* watchUpdateCampaign() {
    yield takeEvery(types.UPDATE_CAMPAIGN, updateCampaign);
}

export function* watchDeleteCallingList() {
    yield takeEvery(types.DELETE_CALLING_LIST, deleteCallingList);
}

export function* watchDataCampaignMngt() {
    yield takeEvery(types.GET_CAMPAIGN, dataCampaignsMngt);
}

export function* watchFilterCampaignMgnt() {
    yield takeEvery(types.FILTER_CAMPAIGN_MGMT, filterCampaignMgnt);
}

function* campaignsManagementSaga() {
    yield all([
        fork(watchCampaigns),
        fork(watchCampaignConfiguration),
        fork(watchCampaignsOverviewByCampaignId),
        fork(watchCampaignConfig),
        fork(watchCallingListCampaign),
        fork(watchCampaignTags),
        fork(watchCampaignCategories),
        fork(watchCampaignTypes),
        fork(watchCallStrategies),
        fork(watchDistributionRule),
        fork(watchAgentGropus),
        fork(watchCreateCampaigns),
        fork(watchDataCallingListAll),
        fork(watchDataCampaignStatus),
        fork(watchDataCampaignCategory),
        fork(watchDataCampaignSubCategory),
        fork(watchDataCampaignTag),
        fork(watchCreateCLL),
        fork(watchUpdateCallingList),
        fork(watchUpdateCampaign),
        fork(watchUpdateCampaignStatus),
        fork(watchDeleteCallingList),
        fork(watchDataCampaignMngt),
        fork(watchFilterCampaignMgnt),
    ]);
}

export default campaignsManagementSaga;