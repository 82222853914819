const doCrosscheck = {
    'TITLE': 'DO Crosscheck',
    'TRACKING_CODE': 'Tracking Code',
    'FFM_NAME': 'FFM Name',
    'CARRIER': 'Carrier',
    'DO_STATUS': 'DO Status',
    'TRANSPORT_STATUS': 'Transport Status',
    'TMS_MAPPING': 'TMS Mapping Status',
    'DO_CREATE_DATE': 'DO Create Date',
    'DO_LAST_DATE': 'DO Last Update',
    'LAST_CROSS': 'Last Crosscheck',
    'TODAY': 'Today',
    'WEEK': 'This week',
    'MONTH': 'This month',
    'PERIOD': 'Period',
    'SEARCH': 'Search',
    'FAIL': 'Fail',
    'TOTAL_DO': 'Total DO',
    'EXPORT': 'Export',
    'SHOW_FILTER': 'Show Filter',
    'CLOSE_FILTER': 'Close Filter',
    'CLEAR_FILTER': 'Clear Search',
};
export default doCrosscheck;