const logistic = {
    'COUNTRY': 'Country',
    'FULFILLMENT': 'Fulfillment',
    'WAREHOUSE': 'Warehouse',
    'LASTMILE': 'Lastmile',
    'PREV': 'Previous',
    'PROVINCE': 'Province',
    'PROVINCE_ID': 'Province ID',
    'LOCATION_STATUS': 'Location Status',
    'SCHEDULE_CHANGE': 'Schedule Change',
    'ONE_DAY': 'One Day Delivery',
    'SAME_DAY': 'Same Day Delivery',
    'CONFIRM': 'Confirm',
    'WOULD_APPLY_CHANGE': 'Would you like to apply changes?',
    'IMPORT_PROVIDERS': 'Import Providers',
    'SEE_TEMPLATE': 'See template',
    'IMPORT_LOCATION_STATUS': 'Import Location status',
    'VIEW_REASON_ERRORS': 'View reason of errors',
    'REMOVE': 'Remove',
    'IMPORT': 'Import',
    'CLOSE': 'Close',
    'EDIT': 'Edit',
    'ADD': 'Create',
    'EXPORT': 'Export',
    'BULK_EDIT': 'Bulk Edit',
    'TYPE_CHANGE': 'Type Change',
    'CHANGE_ALL': 'Change All',
    'CHANGE_LOCATION_STATUS': 'Change Location Status',
    'SELECT_LOCATION_STATUS': 'Select Location Status',
    'SELECT_FULFILLMENT': 'Select Fulfillment',
    'SELECT_WAREHOUSE': 'Select Warehouse',
    'SELECT_LASTMILE': 'Select Lastmile',
    'APPLY_CHANGE': 'Apply Change',
    'CANCEL': 'Cancel',
    'UPDATE': 'Update',
    'DISTRICT': 'District',
    'DISTRICTID': 'District ID',
    'SUB_DISTRICT': 'SubDistrict',
    'SUB_DISTRICTID': 'SubDistrict ID',
    'ZIPCODE': 'ZipCode',
    'NEIGHBORHOOD': 'Neighborhood',
    'NEIGHBORHOOD_ID': 'Neighborhood ID',
};
export default logistic;