const actionLog = {
    'TITLE': 'Bảng Ghi Hoạt Động',
    'CREATE_DATE': 'Ngày tạo',
    'USERID': 'ID người dùng',
    'USERNAME': 'Tên tài khoản',
    'MODULENAME': 'Tên loại mô-đun',
    'ACIONNAME': 'Tên loại hành động',
    'TABLE': 'Bảng',
    'SEARCH': 'Tìm kiếm',
    'CLOSE': 'Đóng',
    'APPLY': 'Áp dụng bộ lọc',
    'CLEAR': 'Làm mới',
    'PLACEHOLDER1': 'Nhập ID người dùng',
    'PLACEHOLDER2': 'Nhập tên tài khoản',
    'PLACEHOLDER3': 'Nhập tên loại mô-đun',
    'PLACEHOLDER4': 'Nhập tên loại hành động',
    'PLACEHOLDER5': 'Nhập tên bảng',
};
export default actionLog;