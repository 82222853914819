import * as types from './constants';

export const getDataAgentStatus = (data) => {
    return ({
        type: types.GET_DATA_AGENT_STATUS,
        payload: data,
        loading: data?.length <= 0 ? true : false,
    })
}

export const getDataAgentFromGroupID = (data, id) => {
    return ({
        type: types.GET_DATA_AGENT_FROM_GROUP_ID,
        payload: data,
        groupID: id
    })
}

export function openListenDialog(data)
{
    return {
        type: types.OPEN_LISTEN_DIALOG,
        data
    }
}

export function closeListenDialog()
{
    return {
        type: types.CLOSE_LISTEN_DIALOG
    }
}

export function joinCall(data, form) {
    return {
        type: types.JOIN_CALL,
        dataJoin: data,
        dataAgent: form
    }
}

export const submitSucceed = (message) => ({
    type: types.SUBMIT_SUCCEED,
    payload: message
});

export const submitFailed = (message) => ({
    type: types.SUBMIT_FAILED,
    payload: message
});

export const resetMessage = () => ({
    type: types.RESET_MESSAGE
});

export const resetLoading = () => ({
    type: types.RESET_LOADING
})