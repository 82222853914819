const campaignConfi ={
    'TITLE': 'Cấu hình chiến lượt bán hàng',
    'SEARCH': 'Tìm kiếm chiến dịch',
    'CAMPAIGN_NAME': 'Tên Chiến dịch',
    'RULE': 'Dist. Rule',
    'PARAM': 'Dist. Param',
    'AGENT_GROUP': 'Nhóm đại lý',
    'SKILL': 'Cấp độ Kỹ năng',
    'CALLING': 'Danh sách gọi điện',
    'NO_DATA': 'Không có dữ liệu để hiển thị',
    'INCLUDING': 'Bao gồm:',
    'MEMBER': 'Thành viên',
    'TEAM_LEAD': 'Trưởng nhóm',
    'DETAIL': 'Chi tiết',
    'ALL_CAMPAIGN': 'Tất cả Chiến dịch',
    'NUMBER': 'Số lượng người dùng',
    'EDIT': 'Chỉnh sửa',
    'RESET': 'Đặt lại',
    'SEARCH_BY': 'Tìm kiếm theo Chiến dịch',
    'CAMPAIGN': 'Chiến dịch',
    'SEARCH_BY_AG': 'Tìm kiếm theo Nhóm tác nhân',
    'SEARCH_BY_CL': 'Tìm kiếm theo danh sách Gọi điện',
    'SEARCH_BY_SL': 'Tìm kiếm theo Cấp độ Kỹ năng',
    'SEARCH_BY_OFFER': 'Tìm kiếm theo phiếu mua hàng',
    'OFFER': 'Phiếu mua hàng',
    'SEARCH_BY_PARTNER': 'Tìm kiếm theo đối tác',
    'PARTNER': 'Đối tác',
    'CLOSE': 'Đóng',
}

export default campaignConfi;