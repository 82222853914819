import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';

import { fetchJSON } from '../../../helpers/api';

import * as types from './constants';

import apiConfig from '../../../config/routesApi';

import * as Actions from './actions';

var j = 0, z = 0, i = 0, k = 0, l = 0;
const delay = (ms) => new Promise(res => setTimeout(res, ms));
const cookie = new Cookies();
const user_token = cookie.get("user_access");

var options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '') }
    //+ (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '')
};

function* dataAgentSummary({numberPage, sizePage}) {
    options.method = 'GET';
    options.body = null;
    try {
        if (i === 0) {
            let list = [];
            i++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainCMMs + apiConfig.apiEventCMMs.getAgentSummary + 'pageIndex=' + numberPage + '&pageSize=' + sizePage, options);
            if (response.header.code === 200) {
                list = response.items;                
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataAgentSummary(list));
            yield delay(100);
            i = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        yield put(Actions.getDataAgentSummary(["null"]));
    }
}

function* dataAgentInbound({ searchObject: formSearch, numberPage, sizePage }) {
    options.method = 'POST';
    options.body = JSON.stringify(formSearch);
    try {
        if (z === 0) {
            let list = [];
            z++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainCMMs + apiConfig.apiEventCMMs.getAgentInbound + 'page='+ numberPage + '&size=' + sizePage, options);
            if (response.header.code === 200) {
                response.items.forEach(element => {
                    element.assigneeSip = element.assignee.assigneeSip;
                    element.assigneeName = element.assignee.assigneeUsername;
                });
                list = response.items;
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataAgentInbound(list));
            yield delay(100);
            z = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        yield put(Actions.getDataAgentInbound(["null"]));
    }
}

function* filterAgent({ formData: data,numberPage, sizePage }) {
    options.method = 'POST';
    options.body = JSON.stringify(data);
    try {
        if (j === 0) {
            let list = [];
            j++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainCMMs + apiConfig.apiEventCMMs.getAgentSummary + 'pageIndex=' + numberPage + '&pageSize=' + sizePage, options);
            if (response.header.code === 200) {
                list = response.items;
            }
            else {
                list = ["null"];
            }
            yield put(Actions.loadAgentByFilter(list));
            yield delay(500);
            j = 0;
            yield delay(7000);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        yield put(Actions.loadAgentByFilter(["null"]));
    }
}

function* dataInboundTeam({ dataTeam }) {
    options.method = 'POST';
    options.body = JSON.stringify(dataTeam);
    try {
        if (k === 0) {
            let list = [];
            k++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainCMMs + apiConfig.apiEventInbound.getDataTeam, options);
            if (response.header.code === 200 && response.total >= 0) {
                list = response.items;
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataTeam(list, []));
            yield delay(500);
            k = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        yield put(Actions.getDataTeam(["null"]));
    }
}
function* dataInboundAgent({ dataAgent }) {
    options.method = 'POST';
    options.body = JSON.stringify(dataAgent);
    try {
        if (l === 0) {
            let list = [];
            l++;
            const response = yield call(fetchJSON, apiConfig.apiGateway.mainCMMs + apiConfig.apiEventInbound.getDataAgent, options);
            if (response.header.code === 200 && response.total >= 0) {
                list = response.items;
            }
            else {
                list = ["null"];
            }
            yield put(Actions.getDataAgent(list, []));
            yield delay(500);
            l = 0;
            yield delay(1);
            yield put(Actions.resetLoading());
        }
    }
    catch (error) {
        yield put(Actions.getDataAgent(["null"]));
    }
}

export function* watchFilterAgent() {
    yield takeEvery(types.FILTER_AGENT, filterAgent);
}

export function* watchAgentSummary() {
    yield takeEvery(types.GET_DATA_AGENT_SUMMARY, dataAgentSummary);
}

export function* watchAgentInbound() {
    yield takeEvery(types.GET_DATA_AGENT_INBOUND, dataAgentInbound);
}
export function* watchInboundTeam() {
    yield takeEvery(types.GET_DATA_TEAM, dataInboundTeam);
}
export function* watchInboundAgent() {
    yield takeEvery(types.GET_DATA_AGENT, dataInboundAgent);
}

function* agentSummarySaga() {
    yield all([
        fork(watchAgentSummary),
        fork(watchAgentInbound),
        fork(watchFilterAgent),
        fork(watchInboundTeam),
        fork(watchInboundAgent),
    ]);
}

export default agentSummarySaga;