const slaCutOffTime ={
    'TITLE': 'Cut Off Time',
    'CREATE': 'Create',
    'PARTNER_CODE': 'Partner Code',
    'PARTNER_Name': 'Partner Name',
    'CUT_OFF_TIME': 'Cut Off Time FFM/LM',
    'UPDATE_BY': 'Update By',
    'UPDATE_AT': 'Update At',
    'ACTION': 'Action',
}

export default slaCutOffTime