const slaCutOffTime ={
    'TITLE': 'Cắt giảm thời gian',
    'CREATE': 'Tạo',
    'PARTNER_CODE': 'Mã đối tác',
    'PARTNER_Name': 'Tên đối tác',
    'CUT_OFF_TIME': 'Cắt thời gian FFM/LM',
    'UPDATE_BY': 'Cập nhật bởi',
    'UPDATE_AT': 'Cập nhật lúc',
    'ACTION': 'Hành động',
}

export default slaCutOffTime