import * as types from './constants';

const initialState = {
    loading: false,
    dataAgentStatus: [],
    dataAgentGroup: [],
    onlineCount: 0,
    offlineCount: 0,
    showMessage: null,
    error: null,
    listenDialog     : {
        props: {
            open: false
        },
        data : null
    },
}

const AgentMonitorCMMs = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATA_AGENT_STATUS:
            return { ...state, dataAgentStatus: action.payload, loading: action.loading, onlineCount: action.online, offlineCount: action.offline }
        case types.GET_DATA_AGENT_FROM_GROUP_ID:
            return { ...state, dataAgentGroup: action.payload }
        case types.OPEN_LISTEN_DIALOG:
        {
            return {
                ...state,
                listenDialog: {
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case types.CLOSE_LISTEN_DIALOG:
        {
            return {
                ...state,
                listenDialog: {
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case types.JOIN_CALL:
            return { ...state, loading: true }
        case types.SUBMIT_SUCCEED:
            return { ...state, showMessage: action.payload, error: null, loading: false }
        case types.SUBMIT_FAILED:
            return { ...state, showMessage: null, error: action.payload, loading: false }
        case types.RESET_MESSAGE:
            return { ...state, showMessage: null, error: null }
        case types.RESET_LOADING:
            return {
                ...state,
                loading: false
            }
        default:
            return { ...state };
    }
}

export default AgentMonitorCMMs;