import outboudDashboard from "./outboudDashboard";
import inboudDashboard from "./inboudDashboard";
import agentGroup from "./agentGroup";
import agentMonitor from "./agentMonitor";
import agentSummaries from "./agentSummaries";
import inboundSummaries from "./inboundSummaries";
import logistic from "./logistic";
import doCrosscheck from "./doCrosscheck";
import logisticStatus from "./logisticStatus";
import logisticLocation from "./logisticLocation";
import logisticRescue from "./logisticRescue";
import users from "./users";
import group from "./group";
import team from "./team";
import campaignM from "./campaignsManagement";
import callingList from "./callingList";
import campaignConfi from "./campaignsOverallReport";
import agentCampaign from "./campaignsConfiguration";
import createCP from "./createNewCampaign";
import actionLog from "./actionLog";
import geoLoadBalancing from "./geoLoadBalancing";
import slaCutOffTime from "./slaCutOffTime";
import slaLogistic from "./slaLogistic";

const vi = {
    hello : 'Xin chào mọi người',
    language_title: 'Thay đổi ngôn ngữ',
    'OUTBOUND_DASHBOARD' : outboudDashboard,
    'INBOUND_DASHBOARD' : inboudDashboard,
    'AGENT_GROUP' : agentGroup,
    'AGENT_MONITOR' : agentMonitor,
    'AGENT_SUMMARIES' : agentSummaries,
    'INBOUND_SUMMARIES' : inboundSummaries,
    'LOGISTIC' : logistic,
    'LOGISTIC_STATUS': logisticStatus,
    'LOGISTIC_LOCATION': logisticLocation,
    'LOGISTIC_RESCUE': logisticRescue,
    'DOCROSSCHECK' : doCrosscheck,
    'USER': users,
    'GROUP': group,
    'TEAM': team,
    'CAMPAIGN_M': campaignM,
    'CALLING_LIST': callingList,
    'CAMPAIGN_CONFI': campaignConfi,
    'AGENT_CP': agentCampaign,
    'CREATE_CP': createCP,
    'ACTION_LOG' : actionLog,
    'GEO_BALANCING' : geoLoadBalancing,
    'SLA_CUTOFFTIME' : slaCutOffTime,
    'SLA_LOGISTIC' : slaLogistic,
};
export default vi;