import * as types from './constants';

const initialState = {
    loading: false,
    dataAgentSummary: [],
    showMessage: null,
    error: null,
    cdrsDialog     : {
        props: {
            open: false
        },
        data : null
    },
    dataTeam: [],
    dataAgent: [],
    formDataFilter: {},
    formDataFilterOutbound: {}
}

const AgentSummaryCMMs = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATA_AGENT_SUMMARY:
            return { ...state, dataAgentSummary: action.payload, loading: action.loading }
        case types.GET_DATA_AGENT_INBOUND:
            return { ...state, dataAgentSummary: action.payload, loading: action.loading }
        case types.GET_DATA_TEAM:
            return { ...state, dataTeam: action.payload, loading: action.loading }
        case types.GET_DATA_AGENT:
            return { ...state, dataAgent: action.payload, loading: action.loading }
        case types.SET_FILTER:
            return { ...state, formDataFilter: action.payload}
        case types.SET_FILTER_OUTBOUND:
            return { ...state, formDataFilterOutbound: action.payload}
        case types.OPEN_CDRS_DIALOG:
        {
            return {
                ...state,
                cdrsDialog: {
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case types.CLOSE_CDRS_DIALOG:
        {
            return {
                ...state,
                cdrsDialog: {
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case types.FILTER_AGENT:
            return { ...state, loading: true }
        case types.LOAD_AGENT_BY_FILTER:
            return { ...state, loading: false, dataAgentSummary: action.payload }
        case types.SUBMIT_SUCCEED:
            return { ...state, showMessage: action.payload, error: null, loading: false }
        case types.SUBMIT_FAILED:
            return { ...state, showMessage: null, error: action.payload, loading: false }
        case types.RESET_MESSAGE:
            return { ...state, showMessage: null, error: null }
        case types.DATA_TEAM_BY_CONDITION:
            return {
                ...state,
                dataTeamByCondition: action.data
            }
        case types.DATA_AGENT_BY_CONDITION:
            return {
                ...state,
                dataAgentByCondition: action.data
            }
        case types.RESET_LOADING:
            return {
                ...state,
                loading: false
            }
        default:
            return { ...state };
    }
}

export default AgentSummaryCMMs;