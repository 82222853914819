import * as types from './constants';
const initialState = {
    loading: false,
    showMessage: null,
    error: null,
    dataActionLog: [],
    dataModuleNameTypes: [],
}

const ActionLog = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATA_ACTION_LOG:
        {
            return {...state, dataActionLog: action.payload, loading: action.loading }
        }
        case types.FILTER_ACTION_LOG:
            return { ...state, loading: true }
        case types.LOAD_ACTION_BY_FILTER:
            return { ...state, loading: false, dataActionLog: action.payload }
        default:
            return { ...state };
    }
}

export default ActionLog;