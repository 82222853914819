const team ={
    'TITLE': 'Các thành viên trong nhóm',
    'TEAM': 'Đội',
    'SEARCH': 'Tìm kiếm',
    'EXPORT': 'Xuất',
    'ADD_MEMBER': 'Thêm thành viên',
    'Full_NAME_ID': 'Họ tên và ID',
    'USER': 'Tên người dùng',
    'NO_DATA': 'Không có dữ liệu để hiển thị',
    'MOVE': 'Chuyển đến nhóm',
    'REMOVE': 'Xóa',
    'FULL_NAME': 'Tên đầy đủ',
    'ALERT': 'Bạn có chắc chắn xóa người dùng khỏi nhóm không?',
    'CANCEL': 'Hủy bỏ',
}

export default team;