const inboudDashboard = {
    'INBOUND_CALL': 'Inbound Call',
    'CENTER': 'Center',
    'TOTAL_CALL': 'Total Calls',
    'WAITING': 'Waiting',
    'ANSWER': 'Answered',
    'ABANDONED': 'Abandoned',
    'TODAY' : 'Today',
    'YESTERDAY' : 'Yesterday',
    'WEEK' : 'This week',
    'MONTH' : 'This month',
    'SERVICE_LEVEL' : '% Service Level (SL)',
    'ABANDON_RATE' : 'Abandon Rate',
    'TOTAL_CS_STATUS' : 'Total CS Status',
    'STATISTIC' : 'Statistic',
    'USERNAME' : 'Username',
    'STATUS' : 'Status',
    'ANSWER_CALL' : 'Answered Calls',
    'TIME' : 'Time in State',
};
export default inboudDashboard;