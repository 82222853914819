export const GET_DATA_AGENT_SUMMARY = 'GET_DATA_AGENT_SUMMARY';
export const GET_DATA_AGENT_INBOUND = 'GET_DATA_AGENT_INBOUND';
export const OPEN_CDRS_DIALOG = 'OPEN_CDRS_DIALOG';
export const CLOSE_CDRS_DIALOG = 'CLOSE_CDRS_DIALOG';
export const FILTER_AGENT = 'FILTER_AGENT';
export const LOAD_AGENT_BY_FILTER = 'LOAD_AGENT_BY_FILTER';
export const SUBMIT_SUCCEED = 'SUBMIT_SUCCEED';
export const SUBMIT_FAILED = 'SUBMIT_FAILED';
export const RESET_MESSAGE = 'RESET_MESSAGE';
export const GET_DATA_TEAM = 'GET_DATA_TEAM';
export const GET_DATA_AGENT = 'GET_DATA_AGENT';
export const DATA_TEAM_BY_CONDITION = 'DATA_TEAM_BY_CONDITION';
export const DATA_AGENT_BY_CONDITION = 'DATA_AGENT_BY_CONDITION';
export const RESET_LOADING = 'RESET_LOADING';
export const SET_FILTER = 'SET_FILTER';
export const SET_FILTER_OUTBOUND = 'SET_FILTER_OUTBOUND';
