const outboudDashboard = {
    'OUTBOUND_DASHBOARD': 'Tổng quan cuộc gọi đi',
    'TOTAL_AG_STATUS': 'Tổng quan trạng thái tư vấn viên',
    'TODAY': 'Hôm nay',
    'HIDE': 'Ẩn',
    'SHOW': 'Hiện biểu đồ',
    'AG_STATUS': 'Trạng thái tư vấn viên',
    'MEMBER': 'Thành viên',
    'USERNAME': 'Tên tài khoản',
    'GROUP': 'Nhóm',
    'TEAM': 'Đội',
    'STATUS': 'Trạng thái',
    'REASON': 'Lý do',
    'TIME': 'Thời gian',
};
export default outboudDashboard;