export const GET_DATA_DASHBOARD_AGENT_STATUS = 'GET_DATA_DASHBOARD_AGENT_STATUS';
export const GET_DATA_CHART = 'GET_DATA_CHART';
export const FILTER_MULTIPLE_GROUP = 'FILTER_MULTIPLE_GROUP';
export const FILTER_MULTIPLE_USER = 'FILTER_MULTIPLE_USER';
export const FILTER_MULTIPLE_TEAM = 'FILTER_MULTIPLE_TEAM';
export const SUBMIT_SUCCEED = 'SUBMIT_SUCCEED';
export const SUBMIT_FAILED = 'SUBMIT_FAILED';
export const RESET_MESSAGE = 'RESET_MESSAGE';
export const GET_DATA_INBOUND_TICKET = 'GET_DATA_INBOUND_TICKET';
export const GET_DATA_INBOUND_TOTAL_CS = 'GET_DATA_INBOUND_TOTAL_CS';
export const GET_CHART_SERVICES = 'GET_CHART_SERVICES';
export const GET_CHART_ABAN_CALL = 'GET_CHART_ABAN_CALL';