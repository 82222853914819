const agentMonitor = {
    'TITLE': 'Danh sách tư vấn viên',
    'GROUP': 'Nhóm',
    'ONLINE': 'Trực tuyến',
    'OFFLINE': 'Ngoại tuyến',
    'ALL': 'Tất cả',
    'REGISTER': 'Đăng ký',
    'UNREGISTER': 'Chưa đăng ký',
    'COLUMN': 'Cột',
    'COLUMNS': 'Cột',
    'IDLE': '[Rảnh rỗi]',
    'SPY': 'Gián điệp',
    'WHISPER': 'Thì thầm',
    'BARGE': 'Chen',
    'TALK1': 'Bạn đang trò chuyện giữa tư vấn viên ',
    'TALK2': ' và khách hàng (',
    'TALK3': 'Trạng thái của ',
    'TALK4': ' đã được chuyển đến ',
    'CANCEL': 'Hủy bỏ',
    'JOIN_CALLS': 'Tham gia cuộc gọi',
};
export default agentMonitor;