// @flow

import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Logistic from './logistic/reducers';
import UserManagement from './users/management/reducers';
import GroupManagement from './users/group/reducers';
import TeamManagement from './users/team/reducers';
import ActionLog from './actionlog/reducers';
import CampaignsManagement from './campaigns/reducers';
import AgentGroupCMMs from './ccms/agentGroup/reducers';
import AgentMonitorCMMs from './ccms/agentMonitor/reducers';
import AgentSummaryCMMs from './ccms/agentSummary/reducers';
import DashboardCCMs from './ccms/dashboard/reducers';

export default combineReducers({
    Auth,
    AppMenu,
    Layout,
    Logistic,
    UserManagement,
    GroupManagement,
    TeamManagement,
    ActionLog,
    CampaignsManagement,
    AgentGroupCMMs,
    AgentMonitorCMMs,
    AgentSummaryCMMs,
    DashboardCCMs
});
