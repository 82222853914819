import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';

import { fetchJSON } from '../../../helpers/api';

import * as types from './constants';

import apiConfig from '../../../config/routesApi';

import * as Actions from './actions';

var j = 0, z = 0, b = 0;
const delay = (ms) => new Promise(res => setTimeout(res, ms));
const cookie = new Cookies();
const user_token = cookie.get("user_access");

var options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '') }
    //+ (user_token ? (typeof user_token == 'object' ? user_token.access_token : JSON.parse(user_token).access_token) : '')
};

function* dataAgentByTeam({ id: teamId }) {
    options.method = 'GET';
    options.body = null;
    try {
        if (b === 0) {
            let list = [];
            b++;
            if (teamId !== '') {
                const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.getAgentByTeam + teamId + '/1/200', options);
                if (response.header.code === 200) {
                    if (response.items.length <= 0) {
                        list = ["null"];
                    }
                    response.items.forEach(element => {
                        element.userId = element.user.userId;
                        element.username = element.user.username;
                        element.fullName = element.user.fullName;
                        list.push(element);
                    });
                }
                else {
                    list = ["null"];
                }
                yield put(Actions.getDataAgentByTeam(list, null));
            }
            yield delay(200);
            b = 0;
        }
    }
    catch (error) {
        yield put(Actions.getDataAgentByTeam(["null"], null));
    }
}

function* moveUserTeam({ formData: data }) {
    options.body = JSON.stringify(data);
    options.method = 'POST';
    if (j === 0) {
        j++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.postMoveTeam, options);

        if (response.header.code === 200 || response.header.code === 201) {
            yield put(Actions.submitSucceed(response.message));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        j = 0;
    }

}

function* removeUserTeam({ formData: data }) {
    options.body = JSON.stringify(data);
    options.method = 'POST';
    if (z === 0) {
        z++;
        const response = yield call(fetchJSON, apiConfig.apiGateway.mainUsers + apiConfig.apiEventUsers.postRemoveTeam, options);

        if (response.header.code === 200 || response.header.code === 201) {
            yield put(Actions.closeDeleteAgentDialog());
            yield put(Actions.submitSucceed(response.message));
        }
        else {
            yield put(Actions.submitFailed(response.message));
        }
        yield delay(500);
        z = 0;
    }

}

export function* watchAgentByTeam() {
    yield takeEvery(types.GET_DATA_AGENT_BY_TEAM, dataAgentByTeam);
}

export function* watchMoveUserTeam() {
    yield takeEvery(types.MOVE_USER_TEAM, moveUserTeam);
}

export function* watchRemoveUserTeam() {
    yield takeEvery(types.REMOVE_USER_TEAM, removeUserTeam);
}


function* userTeamSaga() {
    yield all([fork(watchAgentByTeam), fork(watchMoveUserTeam), fork(watchRemoveUserTeam)]);
}

export default userTeamSaga;