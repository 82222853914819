export const GET_DATA_AGENT_BY_GROUP = 'GET_DATA_AGENT_BY_GROUP';
export const GET_ALL_GR_RULE_BY_AGENT  = 'GET_ALL_GR_RULE_BY_AGENT';
export const GET_DATA_GROUPTYPE = 'GET_DATA_GROUPTYPE';
export const SUBMIT_SUCCEED = 'SUBMIT_SUCCEED';
export const SUBMIT_FAILED = 'SUBMIT_FAILED';
export const RESET_MESSAGE = 'RESET_MESSAGE';
export const MOVE_USER_GROUP = 'MOVE_USER_GROUP';
export const REMOVE_USER_GROUP = 'REMOVE_USER_GROUP';
export const OPEN_NEW_AGENT_DIALOG = 'OPEN_NEW_AGENT_DIALOG';
export const CLOSE_NEW_AGENT_DIALOG = 'CLOSE_NEW_AGENT_DIALOG';
export const OPEN_DELETE_AGENT_DIALOG = 'OPEN_DELETE_AGENT_DIALOG';
export const CLOSE_DELETE_AGENT_DIALOG = 'CLOSE_DELETE_AGENT_DIALOG';
export const OPEN_NEW_GROUP_DIALOG = 'OPEN_NEW_GROUP_DIALOG';
export const CLOSE_NEW_GROUP_DIALOG = 'CLOSE_NEW_GROUP_DIALOG';
export const UPDATE_USER_SKILL_LEVEL = 'UPDATE_USER_SKILL_LEVEL';
export const CREATE_NEW_GROUP = 'CREATE_NEW_GROUP';