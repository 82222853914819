const createCP ={
    'BACK': 'Trở lại',
    'FINISH': 'Kết thúc',
    'NEXT': 'Tiếp theo',
    'ERROR': 'Lỗi, Vui lòng điền thông tin!',
    'CP_NAME': 'Tên Chiến dịch:',
    'START_DATE': 'Ngày bắt đầu:',
    'END_DATE': 'Ngày kết thúc:',
    'CP_TYPE': 'Loại Chiến dịch:',
    'CP_TAG': 'Thẻ chiến dịch:',
    'CP_CATE': 'Danh mục Chiến dịch:',
    'NAME': 'Tên',
    'CALLING': 'Danh sách cuộc gọi',
    'SELECTED': 'Đã chọn',
    'REMOVE': 'Xóa tất cả',
    'SELECT_CALL': 'Chọn Chiến lược Cuộc gọi',
    'SHORT_NAME': 'Tên ngắn',
    'DIS_RULE': 'Quy tắc phân phối',
    'DESC': 'Mô tả:',
    'SELECT_AGENT': 'Chọn Nhóm tác nhân',
    'RULE': 'Quy tắc:',
    'STRATEGY': 'Chiến lược:',
    'AGENT_GROUP': 'Nhóm đại lý:',
}

export default createCP;