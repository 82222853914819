const campaignM ={
    'TITLE': 'Campaign List',
    'SEARCH': 'Search For Campaigns',
    'NEW_CAMPAIGN': 'New Campaign',
    'CAMPAIGN_NAME': 'Campaign Name',
    'OWNER_NAME': 'Owner Name',
    'START_TIME': 'Start Time',
    'STOP_TIME': 'Stop Time',
    'STATUS': 'Status',
    'CAMPAIGN_TYPE': 'Campaign Type',
    'CAMPAIGN_CATE': 'Campaign Category',
    'CAMPAIGN_TAG': 'Campaign Tag',
    'NO_DATA': 'No Data to Display',
    'CLOSE': 'Close',
    'CLEAR': 'Clear Filter',
    'STATUS_NAME': 'Status Name',
    'CATEGORY': 'Category',
    'SUB_CATEGORY': 'SubCategory',
    'TAG': 'Tag',
    'START_DATE': 'Start Date',
    'STOP_DATE': 'Stop Date',
    'ALERT_1': 'Are you sure to start this campaign?',
    'ALERT_2': 'Are you sure to stop this campaign?',
    'ALERT_3': 'Are you sure to delete this campaign?',
    'ADD': 'Add',
    'SAVE': 'Save',
    'CONFIRM': 'Confirm',
    'CANCEL': 'Cancel',
}

export default campaignM;