const createCP ={
    'BACK': 'Back',
    'FINISH': 'Finish',
    'NEXT': 'Next',
    'ERROR': 'Error, Please fill in the information !',
    'CP_NAME': 'Campaign Name:',
    'START_DATE': 'Start Date:',
    'END_DATE': 'End Date:',
    'CP_TYPE': 'Campaign Type:',
    'CP_TAG': 'Campaign Tag:',
    'CP_CATE': 'Campaign Category:',
    'NAME': 'Name',
    'CALLING': 'Calling list',
    'SELECTED': 'Selected',
    'REMOVE': 'Remove All',
    'SELECT_CALL': 'Select Call Strategy',
    'SHORT_NAME': 'Short Name',
    'DIS_RULE': 'Distribution Rule',
    'DESC': 'Description:',
    'SELECT_AGENT': 'Select Agent Group',
    'RULE': 'Rule:',
    'STRATEGY': 'Strategy:',
    'AGENT_GROUP': 'Agent group:',
}

export default createCP;