import * as types from './constants';

export const findCampaigns = (data) => {
    return (
        {
            type: types.FIND_CAMPAIGNS,
            payload: data,
            loading: data?.length <= 0 ? true : false
        }
    )
}

export const findCampaignsConfiguration = (data) => {
    return (
        {
            type: types.FIND_CAMPAIGN_CONFIGURATION,
            payload: data,
            loading: data?.length <= 0 ? true : false
        }
    )
}

export const findCampaignOverviewByCampaignId = (data, campaignId) => {
    return ({
        type: types.FIND_CAMPAIGN_ORVERVIEW,
        payload: data,
        loading: data?.length <= 0 ? true : false,
        id: campaignId
    })
}


export const openCampaignConfigDialog = (data) => {
    return {
        type: types.OPEN_CAMPAIGN_CONFIG_DIALOG,
        payload: data
    }
}

export function closeCampaignConfigDialog() {
    return {
        type: types.CLOSE_CAMPAIGN_CONFIG_DIALOG
    }
}

export function updateCampaignConfig(data, campaignId) {
    return {
        type: types.UPDATE_CAMPAIGN_CONFIG,
        formData: data,
        id: campaignId
    }
}

export const submitSucceed = (message) => ({
    type: types.SUBMIT_SUCCEED,
    payload: message
});

export const submitFailed = (message) => ({
    type: types.SUBMIT_FAILED,
    payload: message
});

export const resetMessage = () => ({
    type: types.RESET_MESSAGE
})

export const filterMultipleCampaigns = (data) => ({
    type: types.FILTER_MULTIPLE_CAMPAIGNS,
    payload: data
})

export const resetCampaignsConfiguration = (data) => ({
    type: types.RESET_CAMPAIGN_CONFIGURATION,
    payload: data
})
export function postCampaigns(data) {
    return {
        type: types.POST_CAMPAIGNS,
        formData : data
    }
}


export const getCampaign = (data) => {
    return ({
        type: types.GET_CAMPAIGN,
        payload: data,
        loading: data?.length <= 0 ? true : false
    })
}

export const getCallingListAll = (data) => {
    return ({
        type: types.GET_CALLING_LIST_ALL,
        payload: data,
        loading: data?.length <= 0 ? true : false
    })
}

export const getCampaignStatus = (data) => {
    return ({
        type: types.GET_CAMPAIGN_STATUS,
        payload: data,
    })
}

export const getCampaignCategory = (data) => {
    return ({
        type: types.GET_CAMPAIGN_CATEGORY,
        payload: data,
    })
}

export const getCampaignSubCategory = (data) => {
    return ({
        type: types.GET_CAMPAIGN_SUBCATEGORY,
        payload: data,
    })
}

export const getCampaignTag = (data) => {
    return ({
        type: types.GET_CAMPAIGN_TAG,
        payload: data,
    })
}

export function createCallingList(data) {
    return {
        type: types.CREATE_CALLING_LIST,
        formData: data
    }
}

export function changeFormData(data) {
    return {
        type: types.CHANGE_FORM_DATA,
        data: data
    }
}
export function saveDataStep2(data) {
    return {
        type: types.SAVE_DATA_STEP2,
        data: data
    }
}
export function saveDataStep3(data) {
    return {
        type: types.SAVE_DATA_STEP3,
        data: data
    }
}
export function saveDataStep4(data) {
    return {
        type: types.SAVE_DATA_STEP4,
        data: data
    }
}
export function saveDataStep5(data) {
    return {
        type: types.SAVE_DATA_STEP5,
        data: data
    }
}
export function saveDataStep(data) {
    return {
        type: types.SAVE_DATA_STEP,
        data: data
    }
}

export const getCampaignTags = (data) => {
    return (
        {
            type: types.GET_CAMPAIGN_TAGS,
            payload: data,
            loading: data?.length <= 0 ? true : false
        }
    )
}
export const getCampaignCategories = (data) => {
    return (
        {
            type: types.GET_CAMPAIGN_CATEGORIES,
            payload: data,
            loading: data?.length <= 0 ? true : false
        }
    )
}
export const getCampaignTypes = (data) => {
    return (
        {
            type: types.GET_CAMPAIGN_TYPES,
            payload: data,
            loading: data?.length <= 0 ? true : false
        }
    )
}
export const getCallingListCampaign = (data) => {
    return (
        {
            type: types.GET_CALLING_LIST_CAMPAIGN,
            payload: data,
            loading: data?.length <= 0 ? true : false
        }
    )
}
export const getCallStrategies = (data) => {
    return (
        {
            type: types.GET_CALL_STRATEGIES,
            payload: data,
            loading: data?.length <= 0 ? true : false
        }
    )
}
export const getDistributionRule = (data) => {
    return (
        {
            type: types.GET_DISTRIBUTION_RULE,
            payload: data,
            loading: data?.length <= 0 ? true : false
        }
    )
}
export const getAgentGroups = (data) => {
    return (
        {
            type: types.GET_AGENT_GROUPS,
            payload: data,
            loading: data?.length <= 0 ? true : false
        }
    )
    }
export function updateCallingList(data, callingListId) {
    return {
        type: types.UPDATE_CALLING_LIST,
        formData: data,
        id: callingListId
    }
}

export function updateCampaign(data, campaignId) {
    return {
        type: types.UPDATE_CAMPAIGN,
        formData: data,
        id: campaignId
    }
}

export function updateCampaignStatus(data, campaignId) {
    return {
        type: types.UPDATE_CAMPAIGN_STATUS,
        formData: data,
        id: campaignId
    }
}

export function deleteCallingList(callingListId) {
    return {
        type: types.DELETE_CALLING_LIST,
        id: callingListId
    }
}

export const openNewCampaignsDialog = () =>
{
    return {
        type: types.OPEN_CAMPAIGN_NEW_DIALOG
    }
}

export function closeNewCampaignsDialog()
{
    return {
        type: types.CLOSE_CAMPAIGN_NEW_DIALOG
    }
}

export function openEditCampaignsDialog(data)
{
    return {
        type: types.OPEN_CAMPAIGN_EDIT_DIALOG,
        data
    }
}

export function closeEditCampaignsDialog() {
    return {
        type: types.CLOSE_CAMPAIGN_EDIT_DIALOG
    }
}

export function openEnableCampaignDialog(data)
{
    return {
        type: types.OPEN_START_CAMPAIGN_DIALOG,
        data
    }
}

export function closeEnableCampaignDialog()
{
    return {
        type: types.CLOSE_START_CAMPAIGN_DIALOG
    }
}

export function openDisableCampaignDialog(data)
{
    return {
        type: types.OPEN_STOP_CAMPAIGN_DIALOG,
        data
    }
}

export function closeDisableCampaignDialog()
{
    return {
        type: types.CLOSE_STOP_CAMPAIGN_DIALOG
    }
}

export function openDeleteCampaignDialog(data)
{
    return {
        type: types.OPEN_DELETE_CAMPAIGN_DIALOG,
        data
    }
}

export function closeDeleteCampaignDialog()
{
    return {
        type: types.CLOSE_DELETE_CAMPAIGN_DIALOG
    }
}

export function filterCampaignMgnt(data) {
    return {
        type: types.FILTER_CAMPAIGN_MGMT,
        formData: data
    }
}

export function loadCampaignByFilter(data) {
    return {
        type: types.LOAD_CAMPAIGN_BY_FILTER,
        payload: data,        
    }
}

export const resetLoading = () => ({
    type: types.RESET_LOADING
})