const agentGroup = {
    'TITLE': 'Agent Group',
    'GROUP': 'Groups',
    'SEARCH': 'Search Text....',
    'REFRESH': 'Refresh',
    'CLEAR_SEARCH': 'Clear Search',
    'TRACK': 'Track Real Time',
    'NAME': 'NAME',
    'SHORT_NAME': 'SHORT NAME',
};
export default agentGroup;