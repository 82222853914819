const group = {
    'TITLE': 'Thành viên nhóm',
    'FULL_NAME_ID': 'Họ tên và ID',
    'GROUP': 'Nhóm',
    'AGENT': 'Tư vấn viên/nhân viên chăm sóc khách hàng',
    'TEAM_LEAD': 'Trưởng nhóm',
    'SEARCH': 'Tìm kiếm',
    'USER': 'Tên người dùng',
    'SKILL': 'Cấp độ kỹ năng',
    'EXPORT': 'Xuất',
    'ADD_MEMBER': 'Thêm thành viên',
    'ADD_GROUP': 'Thêm nhóm',
    'USER_ID': 'Tên người dùng và ID',
    'NEW_USER': 'Người dùng mới',
    'FULL_NAME': 'Tên đầy đủ',
    'REMOVE': 'Xóa',
    'MOVE': 'Di chuyển vào nhóm',
    'NAME': 'Tên',
    'SHORT_NAME': 'Tên viết tắt',
    'DESC': 'Mô tả',
    'TYPE': 'Kiểu',
    'NONE': 'Không có',
    'SKILL_ID': 'SkillId',
    'ADD': 'Thêm',
    'ALERT': 'Bạn có chắc chắn xóa người dùng khỏi nhóm không?',
    'CANCEL': 'Hủy bỏ',
}

export default group;