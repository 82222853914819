import * as types from './constants';

export const getDataAgentSummary = (data, numberPage, sizePage) => {
    return ({
        type: types.GET_DATA_AGENT_SUMMARY,
        payload: data,
        loading: data?.length <= 0 ? true : false,
        numberPage: numberPage,
        sizePage: sizePage,
    })
} 

export const getDataAgentInbound = (data, formSearch, numberPage, sizePage) => {
    return ({
        type: types.GET_DATA_AGENT_INBOUND,
        payload: data,
        searchObject: formSearch,
        loading: data?.length <= 0 ? true : false,
        numberPage: numberPage,
        sizePage: sizePage,
    })
}
export const getDataTeam = (data, dataTeam) => {
    return ({
        type: types.GET_DATA_TEAM,
        payload: data,
        dataTeam: dataTeam,
        loading: data?.length <= 0 ? true : false,
    })
}
export function dataTeamByCondition(data) {
    return {
        type: types.DATA_TEAM_BY_CONDITION,
        data: data
    }
}
export const getDataAgent = (data, dataAgent) => {
    return ({
        type: types.GET_DATA_AGENT,
        payload: data,
        dataAgent: dataAgent,
        loading: data?.length <= 0 ? true : false,
    })
}
export function dataAgentByCondition(data) {
    return {
        type: types.DATA_AGENT_BY_CONDITION,
        data: data
    }
}

export function filterAgent(data, numberPage, sizePage) {
    return {
        type: types.FILTER_AGENT,
        formData: data,
        numberPage: numberPage,
        sizePage: sizePage,
    }
}

export function loadAgentByFilter(data) {
    return {
        type: types.LOAD_AGENT_BY_FILTER,
        payload: data,        
    }
}

export function setFilter(data) {
    return {
        type: types.SET_FILTER,
        payload: data,
    }
}
export function setFilterOutbound(data) {
    return {
        type: types.SET_FILTER_OUTBOUND,
        payload: data,
    }
}

export function openCDRsDialog(data)
{
    return {
        type: types.OPEN_CDRS_DIALOG,
        data
    }
}

export function closeCDRsDialog()
{
    return {
        type: types.CLOSE_CDRS_DIALOG
    }
}

export const submitSucceed = (message) => ({
    type: types.SUBMIT_SUCCEED,
    payload: message
});

export const submitFailed = (message) => ({
    type: types.SUBMIT_FAILED,
    payload: message
});

export const resetMessage = () => ({
    type: types.RESET_MESSAGE
});

export const resetLoading = () => ({
    type: types.RESET_LOADING
})