export function configureFakeBackend() {
    let realFetch = window.fetch;
    window.fetch = function(url, opts) {
        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call
            setTimeout(() => {
                // pass through any requests not handled above
                realFetch(url, opts)
                .then(response => { 
                    if(response.status !== 500) { 
                        resolve(response); 
                    } 
                });
            }, 500);
        });
    };
}
